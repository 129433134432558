
import axios from 'axios';
import qs from 'qs';

// import fetch from 'dva/fetch';
import { notification, message } from 'antd';
// import router from 'umi/router';
// import hash from 'hash.js';
// import { isAntdPro } from './utils';
// import { generalParamsWithP } from './math';
// import { getJAVAApiHost } from './urls';

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    if (response.status !== 401) {
        const errortext = codeMessage[response.status] || response.statusText;
        notification.error({
            message: `请求错误 ${response.status}: ${response.url}`,
            description: errortext,
        });
        const error = new Error(errortext);
        error.name = response.status;
        error.response = response;
        throw error;
    } else if (response.status === 401) {
        const errortext = codeMessage[response.status] || response.statusText;
        //这里不需要弹提示，因为抛出错误后，在request方法中，catch会捕获，在catch里面弹消息就可以了。
        // notification.error({
        //   message: `用户未登录` 
        // });
        const error = new Error(errortext);
        error.name = response.status;
        error.response = response;
        throw error;
    }
};

// const cachedSave = (response, hashcode) => {
//   /**
//    * Clone a response data and store it in sessionStorage
//    * Does not support data other than json, Cache only json
//    */
//   const contentType = response.headers.get('Content-Type');
//   if (contentType && contentType.match(/application\/json/i)) {
//     // All data is saved as text
//     response
//       .clone()
//       .text()
//       .then(content => {
//         sessionStorage.setItem(hashcode, content);
//         sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
//       });
//   }
//   return response;
// };

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default async function request(baseURL, url, config = {}, headers = "", method = 'POST') {
    try {
        let reqContentType = '', data = '';
        if (headers === 'json') {
            reqContentType = 'application/json;charset=UTF-8';
            data = config;
        } else {
            reqContentType = 'application/x-www-form-urlencoded';
            data = qs.stringify(config);
        }

        let response = await axios({
            url,
            method,
            baseURL,
            // headers: { 'content-type': 'application/x-www-form-urlencoded' },
            // headers: { 'content-type': 'application/json;charset=UTF-8' },
            // data: qs.stringify(config),
            // data: config,
            headers: { 'content-type': reqContentType },
            data,
        });
        response = checkStatus(response);
        // console.log(response);
        if (response && response.status === 200) {
            const { code, msg } = response.data;
            if (code !== 200) {
                message.warn(msg);
            }
            return response.data;
        } else {
        }
    } catch (error) {
        console.error('request', error);
    }

}
