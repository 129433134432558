import * as actionTypes from '../constant/supplierDataShare';
import * as req from '../request/supplierDataShare';


export function register(params) {
    return async dispatch => {
        const res = await req.register(params);
        if (res) {
            dispatch({ type: actionTypes.SUPPLIER_DATA_SHARE_REGISTER, payload: res })
        }
    }
}

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData(params) {
    return async dispatch => {
        const res = await req.getData(params);
        if (res) {
            dispatch({ type: actionTypes.SUPPLIER_DATA_SHARE_DATA, payload: res })
        }
    }
}

export function getUUID(params) {
    return async dispatch => {
        const res = await req.getUUID(params);
        if (res) {
            dispatch({ type: actionTypes.SUPPLIER_DATA_SHARE_UUID, payload: res })
        }
    }
}

export function addBathSharingRecord(params) {
    return req.addBathSharingRecord(params);
}

export function getCode(params) {
    return req.getCode(params);
}