//合并所有reducer 并且返回
import { combineReducers } from 'redux';
import { home } from './state/reducer/home';
import { order } from './state/reducer/order';
import { orderDetail } from './state/reducer/orderDetail';
import { download } from './state/reducer/download';
import { shareAccount } from './state/reducer/shareAccount';
import { supplierDataShare } from './state/reducer/supplierDataShare';
import { buyerCredit } from './state/reducer/buyerCredit';
import { supplierSalesStatistics } from './state/reducer/supplierSalesStatistics';
import { store } from './state/reducer/store';
import { purchase } from './state/reducer/purchase';

export default combineReducers({
    home,
    order,
    orderDetail,
    download,
    shareAccount,
    supplierDataShare,
    buyerCredit,
    supplierSalesStatistics,
    store,
    purchase,
})