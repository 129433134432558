import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
// import qs from 'qs';
import { connect } from 'react-redux';
// import { Icon } from 'antd';
import { PullToRefresh, ListView } from 'antd-mobile';
import moment from 'moment';

// import icon_right from '../images/icon_right.png';
// import { fixedZeroTo4Bit, strSplit } from "../../../utils/math";
import { getCreditData } from '../../../state/action/order'
import './credit.less';

@withRouter
@connect(
    state => state.order,
    { getCreditData }
)
class Credit extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            // getRowData: (dataBlob, sectionID, rowID) => dataBlob[rowID],
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        });
        this.state = {
            pageNo: 1,
            dataSource,
            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight,

        }
    }

    async componentDidMount() {
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
        await this.getPageData(1);
        this.setState({
            height: hei,
            refreshing: false,
            isLoading: false,
        });
    }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }
    // onRefresh = async () => {
    //     console.log('onRefresh = async () => {');
    //     this.setState({ refreshing: true, isLoading: true });
    //     await this.getPageData(this.state.pageNo + 1);
    //     this.setState({ refreshing: false, isLoading: false, });
    // };

    onEndReached = async (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    };

    getPageData = async (pageNo) => {
        const { totalSize = 0, pageList = [] } = this.props.creditData.data || {};
        const reqPageIndex = Math.ceil(pageList.length / 10);//请求过的页数
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        } else {
            if (totalSize > pageList.length || pageList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getCreditData({
                    p: this.props.query.sn,
                    pageNo,
                    pageSize: 10,
                });

                const { pageList = [], } = this.props.creditData.data || {};
                // this.setState({ pageNo, data: pageList, refreshing: false });
                this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
                // if (pageNo * 10 > pageList.length) {
                // message.info('没有更多的数据了！');//ListView插件老是提醒
                //}      
            } else {
                const { pageList = [], } = this.props.creditData.data || {};
                this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
                // message.info('没有更多的数据了！');
            }
        }

    }
    //展开或者关闭
    show(e, i) {
        e.stopPropagation();
        const { pageList = [], } = this.props.creditData.data || {};
        this.setState({ ['show' + i]: !this.state['show' + i], dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            // console.log("const row = (rowData, sectionID, rowID) => {", rowData, sectionID, rowID);
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);
            //status 状态水印 1：已付款，3：赊账
            //orderType:5-赊欠白条
            const {
                // todayNo, buyerName, creditTime, creditAmount, saleAmount, attrNameList = [], 
                status,
                orderType,
                // remark = '' 
                createTime, creditAmount, basketNum, cxmShareOrderItemResultList = [],
            } = v;
            // const isCredit = (status !== 1 && status !== 5) === false;

            //如果下一个信息的还款时间和上一个信息的还款时间是同一天的，那么不需要显示时间和还款金额        
            let isSameDay = false;
            // let isSameYear = true;
            const { pageList = [] } = this.props.creditData.data;
            if (i > 0) {
                const prevTime = pageList[i - 1].createTime;
                // console.log('票号：' + v.todayNo, moment(repayTime).format('YYYY-MM-DD'), moment(prevTime).format('YYYY-MM-DD'))
                if (moment(createTime).format('YYYY-MM-DD') === moment(prevTime).format('YYYY-MM-DD')) {
                    isSameDay = true;
                }
                //  else if (moment(repayTime).format('YYYY') !== moment(prevTime).format('YYYY')) {
                // isSameYear = false;
                // }
            }
            const isShow = this.state['show' + i];//是否是展开的
            let showTime = createTime;//显示的时间
            // console.log(moment(createTime).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(-1, 'd').format('YYYY-MM-DD'), new Date());

            if (moment(createTime).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
                showTime = '今日 ' + moment(createTime).format('HH:mm');//今日 22:00
            // } else if (moment(createTime).format('YYYY-MM-DD') === moment(new Date()).add(-1, 'd').format('YYYY-MM-DD')) {
            //     showTime = '昨日 ' + moment(createTime).format('HH:mm');//昨日 22:00
            } else {
                showTime = moment(createTime).format('YYYY-MM-DD HH:mm');//2020-02-22 22:00
            }
            return (
                <div key={i}
                    id={`credit${i}`}
                    className={`orderinfo ${orderType === 5 ? status === 3 ? 'orderType53' : 'orderType5' : ''} ${i === 0 ? 'orderinfo-first' : ''} ${isSameDay ? 'same-day' : 'no-same-day'}`}
                    onClick={() => this.props.toDetail(v, 0, `credit${i}`)}
                    ref={el => this['item' + i] = el}
                >
                    <div>
                        <div>{showTime}</div>
                        <div>
                            <span>下欠:{creditAmount}元</span>
                            {basketNum > 0 ? <span>欠筐:{basketNum}个</span> : null}
                        </div>
                    </div>
                    {
                        orderType === 5 ?
                            <div className="order-item-orderType5">
                                <div>白条</div>
                                <div>下欠:{creditAmount}</div>
                            </div>
                            : <div className="order-item-orderType">
                                <div>
                                    {cxmShareOrderItemResultList.map((c, ii) => {
                                        return <div key={ii} style={{ display: ii > 9 ? isShow ? 'flex' : 'none' : 'flex' }}>
                                            <div>{c.goodsName}</div>
                                            <div>{c.saleDesc}</div>
                                            <div>{c.priceDesc}</div>
                                        </div>
                                    })}
                                </div>
                                {cxmShareOrderItemResultList.length > 10 &&
                                    <div onClick={(e) => this.show(e, i)}>
                                        {isShow ? '收起' : '展开'} <img src={require(`../../../common/images/${isShow ? 'icon-up' : 'icon-down'}.png`)} alt='' />
                                    </div>
                                }
                            </div>
                    }
                </div>
            )

        };

        return <div className='order-buyer-credit-index-credit'>
            <ListView
                key={1}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                // renderHeader={() => <span>Pull to refresh</span>}
                renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : ''}
                    {/* {this.state.isLoading ? '加载中...' : '加载完成'} */}
                </div>)}
                renderRow={row}
                // renderSeparator={separator}
                useBodyScroll={true}
                style={{
                    // height: this.state.height,
                    // border: '1px solid #ddd',
                    // margin: '5px 0',
                }}
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    // onRefresh={this.onRefresh}
                    direction={'up'}
                />}
                onEndReached={this.onEndReached}
                pageSize={10}
            />
        </div >
    }
}

export default Credit;