

import request from '../../utils/request';
import { API_ORDER_TRACK } from '../../utils/urls';

export function getData(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/purchase', params, 'json');
  // return {
  //     "data": {
  //         "buyerName": "一林",
  //         "buyerPhone": "",
  //         "firstTime": 1575288985202,
  //         "lastTime": 1575288985202,
  //         "totalAmount": 110,
  //         "orderList": [
  //             {
  //                 "todayNo": 23,
  //                 "buyerName": "一林",
  //                 "createTime": 1575288985202,
  //                 "creditAmount": 0,
  //                 "statusWatermark": 1,//状态水印 1：已付款，3：赊账
  //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
  //                 "saleAmount": 5000.00,
  //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
  //             },
  //             {
  //                 "todayNo": 23,
  //                 "buyerName": "一林",
  //                 "createTime": 1575288985202,
  //                 "creditAmount": 4000.00,
  //                 "statusWatermark": 3,//状态水印 1：已付款，3：赊账
  //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
  //                 "saleAmount": 5000.00,
  //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
  //             },
  //             {
  //                 "todayNo": 23,
  //                 "buyerName": "一林",
  //                 "createTime": 1575288985202,
  //                 "creditAmount": 4000.00,
  //                 "statusWatermark": 3,//状态水印 1：已付款，3：赊账
  //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
  //                 "saleAmount": 5000.00,
  //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
  //             }
  //         ],
  //         "totalCount": 1
  //     },
  //     "msg": "操作成功",
  //     "code": 200
  // }
}
export function getPurchaseRecord(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/purchase/list', params, 'json');
}
export function getRepayRecord(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/purchase/repay', params, 'json');
  // return {
  //     "code": 200,
  //     "data": {
  //       "pageList": [
  //         {
  //           "saleAmount": 1,
  //           "repayAmount": 1,
  //           "creditAmount": 1,
  //           "creditTime": 1,
  //           "orderNo": "",
  //           "orderItemList": [
  //             {
  //               "goodsAttrName": "地方",
  //               "saleNumber": 1,
  //               "saleWeight": 1,
  //               "packKind": 1,
  //               "unitPrice": 1,
  //               "unitName": "的"
  //             }
  //           ],
  //           "orderType": 1
  //         }
  //       ],
  //       "pageNo": 1,
  //       "pageSize": 10,
  //       "totalPage": 1,
  //       "totalSize": 1
  //     },
  //     "msg": "操作成功"
  //   }
}
export function getCreditRepayCount(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/purchase/count', params, 'json');
}
export function getCreditRepay(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/purchase/flow', params, 'json');
}
export function getDetailData(params) {
  return request(API_ORDER_TRACK, 'cxmUser/customer/share/order/view', params, 'json');
}
