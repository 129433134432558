import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd';
import CallApp from 'callapp-lib';

import { getData } from '../../state/action/download';

import imageSrc from './images/icon_logo.png';
import mask1 from './images/mask1.png';
import icon_banner from './images/icon_banner.png';
import icon_main from './images/icon_main.png';
import icon_android from './images/icon_android.png';
import icon_ios from './images/icon_ios.png';

// import icon_mark from './images/icon_mark.png';
import { isWx } from "../../utils/math";
// import List from './list';

import './style.less';

@withRouter
@connect(
    state => state.download,
    { getData }
)
class Download extends Component {
    constructor(props) {
        super(props);

        this.iosUrl = 'https://itunes.apple.com/cn/app/%E8%8F%9C%E5%B0%8F%E7%A7%98%E4%B9%B0%E5%AE%B6%E7%AB%AF/id1463387025?mt=8';
        this.show = isWx() ? true : false;
    }

    async componentDidMount() {
        this.props.getData();

        document.title = `分享下载`;
        document.body.classList.add("download-body");
        // document.getElementById("root").classList.add("download-root");
    }

    componentWillUnmount() {
        document.body.classList.remove("download-body");
        // document.getElementById("root").classList.remove("download-root");
    }

    openApp = () => {
        const { data } = this.props;
        const options = {
            scheme: {
                protocol: 'caixmpucharse',
            },
            intent: {
                package: 'com.zhiyi.cxm.purchase',
                scheme: 'caixmpucharse',
            },
            appstore: this.iosUrl, // appStore下载地址
            // yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.youku.shortvideo', // 应用宝下载地址
            fallback: data.url, // 安卓版下载地址
            timeout: 2000,
        };
        const callLib = new CallApp(options);
        callLib.open({
            path: 'profile',
        });
    }

    androidDownLoad = () => {
        const { data } = this.props;
        window.location.href = data.url;
    }
    iosDownLoad = () => {
        window.location.href = this.iosUrl;
    }

    render() {
        console.log('download render()', this);

        return <div className='download-index'>
            <div className="container">
                <div className='topBar'>
                    <div className="topBarLeft">
                        <img src={imageSrc} className='imageLogo' alt=''></img>
                        <span className="leftText">已下载请直接打开</span>
                    </div>
                    <div className="topBarRight">
                        <span className="rightText" id="call-button" onClick={this.openApp} >打开</span>
                    </div>
                </div>
                <img src={icon_banner} className='iconBanner' alt=''></img>
                <img src={icon_main} className='iconMain' alt=''></img>
                <img src={icon_android} className='iconDownload' alt='' onClick={this.androidDownLoad}></img>
                <img src={icon_ios} className='iconDownload' alt='' onClick={this.iosDownLoad}></img>
            </div>

            <Modal
                visible={this.show}
                className="popup"
                footer={null}
                style={{ top: 0, }}
                closable={false}
            >
                <img src={mask1} className='mask' alt=''></img>
            </Modal>

        </div>
    }
}

export default Download;