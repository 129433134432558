import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { Modal, message } from 'antd';
import { NoticeBar } from 'antd-mobile';
import qs from 'qs';
//import Slider from "./index/slider";

// import btn from '../../common/images/download-cxm-app.png';
import mask1 from '../../common/images/mask1.png';
import close from '../../common/images/close.png';
import { isWx, isiOS, isQQ, isMobileDing } from "../../utils/math";
import { getCxmData } from '../../state/action/download';

import './style.less';

// const { confirm } = Modal;
const noticeData = [
    { name: '上海伟岳菇行老板娘：', say: '用了菜小秘系统，终于可以一边考察一边旅游了', },
    { name: '武汉百菜园刘老板：', say: '特别看重数据安全，菜小秘一秒调出数据，棒！', },
    { name: '南京众彩市场孙贵：', say: '用之前每天花大半时间工作，根本没法好好记账。', },
    { name: '重庆清源农业唐老板：', say: '农批人太累，需要减少消耗，菜小秘，好工具！', },
    { name: '杭州良渚蔬菜高健生：', say: '用了菜小秘赊欠管理变得简单，百分百消灭遗漏！', },
    { name: '四川雨润市场5区1-2何总：', say: '开单记账最实用，工作人员随时解答问题。', },
    { name: '湖南怀化吴氏菜业：', say: '和菜小秘的认识也算一种缘分。方便易学一看就明白！', },
    { name: '广宇蔬菜经营部莫老板：', say: '再难的生意也抵不过条理运作，赊账管理很不错。', },
    { name: '四川雨润市场薛大爷（75岁）：', say: '智能化真好，有它，省笔、省账本，省时间！', },
    { name: '上海江桥林总：', say: '菜小秘来了，终于可以生二胎了！', },
]
@connect(
    state => state.download,
    { getCxmData }
)
class DownloadCXMApp extends Component {
    constructor(props) {
        super(props);

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));

        this.state = {
            show: false,
            visible: true,
        }
        // this.iosUrl = 'https://itunes.apple.com/cn/app/%E8%8F%9C%E5%B0%8F%E7%A7%98%E4%B9%B0%E5%AE%B6%E7%AB%AF/id1463387025?mt=8';
        // this.show = isWx() ? true : false;
        // this.download = this.download.bind(this);
    }

    async componentDidMount() {
        document.body.classList.add("download-cxm-app2-body");
        // document.getElementById("root").classList.add("download-root");
        if (!isWx() && this.query.download) {
            this.download();
        }

    }

    componentWillUnmount() {
        document.body.classList.remove("download-cxm-app2-body");
        // document.getElementById("root").classList.remove("download-root");
    }

    download = async () => {
        window._czc.push(['_trackEvent', '菜小秘APP下载', '点击', '点击了菜小秘APP下载']);
        // message.info(123123)
        if (isiOS()) {//IOS
            //ios链接暂时还没下来，所以给个提示
            // message.info('ios敬请期待');
            // Modal.info({
            //     title: 'ios链接敬请期待！！！',
            //     content: (
            //         <div>
            //             <p>ios链接敬请期待！！！</p>
            //         </div>
            //     ),
            //     okText: '知道了',
            //     onOk() { },
            // });

            window.location.href = 'https://apps.apple.com/cn/app/%E8%8F%9C%E5%B0%8F%E7%A7%98/id1490901038?mt=8';//最新的
            // window.location.href = 'https://itunes.apple.com/cn/app/%E8%8F%9C%E5%B0%8F%E7%A7%98%E4%B9%B0%E5%AE%B6%E7%AB%AF/id1463387025?mt=8';

            // const result = await this.props.getDownloadUrl();
            // if(result.code === 200){
            //     const data = result.data;
            //     let url = '';
            //     //1:官方 2:android应用宝 3:ios appStore 
            //     data.map((item,index)=>{
            //         if(item.channel===3){
            //             url = item.url;
            //         }
            //     })
            //     window.location.href = url;
            // }else{
            //     message.info(result.msg);
            // }
        } else {
            if (isWx() || isQQ() || isMobileDing()) {
                this.setState({ show: true });
                //网页本身没有任何变化，只是地址栏发生了变化，实现了无刷新改变地址栏，并且在历史记录中添加了一个新的纪录；
                window.history.pushState(null, null, window.location.href + '&download=true');
            } else {
                await this.props.getCxmData();
                const { cxm, } = this.props;
                if (cxm.code === 200) {
                    const cxmLink = cxm.data.data[0].link;
                    window.location.href = cxmLink;
                } else {
                    message.info(cxm.msg)
                }
            }
        }
    }

    handleCancel = e => {
        // console.log(e);
        this.setState({ show: false });
    };

    closeDownload = () => {
        this.setState({ visible: false });
    }

    render() {
        const { visible, show } = this.state;
        if (!visible) {
            return null;
        }
        return <div className="download-cxm-app2-index">
            <div className="close-image"><img src={close} alt="" onClick={this.closeDownload} /></div>
            <div
                // onClick={this.download} 
                className="download-cxm-app2-content"
            // id="cxmDownloadButton"
            >
                {/* <img src={btn} className='img-btn' alt='' ></img> */}
                {/**上下滚动 */}
                {
                    // <div>
                    //     <Slider />
                    // </div>
                    // <div>
                    //     <div>大佬商行</div>
                    //     <div>正在使用菜小秘，你也赶紧试试吧！</div>
                    // </div>
                }
                {/** 横向滚动 */}
                <div className="notice-bar-div">
                    <NoticeBar icon={false} marqueeProps={{ loop: true, leading: 1000, fps: 20 }} className="notice-bar">
                        {noticeData.map((item, index) => {
                            return <span key={index}>{item.name}{item.say}&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        })}
                    </NoticeBar>
                </div>
                <div id="cxmDownloadButton" style={{ cursor: 'pointer' }}>
                    <div id="cxmDownloadButtonChild">{this.props.stallName}</div>
                </div>
            </div>
            <Modal
                visible={show}
                className="popup"
                footer={null}
                style={{ top: 0, }}
                // closable={false}
                // onClose={this.onClose}    
                onCancel={this.handleCancel}
            >
                <img src={mask1} className='mask' alt='' onClick={this.handleCancel}></img>
            </Modal>
        </div>
    }

}

export default DownloadCXMApp;