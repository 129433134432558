import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { Table } from 'antd';

import { getData, getTotalData } from '../../state/action/shareAccount';
// import DownloadCXMApp from '../../component/download-cxm-app/index';
import DownloadCXMApp from '../../component/download-cxm-app2/index';
import './style.less';

//import close from '../../common/images/close.png';

const { Column } = Table;
//采购对账表
@withRouter
@connect(
    state => state.shareAccount,
    { getData, getTotalData }
)
class ShareAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableDataSource: [],
            visible: true
        }
        // this.query = qs.parse(this.props.location.search.substring(this.props.location.search.lastIndexOf('?') + 1));
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
    }

    async componentDidMount() {
        await Promise.all([
            this.props.getData({
                p: this.query.sn
            }),
            this.props.getTotalData({
                p: this.query.sn
            }),
        ]);
        const { sum, list } = this.props;
        this.setState({
            loading: false,
            tableDataSource: [
                ...list,
                {
                    orderTime: '合计',
                    saleAmount: sum.consumeAmount,
                    payAmount: sum.paymentAmount,
                    creditAmount: sum.creditAmount,
                }
            ],
        });
        document.title = `${sum.name || ''}采购对账表`;
        document.body.classList.add("share-account-body");
    }

    componentWillUnmount() {
        document.body.classList.remove("share-account-body");
    }

    closeDownload = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        console.log('share-account render()', this);
        const { sum } = this.props;
        const { loading, tableDataSource } = this.state;
        const { marketType } = this.query;

        return <div className='share-account-index'>
            <div className="container" style={this.state.visible?{ paddingBottom:'0.5rem'}:{}}>
                <div className="containerWrap">
                    <div className="subsTitle">
                        <span className="subsTitleText">{sum.name || ''}采购对账表 日期{sum.startDate} 一 {sum.endDate}</span>
                    </div>
                    <Table
                        rowKey={(record, index) => index}
                        // rowKey={render => render.accountId}
                        dataSource={tableDataSource}
                        pagination={false}
                        scroll={{ x: true }}
                        bordered={true}
                        loading={loading}
                        // style={{ border: "1px solid #eee" }}
                        title={() => {
                            return <div className="header">
                                <span className="headerText2">买家名称:{sum.name}</span>
                                <span className="headerText">累计应结:{sum.consumeAmount}</span>
                                <span className="headerText">已结:{sum.paymentAmount}</span>
                                {marketType !== '15' ? <span className="headerText">剩余欠款:{sum.creditAmount}</span> : null}
                            </div>
                        }}
                    // footer={() => 'Footer'}
                    // className='responsive'
                    >
                        <Column title="日期" dataIndex="orderTime"
                            render={(text, record) => {
                                if (text === '合计') {
                                    return {
                                        children: text,
                                        props: { colSpan: marketType !== '15' ? 10 : 7, },
                                    };
                                }
                                return text || '';
                            }}
                        />
                        <Column title="品名" dataIndex="goodsAttrName"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text || '-';
                            }}
                        />
                        <Column title="数量" dataIndex="saleNumber" render={(text, record) => {
                            if (record.orderTime === '合计') {
                                return {
                                    children: '',
                                    props: { colSpan: 0, },
                                };
                            }
                            return text;
                        }} />
                        <Column title="数量单位" dataIndex="saleNumberUnit"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text;
                            }} />
                        <Column title="重量" dataIndex="saleWeight"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                if(record.packKind === 1){
                                    return '-'
                                }else{
                                    return text;
                                }
                                
                            }} />
                        <Column title="重量单位" dataIndex="saleWeightUnit"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                // return text;
                                if(record.packKind === 1){
                                    return '-'
                                }else{
                                    return text;
                                }
                            }} />
                        <Column
                            title="单价"
                            dataIndex="unitPrice"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text || '-';
                            }}
                        />
                        {marketType !== '15' ? <Column title="力资" dataIndex="tradeAmount"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text || '-';
                            }}
                        /> : null}
                        {marketType !== '15' ? <Column title="灌包" dataIndex="packAmount"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text || '-';
                            }}
                        /> : null}
                        {marketType !== '15' ? <Column title="开票" dataIndex="billAmount"
                            render={(text, record) => {
                                if (record.orderTime === '合计') {
                                    return {
                                        children: '',
                                        props: { colSpan: 0, },
                                    };
                                }
                                return text || '-';
                            }}
                        /> : null}
                        <Column title="应结" dataIndex="saleAmount"
                            render={(text, record) => {
                                return text || '-';
                            }}
                        />
                        <Column title="已结" dataIndex="payAmount"
                            render={(text, record) => {
                                return text || '-';
                            }}
                        />
                        <Column title="剩余欠款" dataIndex="creditAmount"
                            render={(text, record) => {
                                return text || '-';
                            }}
                        />
                    </Table>
                </div>
            </div>
            {this.state.visible?<DownloadCXMApp stallName={sum.stallName} closeDownload={this.closeDownload}/>:null}
        </div>
    }
}

export default ShareAccount;