// import * as actionTypes from '../constant/initial'

//初始状态
const initState = {
    home: {},
}
//reducer
export function home(state = initState, action) {
    switch (action.type) {
        default:
            return state
    }
}