import React from 'react';
import { withRouter } from 'react-router-dom'
import qs from 'qs';
import { getFormatDateTime } from "../../../utils/common";
import './row.less';

@withRouter
class RowData extends React.PureComponent {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
    }

    toOrderDetail = (v = {}) => {
        this.props.history.push({
            pathname: v.orderType === 5 ? '/cxm-order-detail-5' : '/cxm-order-detail',
            search: qs.stringify({
                sn: this.query.sn,
                id: v.orderNo,
                // token: this.query.token,
                tabIndex: this.props.type,
                back: this.props.back || 'cxm-purchase-record',
            }),
            // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
        });
    }

    render() {
        const { rowData, rowID, } = this.props;
        if (rowData == null) return "null";
        const v = rowData;
        const i = parseInt(rowID, 10);
        //status 状态水印 1：已付款，3：赊账
        //orderType:5-赊欠白条
        const {
            createTime, creditAmount = 0, orderItemLst = [], orderSaleAmount, orderType, orderCreditBasketNum = 0
        } = v;
        // return <div>123</div>
        return (
            <div className='purchase-record-row-container' key={i}>
                <div>
                    <div className='contentBox' onClick={() => this.toOrderDetail(v)}>
                        <div className='contentTotal'>
                            <div >
                                <div className='title signTime'>{getFormatDateTime(createTime, orderType)}</div>
                                {v.orderType === 5
                                    ? <div className='status status5'>历史赊欠</div>
                                    : v.statusWatermark === 1
                                        ? <div className='status status1'>已结清</div>
                                        : null}
                            </div>
                            <div>
                                <div className='title'>订单金额：{orderSaleAmount || 0}元</div>
                                <div className='title1'>
                                    {creditAmount && creditAmount > 0 ? <span>下欠：<span className="xiaqian">{creditAmount}元</span></span> : null}
                                    {orderCreditBasketNum && orderCreditBasketNum > 0 ? <span>欠筐：<span className="qiankuan">{orderCreditBasketNum}</span></span> : null}
                                </div>
                            </div>
                        </div>
                        {/* 订单金额 下欠 */}
                        <div className='contentDetail'>
                            {
                                orderType !== 5 && orderItemLst && orderItemLst.length > 0 ?
                                    orderItemLst.map((item, index) => {
                                        //包装形式 1定装 3散装 2非定装 7多单位 5郊菜
                                        //定装，定装多单位取saleNumber，其他取saleWeight
                                        //比如这个显示：5包，10元/包，取值saleNumber unitName， unitPrice extend3
                                        const { goodsAttrName, packKind, saleNumber, saleWeight, unitName, unitPrice, extend3 } = item;
                                        return <div key={index}>
                                            <div className='item'>
                                                <div>{goodsAttrName}</div>
                                                <div>{packKind === 1 || packKind === 7 ? saleNumber : saleWeight}{unitName}</div>
                                                <div>{unitPrice}{extend3}</div>
                                            </div>
                                        </div>;
                                    })
                                    :
                                    null
                            }

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default RowData;