import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// import { getCreditDetailData } from '../../state/action/buyerCredit';
import { PullToRefresh, ListView } from 'antd-mobile';
// import { Badge } from 'antd';
// import { ORDER_STATUS } from '../../utils/status';
// import { URL_ORDER_TRACK } from '../../utils/urls';

import './repayRecord.less';
import moment from 'moment';
// import { fixedZeroTo4Bit, strSplit } from "../../utils/math";
// import Back from '../../component/back/index';
import backImgSrc from '../../common/images/icon_left.png';
import { getRepayRecord, clearRepayRecord } from '../../state/action/purchase'
//const { Column } = Table;
@withRouter
@connect(
    state => state.purchase,
    { getRepayRecord, clearRepayRecord }
)
class RepayRecord extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        })
        this.state = {
            dataSource,
            pageNo: 1,
            refreshing: true,
            isLoading: false,
        }

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        // this.sn = decodeURI(this.query.sn);
    }

    async componentDidMount() {
        document.title = "还款记录";
        document.getElementById("root").classList.add("cxm-bill-repay-record-root");
        // const { sn, id } = this.query;
        // await this.props.getCreditDetailData({
        //     sn,
        //     param: id,
        // });
        // 监听message事件    
        // window.addEventListener("message", this.receiveMessage, false);
        //静态数据        
        await this.getPageData(1);
        this.setState({
            // height: hei,
            refreshing: false,
            isLoading: false,
        });
    }
    componentWillUnmount() {
        document.getElementById("root").classList.remove("cxm-bill-repay-record-root");
        // window.removeEventListener("message", this.receiveMessage);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }

    onEndReached = async () => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false });
    }
    getPageData = async (pageNo) => {
        const data = this.props.repayRecord.data || {};
        const { totalSize = 0, pageList = [] } = data;
        const reqPageIndex = Math.ceil(pageList.length / 10);//请求过的页数        
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        } else {
            if (totalSize > pageList.length || pageList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getRepayRecord({
                    sn: this.query.id,
                    pageNo,
                    pageSize: 10,
                });

                const { pageList = [], } = this.props.repayRecord.data || {};
                // this.setState({ pageNo, data: pageList, refreshing: false });
                this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
                // if (pageNo * 10 > pageList.length) {
                // message.info('没有更多的数据了！');//ListView插件老是提醒
                //}      
            } else {
                const { pageList = [], } = data;
                this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
                // message.info('没有更多的数据了！');
            }
        }
    }
    toBack = () => {
        this.props.clearRepayRecord();//清楚还款记录的数据，如果不清除，那么返回之后，再换个还款记录的信息进来，那么会不调用接口
        this.props.history.push({
            pathname: 'cxm-credit-repay',
            search: qs.stringify({
                sn: this.query.sn,
                tabIndex: this.query.tabIndex
            })
        })
    }
    toOrderDetail(v = {}) {
        this.props.history.push({
            pathname: v.orderType === 5 ? '/cxm-order-detail-5' : '/cxm-order-detail',
            search: qs.stringify({
                sn: this.query.sn,
                id: v.sn,
                // token: this.query.token,
                back: 'cxm-repay-record',
            }),
            // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
        });
    }

    render() {
        // const { amount, time } = this.query;
        const { repayAmount, createTime } = this.props.creditRepayClickData || {};
        const { totalSize } = this.props.repayRecord.data || {};
        const row = (rowData, sectionID, rowID) => {
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);
            //status 状态水印 1：已付款，3：赊账
            //orderType:5-赊欠白条
            const {
                saleAmount, repayAmount, orderItemLst = [], creditAmount, creditTime, orderType
            } = v;

            return (
                <div className='container'>
                    <div>
                        <div key={i} className='contentBox' onClick={() => this.toOrderDetail(v)} >
                            <div key={i} className='contentTotal'>
                                <div>
                                    <div className='title'>订单金额：{saleAmount}元</div>
                                    <div className='title'>还款：{repayAmount}元</div>
                                </div>
                                <div>
                                    {
                                        v.orderType === 5 ?
                                            <div className='status'>历史赊欠</div>
                                            : <div>赊欠时间：<span>{moment(creditTime).format('YYYY-MM-DD HH:mm')}</span></div>
                                    }
                                    {
                                        creditAmount > 0 ?
                                            <div className='title1'>下欠：<span>{creditAmount || 0}元</span></div>
                                            : <div className='status1'>已结清</div>
                                    }
                                </div>
                            </div>
                            <div className='contentDetail'>
                                {
                                    orderType !== 5 && orderItemLst && orderItemLst.length > 0 ?
                                        orderItemLst.map((item, index) => {
                                            //包装形式 1定装 3散装 2非定装 7多单位 5郊菜
                                            //定装，定装多单位取saleNumber，其他取saleWeight
                                            //比如这个显示：5包，10元/包，取值saleNumber unitName， unitPrice extend3
                                            const { goodsAttrName, packKind, saleNumber, saleWeight, unitName, unitPrice, extend3 } = item;
                                            return <div key={index}>
                                                <div className='item'>
                                                    <div>{goodsAttrName}</div>
                                                    <div>{packKind === 1 || packKind === 7 ? saleNumber : saleWeight}{unitName}</div>
                                                    <div>{unitPrice}{extend3}</div>
                                                </div>
                                            </div>;
                                        })
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <div className='cxm-bill-repay-record'>
            <div className="header">
                <div className='headerBox'>
                    <div className="headerNum">还款： <span>{repayAmount}元</span></div>
                    <div>还款时间：{moment(Number(createTime)).format('YYYY-MM-DD HH:mm')}</div>
                </div>
            </div>
            <div className='totalRepay'>
                <span>还款订单 ({totalSize || 0}笔)</span>
            </div>
            <ListView
                key={1}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : ''}
                </div>)}
                renderRow={row}
                useBodyScroll={true}
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    direction={'up'}
                />}
                onEndReached={this.onEndReached}
                pageSize={10}
            />
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
        </div>
    }
}

export default RepayRecord;