import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { connect } from 'react-redux';
// import { Icon } from 'antd';
import { PullToRefresh, ListView } from 'antd-mobile';
import moment from 'moment';

// import icon_right from '../images/icon_right.png';
import { fixedZeroTo4Bit, strSplit } from "../../../utils/math";
import { getData } from '../../../state/action/buyerCredit'
import './credit.less';

@withRouter
@connect(
    state => state.buyerCredit,
    { getData }
)
class Credit extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            // getRowData: (dataBlob, sectionID, rowID) => dataBlob[rowID],
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        });
        this.state = {
            pageNo: 1,
            dataSource,
            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight,

        }
    }

    async componentDidMount() {
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
        await this.getPageData(1);
        this.setState({
            height: hei,
            refreshing: false,
            isLoading: false,
        });
    }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }
    // onRefresh = async () => {
    //     console.log('onRefresh = async () => {');
    //     this.setState({ refreshing: true, isLoading: true });
    //     await this.getPageData(this.state.pageNo + 1);
    //     this.setState({ refreshing: false, isLoading: false, });
    // };

    onEndReached = async (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    };

    getPageData = async (pageNo) => {
        const { totalCount = 0, orderList = [] } = this.props.buyCreditData.data || {};
        const reqPageIndex = Math.ceil(orderList.length / 10);//请求过的页数
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(orderList), refreshing: false });
        } else {
            if (totalCount > orderList.length || orderList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getData({
                    sn: this.props.query.sn,
                    pageNo,
                    pageSize: 10,
                });

                const { orderList = [], } = this.props.buyCreditData.data || {};
                // this.setState({ pageNo, data: orderList, refreshing: false });
                this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(orderList), refreshing: false });
                // if (pageNo * 10 > orderList.length) {
                // message.info('没有更多的数据了！');//ListView插件老是提醒
                //}      
            } else {
                const { orderList = [], } = this.props.buyCreditData.data || {};
                this.setState({ dataSource: this.state.dataSource.cloneWithRows(orderList) });
                // message.info('没有更多的数据了！');
            }
        }

    }

    handleCreditDetail(v, i) {
        // console.log('handleCreditDetail(v,i) {', this['item' + i]);
        // this['item' + i].classList.add("opacity5");//点击增加透明
        this.props.history.push({
            pathname: v.orderType === 5 ? '/buyerCreditDetail5' : '/buyerCreditDetail',
            // pathname: '/buyerCreditDetail',
            search: qs.stringify({
                sn: this.props.query.sn,
                id: v.orderNo,
                token: this.props.query.token,
                back: '/buyerCredit',
                tabIndex: 0,
            }),
            // state: { back: { pathname: '/buyerCredit', search: qs.stringify({ sn: this.sn }) } },
        });
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            // console.log("const row = (rowData, sectionID, rowID) => {", rowData, sectionID, rowID);
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);
            //statusWatermark 状态水印 1：已付款，3：赊账
            //orderType:5-赊欠白条
            const { todayNo, buyerName, createTime, creditAmount, saleAmount, attrNameList = [], statusWatermark, orderType } = v;
            return (
                <div key={i}
                    className={`orderinfo ${orderType === 5 ? statusWatermark === 3 ? 'orderType53' : 'orderType5' : ''}`}
                    onClick={() => this.handleCreditDetail(v, i)}
                    ref={el => this['item' + i] = el}
                    // onMouseOver={() => { this['item' + i].classList.add("opacity5") }}
                    // onMouseOut={() => { this['item' + i].classList.remove("opacity5") }}
                    onTouchStart={() => { this['item' + i].classList.add("opacity5") }}
                    onTouchEnd={() => { this['item' + i].classList.remove("opacity5") }}
                    onTouchMove={() => { this['item' + i].classList.remove("opacity5") }}
                    onTouchCancel={() => { this['item' + i].classList.remove("opacity5") }}
                >
                    <div>
                        <div><span>{orderType === 5 ? '历史赊欠' : `票号：${todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}`}</span></div>
                        <div><span>赊欠时间：</span><span>{moment(createTime).format(orderType === 5 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')}</span></div>
                        <div>
                            {/* {statusWatermark === 1 ? <div className="status1">已结清</div> : <div className="status3">赊欠</div>} */}
                            <div><img src={require(`../../../common/images/${statusWatermark === 1 ? 'jieqing' : 'sheqian'}.png`)} alt='' /></div>
                        </div>
                    </div>
                    <div>
                        <div>{buyerName}</div>
                        <div>¥ {saleAmount}</div>
                    </div>
                    {
                        orderType === 5 ?
                            statusWatermark === 3 ? <div>
                                <div></div>
                                {statusWatermark === 3 ? <div><span>下欠：</span><span>{creditAmount}元</span></div> : null}
                            </div>
                                : null
                            : <div>
                                <div>{strSplit(attrNameList.join('、'), 14)}</div>
                                {statusWatermark === 3 ? <div><span>下欠：</span><span>{creditAmount}元</span></div> : null}
                            </div>
                    }

                </div>
            )

        };

        return <div className='buyer-credit-index-credit'>
            <ListView
                key={1}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                // renderHeader={() => <span>Pull to refresh</span>}
                renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : ''}
                    {/* {this.state.isLoading ? '加载中...' : '加载完成'} */}
                </div>)}
                renderRow={row}
                // renderSeparator={separator}
                useBodyScroll={true}
                style={{
                    // height: this.state.height,
                    // border: '1px solid #ddd',
                    // margin: '5px 0',
                }}
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    // onRefresh={this.onRefresh}
                    direction={'up'}
                />}
                onEndReached={this.onEndReached}
                pageSize={10}
            />
        </div >
    }
}

export default Credit;