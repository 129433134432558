// 货物
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getStoreDetail, updateLikes } from '../../../state/action/store'

import './goods.less';

@connect(
    state => state.store,
    { getStoreDetail, updateLikes }
)
class Goods extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }
    async down(e, v, i) {
        e.stopPropagation();
        this.setState({ ['z' + i]: true });
        // this.setState({ ['z' + i]: !this.state['z' + i] });
        await this.props.updateLikes({ id: v.id });
        const { likes } = this.props;
        if (likes.data === '点赞成功') {
            // await this.props.getStoreDetail({ stallId: v.stallId });
            await this.props.getStoreDetail({ contentDataJson: this.props.query.sn, viewStatus: false });//'eyJzdGFsbElkIjoxMDAwNSwidmlld1N0YXR1cyI6dHJ1ZX0='
        }

    }

    render() {
        const { data = [] } = this.props;
        return <div className="goods">
            {data.map((v, i) =>
                <div key={i} className="goods-item">
                    <div>
                        {/* <div><img src={require(`../../../common/images/store/${this.state['z' + i] ? '2' : '1'}.png`)} alt='' /></div> */}
                        <div><img src={v.imageList && v.imageList[0]} alt='' /></div>
                        <div>
                            <div>{v.productName}</div>
                            <div>{v.saleWeight}</div>
                            <div>{v.slogan}</div>
                        </div>
                    </div>
                    <div onClick={(e) => this.down(e, v, i)}>
                        <div><img src={require(`../../../common/images/store/${this.state['z' + i] ? '2' : '1'}.png`)} alt='' /></div>
                        <div>{v.likeNum}</div>
                    </div>
                </div>)}
        </div>
    }
}

export default Goods;