import qs from 'qs';
// import { URL } from './utils/urls';
import { getWXSignature } from './state/request/wx';
import { getWXShare } from './utils/common';
(
    async () => {
        // var href = window.location.href;
        // const basename = href.substring(0, href.indexOf('?'));
        const signature = await getWXSignature({ url: window.location.href });
        // console.log('signature', signature);
        // alert('signature' + signature)
        if (signature && typeof signature === 'object' && signature.code === 200) {
            window.sessionStorage.setItem('wxSignature', signature.data)
            const data = signature.data;
            // // alert('signature.data' + signature.data)
            window.wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId || 'wx4cc13c86d2c13a63', // 0
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    // 'onMenuShareAppMessage',  //旧的接口，即将废弃
                    // 'onMenuShareTimeline' //旧的接口，即将废弃
                ] // 必填，需要使用的JS接口列表
            });


            //需在用户可能点击分享按钮前就先调用
            window.wx.ready(function () {
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，
                //所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

                // wx.checkJsApi({
                //     jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                //     success: function (res) {
                //         // 以键值对的形式返回，可用的api值true，不可用为false
                //         // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                //         console.log(res)
                //     }
                // });
                const objShareData = getWXShare() || {};
                // alert('objShareData' + JSON.stringify(objShareData) + '111' + JSON.stringify(window.sessionStorage))
                let title = document.title;
                let desc = '有了菜小秘，省时！省心！更省钱！农批生意简单了！';
                let link = window.location.href;
                if (objShareData.title) {
                    title = objShareData.title;
                    desc = objShareData.desc;
                    link = objShareData.link;
                } else {
                    const query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
                    title = query && query.title ? query.title : title;
                    desc = query && query.content ? query.content : desc;
                    // link = window.location.href;
                }
                // alert('title' + JSON.stringify(title) + '111' + JSON.stringify(desc))
                const imgUrl = 'http://statics.cxiaomi.com/caixm/logo/logo2.png';
                // console.log('query', query)
                //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
                window.wx.updateAppMessageShareData({
                    title: objShareData.title || title || 'title', // 分享标题
                    desc: objShareData.desc || desc || 'desc', // 分享描述
                    link: objShareData.link || link || 'link', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://statics.cxiaomi.com/caixm/logo/logo2.png',//imgUrl, // 分享图标
                    success: function () {
                        // 设置成功
                        // alert('自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）')
                    }
                })

                //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
                window.wx.updateTimelineShareData({
                    title: title, // 分享标题
                    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })

            });

            window.wx.error(function (res) {
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
        }

    }
)();

