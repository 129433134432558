import * as actionTypes from '../constant/store';
import * as req from '../request/store';

/**
 * 获取home头部数据
 */
export function getStoreDetail(params) {
    return async dispatch => {
        const res = await req.getStoreDetail(params);
        if (res) {
            dispatch({ type: actionTypes.STORE_DATA, payload: res })
        }
    }
}
/**
 * 点赞
 */
export function updateLikes(params) {
    return async dispatch => {
        const res = await req.updateLikes(params);
        if (res) {
            dispatch({ type: actionTypes.STORE_UPDATE_LIKES, payload: res })
        }
    }
}