import * as actionTypes from '../constant/order';

//初始状态
const initState = {
    data: {},
    creditData: {},//赊欠分享页面
    repayData: {},//还款分享页面
    statistics: { data: {} },//统计
    detail: { data: {} },//详细页面的数据
    // creditAmount: 0,
    // spendAmount: 0,
    // refundAmount: 0,
    // customerName,
    // firstCreditTime,
    // dkSaleOrderVoLst,
    // refundOrderNoList,
    // spendOrderNoList,
    // stallName,
}
//reducer
export function order(state = initState, action) {
    switch (action.type) {
        case actionTypes.ORDER_DATA:
            const returnData = {};
            const { code, data } = action.payload;
            if (code === 200) {
                const {
                    creditAmount = 0, spendAmount = 0, refundAmount = 0, customerName,
                    firstCreditTime, dkSaleOrderVoLst, refundOrderNoList, spendOrderNoList,
                    stallName, selectedCreditAmount,
                } = data;
                // console.log("data.creditAmount", creditAmount)
                returnData.creditAmount = creditAmount;
                returnData.spendAmount = spendAmount;
                returnData.refundAmount = refundAmount;
                returnData.customerName = customerName;
                returnData.firstCreditTime = firstCreditTime;
                returnData.array = dkSaleOrderVoLst.filter(item => item.creditAmount > 0);
                returnData.arr = dkSaleOrderVoLst;
                returnData.refundOrderNoList = dkSaleOrderVoLst.filter(item => refundOrderNoList.indexOf(item.orderNo) > -1);;
                returnData.spendOrderNoList = dkSaleOrderVoLst.filter(item => spendOrderNoList.indexOf(item.orderNo) > -1);
                returnData.stallName = stallName ? stallName + '-' + customerName : '';
                returnData.selectedCreditAmount = selectedCreditAmount;
            }
            return {
                ...state,
                data: { ...returnData },
            }
        case actionTypes.ORDER_DATA_2_CREDIT:
            if (state.creditData.data) {
                return { ...state, creditData: { ...state.creditData, ...action.payload, data: { ...{ ...state.creditData.data, ...action.payload.data }, pageList: [...state.creditData.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, creditData: { ...state.creditData, ...action.payload } };
            }
        case actionTypes.ORDER_DATA_2_REPAY:
            if (state.repayData.data) {
                return { ...state, repayData: { ...state.repayData, ...action.payload, data: { ...{ ...state.repayData.data, ...action.payload.data }, pageList: [...state.repayData.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, repayData: { ...state.repayData, ...action.payload } };
            }
        case actionTypes.ORDER_DATA_2_STATISTICS:
            return { ...state, statistics: action.payload }
        case actionTypes.ORDER_DATA_2_DETAIL:
            return { ...state, detail: action.payload }
        default:
            return state;
    }
}