import * as actionTypes from '../constant/shareAccount';
import { handleTimeMonthDay, handleToTimeStamp } from '../../utils/math';

//初始状态
const initState = {
    sum: {},
    list: [],
}
//reducer
export function shareAccount(state = initState, action) {
    if (action.type === actionTypes.SHARE_ACCOUNT_DATA) {
        let returnList = [];
        const { code, data } = action.payload;
        if (code === 200) {

            let total = {
                saleAmount: 0,
                payAmount: 0,
                creditAmount: 0
            }
            data.map((item, i) => {
                const { orderTime, orderDetails, weightUnit } = item
                // console.log("orderDetails",orderDetails)
                orderDetails.map((order, index) => {
                    if (index === 0) {
                        console.log("orderTime", orderTime)
                        order.orderTime = handleTimeMonthDay(handleToTimeStamp(orderTime))
                    }
                    if (order.saleAmount) {
                        total.saleAmount = total.saleAmount + order.saleAmount
                    }
                    if (order.payAmount) {
                        total.payAmount = total.payAmount + order.payAmount
                    }
                    if (order.creditAmount) {
                        total.creditAmount = total.creditAmount + order.creditAmount
                    }

                    order.extend3 = order.avgPriceUnit;
                    const findIndex = order.extend3.indexOf('/');
                    const numberUnit = findIndex === -1 ? order.extend3 : order.extend3.substring(findIndex + 1);
                    // order.saleNumber = order.saleNumber;
                    if (order.packKind === 7) {
                        order.saleNumberUnit = numberUnit;
                    } else {
                        order.saleNumberUnit = '件';
                    }

                    if (order.packKind === 7) {
                        order.saleWeight = '-';
                        order.saleWeightUnit = '-';
                    } else if (order.packKind === 1) {
                        if (order.extend3 === '元/件') {
                            //定装货重量单位取值需判断“extend3=元/件”，若为元/件，则重量单位取weigt_unit的值。若不为元/件，则取extend3中的重量单位    +
                            order.saleWeightUnit = weightUnit;
                        } else {
                            order.saleWeightUnit = numberUnit;
                        }
                    } else {
                        order.saleWeightUnit = numberUnit;
                    }
                    returnList.push(order);
                    return null;
                })
                return null;
            })
        }
        return {
            ...state,
            list: returnList,
        }
    } else if (action.type === actionTypes.SHARE_ACCOUNT_TOTAL_DATA) {
        let returnObject = {};
        const { code, data } = action.payload;
        if (code === 200) {
            returnObject = { ...data };
        }
        return {
            ...state,
            sum: returnObject,
        }
    } else {
        return state;
    }
}