import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { BackTop, Button, Modal, Affix, Icon } from 'antd';
import { Tabs } from 'antd-mobile';

import Goods from './index/goods';
// import RenderTabBar from '../../component/render-tab-bar/index';
import RenderTabBar2 from '../../component/render-tab-bar2/index';
import { getStoreDetail } from '../../state/action/store';

import './index.less';

const iconStallAddress = require(`../../common/images/store/stall-address.png`);
const iconStatistics1 = require(`../../common/images/store/statistics1.png`);
const iconStatistics2 = require(`../../common/images/store/statistics2.png`);
const iconStore = require(`../../common/images/store/store.png`);
const iconPhone = require(`../../common/images/store/phone.png`);
// const iconPic = require(`../../common/images/store/pic.png`);
// const iconPhone2 = require(`../../common/images/store/phone2.png`)
const iconMore = require(`../../common/images/store/....png`);
const iconFriend = require(`../../common/images/store/friend.png`);
const iconFriend2 = require(`../../common/images/store/friend2.png`);

@withRouter
@connect(
  state => state.store,
  { getStoreDetail }
)
class Index extends Component {
  constructor(props) {
    super(props);
    this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
    this.state = {
      // visible: false,
      modalShare: false,
      // phone: '',
    }
  }

  async componentDidMount() {
    // await this.props.getStoreDetail({ stallId: this.query.stallId });
    await this.props.getStoreDetail({ contentDataJson: this.query.sn, viewStatus: true });//'eyJzdGFsbElkIjoxMDAwNSwidmlld1N0YXR1cyI6dHJ1ZX0='
    const { homeData = {} } = this.props || {};
    document.title = homeData.storeName || '菜小秘 - 我的商城';
    document.getElementById("root").classList.add("store-root");
    document.body.classList.add("store-body");
  }

  componentWillUnmount() {
    document.getElementById("root").classList.remove("store-root");
    document.body.classList.remove("store-body");
  }

  // showModal(phone) {
  //   this.setState({
  //     visible: true,
  //     phone,
  //   });
  // }
  handleCancel = e => {
    this.setState({ visible: false, });
  };

  //打开分享模态框
  handleShare = () => {
    this.setState({ modalShare: true });
  }
  handleCancelShare = e => {
    // console.log(e);
    this.setState({ modalShare: false, });
  };

  //固定采购联系电话
  handleAffix(affixed) {
    // console.log(affixed, this.refTelephone, this.refTelephone.offsetHeight);
    if (affixed) {//如果固定了电话，那么计算下个固定的高度
      this.setState({ topContent: this.refTelephone.offsetHeight - 1 || 40 });//-1是计算是会有小数，造成某些手机滑动时没有遮挡住
    }
  }

  render() {
    // console.log('store index.js render()', this);
    const { homeData = {} } = this.props || {};
    const data = homeData;

    return <div className='index'>
      <div className="container">
        <div className="header">
        </div>
        <div className='headerBox'>
          <div className="stall-icon"><img src={iconStore} alt='' /></div>
          <div className="stall-content">
            <div className="stall-name">{data.storeName}</div>
            <div className="stall-address">
              <div><img src={iconStallAddress} alt='' /></div>
              <div><span>{data.address}</span></div></div>
            <div className="stall-sell">主营品类：{data.mainBusiness}</div>
            <div className="stall-statistics">
              <div>
                <div><img src={iconStatistics1} alt='' /></div>
                <div><span>{data.scan}</span></div>
              </div>
              <div>
                <div><img src={iconStatistics2} alt='' /></div>
                <div><span>{data.likeNum}</span></div>
              </div>
            </div>
          </div>
        </div>

        <Affix offsetTop={this.state.top || 0} onChange={affixed => this.handleAffix(affixed)}>
          <div className='telephone' ref={el => this.refTelephone = el}>
            <div className='telephone-content'>
              <div>采购联系电话</div>
              <div>
                {data.contactVoList && data.contactVoList.map(v => <Button
                  key={v.contactPhone} type="primary" shape="round" size="large"
                  // onClick={() => this.showModal(v.contactPhone)}
                  onClick={() => window.location.href = `tel:${v.contactPhone}`}
                >
                  <img src={iconPhone} alt='' />
                  {v.contactName}
                </Button>
                )}
              </div>
            </div>
          </div>
        </Affix>

        <div className="content">
          <Tabs
            tabs={data.storeProductCategoryResultList && data.storeProductCategoryResultList.map(v => { return { title: v.categoryName } })}
            initialPage={(this.query.tabIndex && parseInt(this.query.tabIndex, 10)) || 0}
            swipeable={false}
            animated={true}
            onChange={(tab, index) => { console.log('onChange', index, tab); }}
            onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
            renderTabBar={props =>
              <Affix
                offsetTop={this.state.topContent || 0 + 32}
              >
                <Tabs.DefaultTabBar
                  {...props}
                  page={4}
                  renderTab={(tab) =>
                    <RenderTabBar2
                      goToTab={(index) => true}
                      {...props}
                      tab={tab}
                    />
                  }
                />
              </Affix>
            }
          >
            {
              data.storeProductCategoryResultList && data.storeProductCategoryResultList.map(v => {
                return <div key={v.id}>
                  <Goods type={v.categoryName} data={v.productResultList} query={this.query} />
                </div>
              })
            }
          </Tabs>

        </div>

        <div className="picGoods">
          {/* <Affix offsetBottom={this.state.bottom || 46}> */}
          <Button type="primary" shape="round" size="large" onClick={this.handleShare}>
            <Icon type="share-alt" />
          </Button>
          {/* </Affix> */}
        </div>
      </div>

      {/* <Modal
        // title="Basic Modal"
        visible={this.state.visible}
        // onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        style={{ top: 'auto', bottom: 0, position: 'absolute' }}
        // style={{ top: 20 }}
        className='modal-telephone'
      >
        <div>
          <Button onClick={() => window.location.href = `tel:${this.state.phone}`}>
            <div>
              <div><img src={iconPhone2} alt='' /></div>
              <div><span>呼叫 {this.state.phone}</span></div>
            </div>
          </Button>
        </div>
        <div><Button onClick={this.handleCancel}>取消</Button></div>
      </Modal> */}

      <Modal
        // title="Basic Modal"
        visible={this.state.modalShare}
        // onOk={this.handleOk}
        onCancel={this.handleCancelShare}
        footer={null}
        style={{ top: 0 }}
        // style={{ top: 20 }}
        className='modal-share'
      >
        <div>
          <div class="d3"></div>
          <div>
            <div>
              <span>点击</span>
              <span><img src={iconMore} alt='' /></span>
              <span>进行分享：</span>
            </div>
            <div>
              <div><img src={iconFriend} alt='' />可分享给朋友</div>
              <div><img src={iconFriend2} alt='' />可分享到朋友圈</div>
            </div>
          </div>
        </div>
      </Modal>

      <BackTop />
    </div >
  }
}

export default Index;
