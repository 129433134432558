import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { message } from 'antd';

import './style.less';

class Home extends Component {
    render() {
        // console.log('Home render()');
        return <div className='home'>
            <ul>
                <li><Link to="/">引导页</Link></li>
                <li><Link to="/order">档主端APP - 赊欠账单</Link></li>
                <li><Link to="/partialOrder">档主端APP - 部分赊欠账单</Link></li>
                <li><Link to="/orderDetail">档主端APP - 赊还款详情</Link></li>
                <li><Link to="/shareAccount">采购对账表</Link></li>
                <li><Link to="/supplierDataShare">货主端分享</Link></li>
                <li><Link to="/download">分享下载</Link></li>
                <li><Link to="/bill?sn=N21ndGpwbHRvOTNsYk0zQjNVSjZLWjE4Y1VUdEFJZitTVkhxWkVRZDdWNytzQUkvd1FCNENFWmdId0YzS2JyOGVpSWFqSU4xaGhRaWpFVlh4N2kzZzZDZ0ppdzBoeW43dmh2UE0raktVcDVoRDRFdzR1SmxkVHB5SzhFMVdYWHNDZlFuMi95QWRmOWJEd1BjVG9ZajJRPT0=">
                    菜小秘APP - 账单分享v1.1.1</Link></li>
                <li><Link to="/purchaseStatement">菜小秘APP - 采购对账单</Link></li>
                <li><Link to="/buyerCredit">菜小秘APP - 买家赊欠对账单</Link></li>
                <li><Link to="/buyerCreditDetail">菜小秘APP - 买家赊欠对账单详情</Link></li>
                <li><Link to="/buyerCreditDetail5">菜小秘APP - 买家赊欠对账单,赊欠，还款详情</Link></li>
                <li><Link to="/supplierSalesStatistics">菜小秘APP - 批次销售统计</Link></li>
                <li><Link to="/supplierSalesStatisticsDetail">菜小秘APP - 批次销售统计详情</Link></li>
                <li><Link to="/sharecxm">菜小秘APP - 分享下载 - 废弃 - 公众号，POS机都到这个页面</Link></li>
                <li><Link to="/cxmDownload">菜小秘APP - 分享下载 </Link></li>
                <li><Link to="/store">商城 - 首页</Link></li>
            </ul>
        </div>

    }

}

export default Home;