import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { getCreditDetailData } from '../../state/action/buyerCredit';
// import { Icon } from 'antd';
import { Modal, Carousel } from 'antd-mobile';
import { ORDER_STATUS } from '../../utils/status';
// import { URL_ORDER_TRACK } from '../../utils/urls';

import './detailOrderType5.less';
import moment from 'moment';
// import { fixedZeroTo4Bit } from "../../utils/math";
// import Back from '../../component/back/index';
import backImgSrc from '../../common/images/icon_left.png';

function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

@withRouter
@connect(
    state => state.buyerCredit,
    { getCreditDetailData }
)
class BuyerCreditDetail5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            modal1: false,
        }

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        // this.sn = decodeURI(this.query.sn);
    }

    async componentDidMount() {
        // const { pathname } = this.props.location.state.back;
        document.title = "订单详情";//pathname === '/purchaseStatement' ? `采购对账单详情` : `赊还款详情`;
        document.getElementById("root").classList.add("buyer-credit-detail5-root");
        document.body.classList.add("buyer-credit-detail5-body");
        // this.orderId = this.props.location.state; //订单号
        const { sn, id } = this.query;
        await this.props.getCreditDetailData({
            sn,
            param: id,
        });
        // 监听message事件    
        // window.addEventListener("message", this.receiveMessage, false);
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("buyer-credit-detail5-root");
        document.body.classList.add("buyer-credit-detail5-body");
        // window.removeEventListener("message", this.receiveMessage);
    }

    // receiveMessage = (event) => {
    //     console.log('receiveMessage', event);
    //     if (event != null && event.data && event.data instanceof Object && event.data.type === "订单轨迹") {
    //         // console.log('我是react,我接受到了来自iframe的数据：', event.data);
    //         document.getElementById('iframeOrderTrack').height = event.data.currDivHeight + 50;
    //     }
    // };
    // imgRotateChange = e => {
    //     if (this.state.imgRotate === 'rotate(90deg)') {
    //         this.setState({ imgRotate: 'rotate(270deg)', freshState: true })
    //     } else {
    //         this.setState({ imgRotate: 'rotate(90deg)', freshState: false })
    //     }
    // }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    toBack = () => {
        this.props.history.push({
            pathname: this.query.back ? this.query.back : '/buyerCredit',//this.query.back有2个值 'purchaseStatement'， '/buyerCredit'
            search: qs.stringify({ sn: this.query.sn, tabIndex: this.query.tabIndex })
        })
    }

    showModal = (e, key, v, i) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
            selectedIndex: i,
        });
    }
    onClose = key => () => {
        console.log('onClose = key => () => {', key);

        this.setState({
            [key]: false,
            selectedIndex: 0,
        });
    }

    onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    render() {
        // console.log('detail.js Render', this.state)
        const { buyerName, statusWatermark, createTime, orderSaleAmount, imgs = [
            // "https://www.caixm.cn/static/media/banner.29ebcf53.png",
            // "https://www.caixm.cn/static/media/product-banner.ce4d4bb4.png",
            // "https://www.caixm.cn/static/media/solution-banner.194959a4.png",
            // "https://www.caixm.cn/static/media/client-banner.4084cb88.png",
            // "https://www.caixm.cn/static/media/about-banner.a398789f.png",
        ], paymentAmount, creditAmount
            , remark = ""//"没有备注就不显示，此为档主端可能有备注的情况，才显示此备注信息。"
        } = this.props.buyCreditDetailData.data || {};

        // console.log('orderDiscountAmount', productVOList)
        // let access_token = '', stall_id = '';
        // if (this.query.token) {
        //     const token = BASE64_decode(this.query.token).split('_');
        //     if (token && token.length === 2) {
        //         stall_id = token[0];
        //         access_token = token[1];
        //     }
        // }
        // const orderUrl = URL_ORDER_TRACK.replace('[ORDERNO]', orderNo).replace('[STATUS]', orderStatus).replace('[ACCESSTOKEN]', access_token).replace('[STALLID]', stall_id);
        // const pathname = this.props.location && this.props.location.state && this.props.location.back && this.props.location.state.back.pathname;
        return <div className='buyer-credit-detail5-index'>
            <div className='container'>
                <div className="header">
                    <div className='headerBox'>
                        <div className="headerNum"><span>历史赊欠</span>{buyerName}</div>
                        {/* <div className="creatTime">开单时间: {moment(orderCreateTime).format('YYYY-MM-DD HH:mm')}</div> */}
                        <div className="signTime">{`赊欠时间`}:  {moment(createTime).format('YYYY-MM-DD')}</div>
                        <div className='headerStatu'>
                            <div className={statusWatermark === 1 ? 'status1' : 'status3'}>{ORDER_STATUS.get(statusWatermark)}</div>
                        </div>
                    </div>
                </div>
                <div className='detailTop'>
                    <div className='detailItem'>
                        <div>
                            <span className='title'>{orderSaleAmount}</span><span>应收</span>
                        </div>
                        {/* {paymentAmount > 0 ? */}
                        <div>
                            <span className='title'>{paymentAmount}</span><span>已付</span>
                        </div>
                        {/* : null
                        } */}
                        <div>
                            <span className='title1'>{creditAmount}</span><span>下欠</span>
                        </div>
                    </div>
                </div>
                {/* <div className='detailContent'>
                    <div className='contentHeader'>
                        <span>菜品</span>
                        <span>数量</span>
                        <span>重量</span>
                        <span>单价</span>
                        <span>小计</span>
                    </div>
                    <div className='content'>
                        {productVOList.map((v, i) => {
                            return (
                                <div className='contentItem' key={i}>
                                    <span className='contentItem-name'> {v.attrName}</span>
                                    <span>{v.saleNumber}</span>
                                    <span>{v.saleWeight}</span>
                                    <span>{v.unitPrice}</span>
                                    <span>{v.saleAmount}</span>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

                {
                    imgs && imgs.length > 0 ?
                        <div className='detailImages'>
                            <div className='detailItem'>
                                <div>赊欠记录附件</div>
                                <div>{imgs.map((v, i) => <div key={v} onClick={e => this.showModal(e, 'modal1', v, i)}><img src={v} alt="菜小秘" /></div>)}</div>
                            </div>
                        </div>
                        : null
                }

                {
                    remark ?
                        <div className='detailRemark'>
                            <div className='detailItem'>
                                <div>备注</div>
                                <div>{remark}</div>
                            </div>
                        </div>
                        : null
                }
                <Modal
                    visible={this.state.modal1}
                    transparent
                    maskClosable={true}
                    onClose={this.onClose('modal1')}
                    // title="Title"
                    // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    // afterClose={() => { alert('afterClose'); }}
                    style={{ width: '100%', padding: 0 }}
                >
                    <div onClick={this.onClose('modal1')}>
                        <Carousel
                            autoplay={false}
                            vertical={false}
                            infinite
                            // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                            // afterChange={index => console.log('slide to', index)}
                            selectedIndex={this.state.selectedIndex}
                        >
                            {imgs.map(val => (
                                // <a
                                //     key={val}
                                //     href="/"
                                //     style={{ display: 'inline-block', width: '100%', height: this.state.imgHeight }}
                                // >
                                <img
                                    key={val}
                                    src={val}
                                    alt=""
                                    style={{ width: '100%', verticalAlign: 'top' }}
                                    onLoad={() => {
                                        // fire window resize event to change height
                                        window.dispatchEvent(new Event('resize'));
                                        // this.setState({ imgHeight: 'auto' });
                                    }}
                                    
                                />
                                // </a>
                            ))}
                        </Carousel>
                    </div>
                    {/* <div style={{ height: 100, overflow: 'scroll' }}>
                        scoll content...<br />
                        scoll content...<br />
                        scoll content...<br />
                        scoll content...<br />
                        scoll content...<br />
                        scoll content...<br />
                    </div> */}
                </Modal>
                {
                    // remark ?
                    //     <div className='detailRemark'>
                    //         <div className='detailItem'>
                    //             <div>备注</div>
                    //             <div>{remark}</div>
                    //         </div>
                    //     </div>
                    //     : null
                }

                {/* <div className='detailButtom'>
                    <div className='detailButtom-top'>
                        <span>订单的一生</span>
                        <span onClick={this.toggleCollapsed}>
                            <Icon type={this.state.collapsed ? 'up' : 'down'} />
                        </span>
                    </div>
                    {this.state.collapsed ?
                        <div className='detailButtom-bom'>
                            {orderNo &&
                                <iframe
                                    id="iframeOrderTrack"
                                    key={orderNo}
                                    // src='http://192.168.5.111:8001/#/batch/Profile?env=dev&orderNo=379930790487781376&status=10&accessToken=d0c8c7be98a2f57b5ba973f2706dcbd7&appType=web&stallId=10005&code=react'
                                    src={orderUrl}
                                    // height='800'
                                    width='100%'
                                    frameBorder='0'
                                    title='订单轨迹'
                                >订单轨迹</iframe>
                            }
                        </div>
                        : null
                    }

                </div> */}
                {/* <div className="detail-footer"></div>
                <div className="footer">
                    <div className="count">应收：<span>{orderSaleAmount}</span></div>
                    <div className="countPrice">合计：<span>{originOrderSaleAmount}</span></div>
                </div> */}
            </div>
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
            {/* <Back {...this.props.location.state}/> */}
        </div>
    }
}

export default BuyerCreditDetail5;