import React, { Component } from "react";

import './index.less'
/**
 * 学习统计中的TabBar
 */
export default class RenderTabBar extends Component {
    render() {
        console.log(this.props)
        /**
         * tabs 存放每一项内容的标题的数组
         */
        const { tabs = [], tab, activeTab } = this.props;
        const isActive = tabs.findIndex(v => v.title === tab.title) === activeTab;
        return (
            <div className="render-tab-bar-component2">
                <div
                    className={`${isActive ? 'activeTab' : ''}`}
                >
                    <div >{tab.title}</div>
                    <div></div>
                </div>

            </div>
        );
    }
}
