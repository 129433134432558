import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { register, getData, getUUID } from '../../state/action/supplierDataShare';
import { handleTimeYearMonthDay } from "../../utils/math";


import Register from './index/register';
import supplierLogo from './images/supplier_logo.png';

import './style.less';

const statusList = [
    {
        key: 1,
        value: '待设置行费',
        color: ''
    },
    {
        key: 4,
        value: '已设置行费',
        color: ''

    },
    {
        key: 5,
        value: '开售',
        color: '#E6A23C'

    },
    {
        key: 6,
        value: '停售',
        color: '#F67951'

    },
    {
        key: 9,
        value: '售罄',
        color: '#F67951'
    },
    {
        key: 11,
        value: '结单',
        color: '#2699FB'
    }
];
const defaultBatchGoodsResults = [
    {
        averagePrice: 3,
        batchItemId: 52338,
        goodsAmount: 270,
        goodsName: "西瓜",
        saleNumber: 90,
        saleWeight: 90,
        status: 5,
        unit: "元/公斤",
    },
    {
        averagePrice: 5,
        batchItemId: 52342,
        goodsAmount: 750,
        goodsName: "土豆",
        saleNumber: 150,
        saleWeight: 150,
        status: 5,
        unit: "元/公斤",
    },
    {
        averagePrice: 7.55,
        batchItemId: 52485,
        goodsAmount: 823,
        goodsName: "白菜",
        saleNumber: 109,
        saleWeight: 2180,
        status: 5,
        unit: "元/件",
    },
    {
        averagePrice: 9.2,
        batchItemId: 52486,
        goodsAmount: 230,
        goodsName: "西瓜",
        saleNumber: 11,
        saleWeight: 25,
        status: 5,
        unit: "元/公斤",
    },
    {
        averagePrice: 0,
        batchItemId: 52487,
        goodsAmount: 150,
        goodsName: "土豆",
        saleNumber: 34,
        saleWeight: 10,
        status: 5,
        unit: "元/公斤",
    }
];

//采购对账表
@withRouter
@connect(
    state => state.supplierDataShare,
    { register, getData, getUUID }
)
class SupplierDataShare extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
        }

        // this.query = qs.parse(this.props.location.search.substring(1));
        // this.query = qs.parse(this.props.location.search.substring(this.props.location.search.lastIndexOf('?') + 1));
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        this.query = {
            batchId: this.query.bId,
            stallId: this.query.sId,
            empId: this.query.eId,
            startTime: handleTimeYearMonthDay(this.query.st),
            endTime: handleTimeYearMonthDay(this.query.et)
        }

        this.downloadAPP = this.downloadAPP.bind(this);
        this.changeShow = this.changeShow.bind(this);
    }

    async componentDidMount() {
        document.title = `数据分享`;
        document.body.classList.add("supplier-data-share-body");
    }
    componentWillUnmount() {
        document.body.classList.remove("supplier-data-share-body");
    }

    downloadAPP() {
        console.log('触摸事件');
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.zhiyi.cxm.supplier";
    }
    changeShow() {
        this.setState({ show: !this.state.show });
    }
    getStatus(status) {
        const list = statusList.filter(v => v.key === status);
        if (list && list.length > 0) {
            const item = list[0];
            return <div className="content-dataState" style={{ backgroundColor: item.color }}>{item.value}</div>;
        }
        return null;
    }
    render() {
        console.log('supplier-data-share render()', this);

        const { show } = this.state;
        const { batchGoodsResults = defaultBatchGoodsResults, sum = '0', stallName = '' } = this.props.data

        return <div className='supplier-data-share-index'>

            <div className="header">
                <img src={supplierLogo} className='imageLogo' alt=''></img>

                <span className="header-name"> 销量同步 省时省力 货款结算 无需担心 <br /> 数据一键分享 即刻下载体验 </span>
                <span className="header-btn" onClick={this.downloadAPP}>点我下载</span>
            </div>

            <div className="content">
                <div className="content-headerBox">
                    <div className="content-header">
                        <span className="content-headerData">总货款: </span>
                        <span className="content-headerNum">{show ? '****' : sum}</span>
                        <span className="content-headerData">元</span>
                    </div>
                    <div className="content-header2">
                        <span className="content-headerData">商行: </span>
                        <span className="content-headerfiremName" >{show ? '*****' : stallName}</span>
                    </div>
                </div>
                <div>
                    {batchGoodsResults.map((item, index) =>
                        <div key={index} className="content-dataBox">
                            <div className="content-data">
                                <div className="content-dataNumBox">
                                    <span className="content-dataNum">{index + 1}</span>
                                </div>
                                <div className="content-dataDetail">
                                    <span className="content-dataName" >{show ? '*****' : item.goodsName}</span>
                                    <div className="content-datadetailBox">
                                        <div className="content-dataDetailItem">
                                            <span className="content-dataDetailItemTop"> 件数 </span>
                                            <span className="content-dataDetailItemNum" >{show ? '***' : item.saleNumber} </span>
                                        </div>
                                        <div className="content-dataDetailItem">
                                            <span className="content-dataDetailItemTop"> 重量（公斤） </span>
                                            <span className="content-dataDetailItemNum" >{show ? '***' : item.saleWeight} </span>
                                        </div>
                                        <div className="content-dataDetailItem">
                                            <span className="content-dataDetailItemTop"> 均价（{item.unit}）  </span>
                                            <span className="content-dataDetailItemNum"  >{show ? '***' : item.averagePrice} </span>
                                        </div>
                                        <div className="content-dataDetailItem">
                                            <span className="content-dataDetailItemTop"> 货款（元） </span>
                                            <span className="content-dataDetailItemNum" >{show ? '***' : item.goodsAmount} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-dataStateBox" >
                                    {this.getStatus(item.status)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>


            <Register query={this.query} show={show} changeShow={this.changeShow}></Register>

        </div>
    }
}

export default SupplierDataShare;