import request from '../../utils/request';
import { API_SUPPLIER } from '../../utils/urls';

export function getData(params) {
    return request(API_SUPPLIER, 'batch/getBatchItemsByShare', params);
}
export function getUUID(params) {
    return request(API_SUPPLIER, 'batch/getShareUUId', params);
}
export function register(params) {
    return request(API_SUPPLIER, 'user/login', params);
}
export function addBathSharingRecord(params) {
    return request(API_SUPPLIER, 'batch/addBathSharingRecord', params);
}
export function getCode(params) {
    return request(API_SUPPLIER, 'user/smsSend', params);
}