import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import { BackTop } from 'antd';
import { Tabs } from 'antd-mobile';
import Credit from './index/credit';
import Repay from './index/repay';

import { getData } from '../../state/action/buyerCredit';
import RenderTabBar from '../../component/render-tab-bar/index';
// import icon_right from './images/icon_right.png';
// import icon_mark from './images/icon_mark.png';
// import { handleTimeYearMonthDay } from "../../utils/math";
// import List from './index/list';
// import { fixedZeroTo4Bit, strSplit } from "../../utils/math";

import './style.less';

@withRouter
@connect(
    state => state.buyerCredit,
    { getData }
)
class BuyerCredit extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));

        this.sn = decodeURI(this.query.sn);
        // console.log('sn', this.sn)

        // this.state = {
        // data: [],
        // pageNo: 1,
        // pageSize: 10,
        // refreshing: false,
        // height: document.documentElement.clientHeight,
        // tabIndex: 0,//tab切换用的索引
        // }
    }

    async componentDidMount() {
        // await this.props.getData({
        //     sn: this.sn,
        //     pageNo: 1,
        //     pageSize: 10
        // });
        // await this.getPageData(1);
        // const hei = this.state.height - ReactDOM.findDOMNode(this.ptr).offsetTop;
        // this.setState({ height: hei, })

        document.title = `赊还款对账单`;
        document.getElementById("root").classList.add("buyer-credit-root");
        document.body.classList.add("buyer-credit-body");
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("buyer-credit-root");
        document.body.classList.remove("buyer-credit-body");
    }

    // getPageData = async (pageNo) => {
    //     this.setState({ refreshing: true });
    //     await this.props.getData({
    //         sn: this.sn,
    //         pageNo,
    //         pageSize: 10,
    //     });

    // const { orderList = [], } = this.props.buyCreditData.data || {};
    // this.setState({ pageNo, data: [...this.state.data, ...orderList], refreshing: false });
    // if (orderList.length === 0) {
    //     message.info('没有更多的数据了！');
    // }
    // }

    // handleCreditDetail(id) {
    //     this.props.history.push({
    //         pathname: '/buyerCreditDetail',
    //         search: qs.stringify({
    //             sn: this.sn,
    //             id,
    //             token: this.query.token,
    //             back: '/buyerCredit',
    //             tabIndex: this.state.tabIndex,
    //         }),
    //         // state: { back: { pathname: '/buyerCredit', search: qs.stringify({ sn: this.sn }) } },
    //     });
    // }

    render() {
        console.log('buyer-credit index.js render()', this);
        const { stallName, stallPhone, buyerName, firstTime, totalCount, totalAmount } = this.props.buyCreditData.data || {};
        const firstDate = moment(firstTime).format('YYYY-MM-DD');//最早赊欠日
        // const lastDate = moment(lastTime).format('YYYY-MM-DD');//最近赊欠日
        // const { data, pageNo, refreshing } = this.state;
        return <div className='buyer-credit-index'>
            <div className="container">
                <div className="header">
                    <div>
                        <div>{stallName || stallPhone || '我的商行'}</div>
                        <div>
                            <div>{buyerName}</div>
                            {/* <span className="phone">{buyerPhone}</span> */}
                            <div><span>总欠款：</span><span>{totalAmount ? `¥ ${totalAmount}` : `0元`}</span></div>
                        </div>
                        <div>
                            <div>最早赊欠时间：{firstDate} </div>
                            <div><span>赊欠单数：</span><span>{totalCount}单</span></div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <Tabs
                        tabs={[
                            { title: "赊欠详情" },
                            { title: "还款详情" },
                        ]}
                        initialPage={(this.query.tabIndex && parseInt(this.query.tabIndex, 10)) || 0}
                        swipeable={false}
                        onChange={(tab, index) => { console.log('onChange', index, tab); }}
                        onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                        renderTabBar={(props) =>
                            <RenderTabBar
                                goToTab={(index) => true}
                                {...props}
                            />
                        }
                    >
                        <div>
                            <Credit query={this.query} />
                        </div>
                        <div>
                            <Repay query={this.query} />
                        </div>

                    </Tabs>

                    {/* <div className="title" >订单信息</div>
                    <PullToRefresh
                        key={1}
                        ref={el => this.ptr = el}
                        damping={100}
                        distanceToRefresh={window.devicePixelRatio ? window.devicePixelRatio * 25 : 25}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        indicator={{ deactivate: '上拉可以刷新' }}
                        direction={'up'}
                        refreshing={refreshing}
                        onRefresh={() => {
                          
                            this.getPageData(pageNo + 1);
                        }}
                    >
                        {data.map((v, i) => {
                            const { todayNo, buyerName, createTime, creditAmount, orderNo, saleAmount, attrNameList = [] } = v;
                            return (
                                <div key={i} className="orderinfo" onClick={() => this.handleCreditDetail(orderNo)}>

                                   
                                    <div>
                                        <div><span>票号：{todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}</span></div>
                                        <div><span>赊欠时间：</span><span>{moment(createTime).format('MM-DD HH:mm')}</span></div>
                                    </div>
                                    <div>
                                        <div>{buyerName}</div>
                                        <div>¥ {saleAmount}</div>
                                    </div>
                                    <div>
                                        <div>{strSplit(attrNameList.join('、'), 14)}</div>
                                        <div><span>下欠：</span><span>{creditAmount}元</span></div>
                                    </div>

                                </div>
                            )
                        })}
                    </PullToRefresh> */}

                </div>
                {/* <div className="footer">
                    <div className="date-size">
                        <div className="date">{createTime} 至 {lastTime}</div>
                        <div className="size">单数：{totalCount}单</div>
                    </div>
                    <div className="count">总计：<span className="number">{totalCreditAmount}</span></div>
                </div> */}

            </div>
            <BackTop />
        </div>
    }
}

export default BuyerCredit;
