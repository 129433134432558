

import request from '../../utils/request';
import { API_STALL } from '../../utils/urls';

export function searchCreditOrdersByCustomerName(params) {
    return request(API_STALL, 'order/searchCreditOrdersByCustomerName', params);
    // return {
    //     "code":200,
    //     "data":{
    //         "creditAmount":123456.00,
    //         "creditCount":1,
    //         "customerCount":0,
    //         "customerId":0,
    //         "customerName":"908线上测试校验",
    //         "customerPhone":"13297941816",
    //         "dkSaleOrderVoLst":[
    //             {
    //                 "alipayPaymentAmount":0.00,
    //                 "bankPaymentAmount":0.00,
    //                 "buyerName":"908线上测试校验",
    //                 "buyerUid":39448,
    //                 "cashPaymentAmount":0.00,
    //                 "cashierName":"杨威",
    //                 "cashierTime":1567934366230,
    //                 "cashierUid":10,
    //                 "chargeOrderFree":0.00,
    //                 "createName":"杨威",
    //                 "createOrderName":"杨威",
    //                 "createOrderUid":10,
    //                 "createTime":1567934366230,
    //                 "createUid":10,
    //                 "creditAmount":123456.00,
    //                 "enable":true,
    //                 "extend5":0,
    //                 "extraProcessFlag":false,
    //                 "groupPurchaseOrderNo":"",
    //                 "handId":0,
    //                 "hashCode":-487754776,
    //                 "id":2481633,
    //                 "operaWatermark":0,
    //                 "orderBillFee":0.00,
    //                 "orderCreateTime":1567353600000,
    //                 "orderDiscountAmount":0.00,
    //                 "orderGoodExtraAmount":0.00,
    //                 "orderGoodsAmount":123456.00,
    //                 "orderItemLst":[],
    //                 "orderNo":"MllhQ3BMZEdXNVdHY2JCcTB3d21VMUY3cjNIdXJUcGtqTjNEd0lUUTdBbzNXUlBiNllyZUtOUjZDTXdsVm4vNw==",
    //                 "orderOverchargeAmount":0.00,
    //                 "orderPackExtraAmount":0.00,
    //                 "orderPackFee":0.00,
    //                 "orderSaleAmount":123456.00,
    //                 "orderSaleNumber":1.00,
    //                 "orderSaleWeight":1.00,
    //                 "orderStatus":1,
    //                 "orderStatusDesc":"待付款",
    //                 "orderTrackLst":[],
    //                 "orderTradeExtraAmount":0.00,
    //                 "orderTradeFee":0.00,
    //                 "orderType":5,
    //                 "orderWeighingFee":0.00,
    //                 "originOrderSaleAmount":123456.00,
    //                 "otherPaymentAmount":0.00,
    //                 "payMethod":0,
    //                 "payStatus":15,
    //                 "payStatusDesc":"赊账",
    //                 "paymentAmount":0,
    //                 "refundAmount":0.00,
    //                 "revocationFlag":false,
    //                 "salerName":"杨威",
    //                 "salerUid":10,
    //                 "scanPaymentAmount":0.00,
    //                 "sourceType":1,
    //                 "stallId":10005,
    //                 "statusWatermark":0,
    //                 "todayNo":75,
    //                 "todayOrder":true,
    //                 "updateTime":1567934366319,
    //                 "updateUid":0,
    //                 "wechatPaymentAmount":0.00
    //             }
    //         ],
    //         "firstCreditTime":1567934366230,
    //         "orderNoList":[],
    //         "refundOrderNoList":[],
    //         "salerNameList":[
    //             "杨威"
    //         ],
    //         "spendOrderNoList":[],
    //         "stallName":"菜小宝商行",
    //         "todayCustomerCount":0
    //     },
    //     "msg":"操作成功"
    // }
}

//还款详细
export function searchRepayOrdersByCustomerName(params) {
    return request(API_STALL, 'order/searchRepayOrdersByCustomerName', params);
    // return {
    //     "code": 200,
    //     "data": {
    //         "pageList": [
    //             {
    //                 "buyerName": "李宁",
    //                 "creditTime": 1573193133030,
    //                 "dayRepayAmount": 4070.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4747588,
    //                 "orderNo": "377337710274277376",
    //                 "orderProductName": "白菜,白菜",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 9.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 500.00,
    //                         "unitStr": "件"
    //                     },
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 250.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 6.00,
    //                         "unitStr": "公斤"
    //                     }
    //                 ],
    //                 "repayAmount": 70.00,
    //                 "repayTime": 1575466740649,
    //                 "saleAmount": 6000.00,
    //                 "settled": false,
    //                 "todayNo": 14
    //             },
    //             {
    //                 "buyerName": "李宁",
    //                 "creditTime": 1573193133030,
    //                 "dayRepayAmount": 4070.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4747544,
    //                 "orderNo": "377337710274277376",
    //                 "orderProductName": "白菜,白菜",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 9.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 500.00,
    //                         "unitStr": "件"
    //                     },
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 250.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 6.00,
    //                         "unitStr": "公斤"
    //                     }
    //                 ],
    //                 "repayAmount": 500.00,
    //                 "repayTime": 1575457790814,
    //                 "saleAmount": 6000.00,
    //                 "settled": false,
    //                 "todayNo": 14
    //             },
    //             {
    //                 "buyerName": "李宁",
    //                 "creditTime": 1573193133030,
    //                 "dayRepayAmount": 4070.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4747543,
    //                 "orderNo": "377337710274277376",
    //                 "orderProductName": "白菜,白菜",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 9.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 500.00,
    //                         "unitStr": "件"
    //                     },
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 250.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 6.00,
    //                         "unitStr": "公斤"
    //                     }
    //                 ],
    //                 "repayAmount": 1000.00,
    //                 "repayTime": 1575457780458,
    //                 "saleAmount": 6000.00,
    //                 "settled": false,
    //                 "todayNo": 14
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1569754947393,
    //                 "dayRepayAmount": 4070.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4747539,
    //                 "orderNo": "362916414761209856",
    //                 "orderProductName": "10",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "10",
    //                         "packKind": 1,
    //                         "saleNumber": 100.00,
    //                         "saleWeight": 1000.00,
    //                         "unitPrice": 80.00,
    //                         "unitStr": "件"
    //                     }
    //                 ],
    //                 "repayAmount": 2500.00,
    //                 "repayTime": 1575457730100,
    //                 "saleAmount": 8902.00,
    //                 "settled": false,
    //                 "todayNo": 170
    //             },
    //             {
    //                 "buyerName": "李宁",
    //                 "creditTime": 1573193133030,
    //                 "dayRepayAmount": 3500.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4744376,
    //                 "orderNo": "377337710274277376",
    //                 "orderProductName": "白菜,白菜",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 9.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 500.00,
    //                         "unitStr": "件"
    //                     },
    //                     {
    //                         "attrName": "白菜",
    //                         "packKind": 7,
    //                         "saleNumber": 250.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 6.00,
    //                         "unitStr": "公斤"
    //                     }
    //                 ],
    //                 "repayAmount": 3500.00,
    //                 "repayTime": 1573193133030,
    //                 "saleAmount": 6000.00,
    //                 "settled": false,
    //                 "todayNo": 14
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1570586797218,
    //                 "dayRepayAmount": 3200.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4711052,
    //                 "orderNo": "366405984697978880",
    //                 "orderProductName": "莲子",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "莲子",
    //                         "packKind": 5,
    //                         "saleNumber": 0.00,
    //                         "saleWeight": 190.00,
    //                         "unitPrice": 50.00,
    //                         "unitStr": "公斤"
    //                     }
    //                 ],
    //                 "repayAmount": 100.00,
    //                 "repayTime": 1570603364772,
    //                 "saleAmount": 9881.00,
    //                 "settled": false,
    //                 "todayNo": 191
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1569754947393,
    //                 "dayRepayAmount": 3200.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4711041,
    //                 "orderNo": "362916414761209856",
    //                 "orderProductName": "10",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "10",
    //                         "packKind": 1,
    //                         "saleNumber": 100.00,
    //                         "saleWeight": 1000.00,
    //                         "unitPrice": 80.00,
    //                         "unitStr": "件"
    //                     }
    //                 ],
    //                 "repayAmount": 100.00,
    //                 "repayTime": 1570586915335,
    //                 "saleAmount": 8902.00,
    //                 "settled": false,
    //                 "todayNo": 170
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1569754749385,
    //                 "dayRepayAmount": 3200.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4711039,
    //                 "orderNo": "362916088016539648",
    //                 "orderProductName": "大玉米",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "大玉米",
    //                         "packKind": 7,
    //                         "saleNumber": 100.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 200.00,
    //                         "unitStr": "条"
    //                     }
    //                 ],
    //                 "repayAmount": 3000.00,
    //                 "repayTime": 1570586753443,
    //                 "saleAmount": 20000.00,
    //                 "settled": true,
    //                 "todayNo": 169
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1569754749385,
    //                 "dayRepayAmount": 19902.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4710875,
    //                 "orderNo": "362916088016539648",
    //                 "orderProductName": "大玉米",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "大玉米",
    //                         "packKind": 7,
    //                         "saleNumber": 100.00,
    //                         "saleWeight": 0.00,
    //                         "unitPrice": 200.00,
    //                         "unitStr": "条"
    //                     }
    //                 ],
    //                 "repayAmount": 5000.00,
    //                 "repayTime": 1569754970479,
    //                 "saleAmount": 20000.00,
    //                 "settled": false,
    //                 "todayNo": 169
    //             },
    //             {
    //                 "buyerName": "啊啊啊",
    //                 "creditTime": 1569754947393,
    //                 "dayRepayAmount": 19902.00,
    //                 "discountAmount": 0.00,
    //                 "id": 4710874,
    //                 "orderNo": "362916414761209856",
    //                 "orderProductName": "10",
    //                 "overChargeAmount": 0.00,
    //                 "productInfoList": [
    //                     {
    //                         "attrName": "10",
    //                         "packKind": 1,
    //                         "saleNumber": 100.00,
    //                         "saleWeight": 1000.00,
    //                         "unitPrice": 80.00,
    //                         "unitStr": "件"
    //                     }
    //                 ],
    //                 "repayAmount": 2902.00,
    //                 "repayTime": 1569754947393,
    //                 "saleAmount": 8902.00,
    //                 "settled": false,
    //                 "todayNo": 170
    //             }
    //         ],
    //         "pageNo": 1,
    //         "pageSize": 10,
    //         "totalPage": 2,
    //         "totalSize": 19
    //     },
    //     "msg": "操作成功"
    // }
}

//分享信息统计
export function shareStatistics(params) {
    return request(API_STALL, 'order/shareStatistics', params);   
}
//赊欠订单
export function getCreditData(params) {
    // return request(API_STALL, 'order/shareCreditOrder', params);
    return request(API_STALL, 'order/shareCreditOrderNew', params);
}
//还款记录
export function getRepayData(params) {
    return request(API_STALL, 'order/shareRepayTrace', params);
}
export function getDetail(params) {
    return request(API_STALL, 'order/searchSaleBillByOrderNoForShare', params);       
}