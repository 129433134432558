// import cookies from 'browser-cookies'
import * as actionTypes from '../constant/purchase';
import * as req from '../request/purchase';

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData(params) {
    return async dispatch => {
        const res = await req.getData(params);
        if (res) {
            dispatch({ type: actionTypes.PURCHASE_HOME_DATA, payload: res, sn: params.sn, })
        }
    }
}
export function getPurchaseRecord(params) {
    return async dispatch => {
        const res = await req.getPurchaseRecord(params);
        if (res) {
            if (params.type === 1) {
                dispatch({ type: actionTypes.PURCHASE_PRCHASE_RECORD_1, payload: res });
            } else {
                dispatch({ type: actionTypes.PURCHASE_PRCHASE_RECORD_2, payload: res });
            }
        }
    }
}
export function getRepayRecord(params) {
    return async dispatch => {
        const res = await req.getRepayRecord(params);
        if (res) {
            dispatch({ type: actionTypes.PURCHASE_REPAY_RECORD, payload: res });
        }
    }
}

export function getCreditRepayCount(params) {
    return async dispatch => {
        const res = await req.getCreditRepayCount(params);
        if (res) {
            dispatch({ type: actionTypes.PURCHASE_CREDIT_REPAY_COUNT, payload: res });
        }
    }
}
export function getCreditRepay(params) {
    return async dispatch => {
        const res = await req.getCreditRepay(params);
        if (res) {
            dispatch({ type: actionTypes.PURCHASE_CREDIT_REPAY, payload: res });
        }
    }
}
export function getDetailData(params) {
    return async dispatch => {
        const res = await req.getDetailData(params);
        if (res) {
            dispatch({ type: actionTypes.PURCHASE_DETAIL_DATA, payload: res });
        }
    }
}
export function saveClickData(params) {
    return async dispatch => {
        dispatch({ type: actionTypes.PURCHASE_CREDIT_REPAY_CLICK_DATA, payload: params });
    }
}
export function clearRepayRecord() {
    return async dispatch => {
        dispatch({ type: actionTypes.PURCHASE_REPAY_RECORD_CLEAR });
    }
}
export function clearCreditRepay() {
    return async dispatch => {
        dispatch({ type: actionTypes.PURCHASE_CREDIT_REPAY_CLEAR });
    }
}
