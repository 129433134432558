export const PURCHASE_HOME_DATA = 'PURCHASE_HOME_DATA';
export const PURCHASE_PRCHASE_RECORD_1 = 'PURCHASE_PRCHASE_RECORD_1';
export const PURCHASE_PRCHASE_RECORD_2 = 'PURCHASE_PRCHASE_RECORD_2';
export const PURCHASE_REPAY_RECORD = 'PURCHASE_REPAY_RECORD';
export const PURCHASE_CREDIT_REPAY_COUNT = 'PURCHASE_CREDIT_REPAY_COUNT';
export const PURCHASE_CREDIT_REPAY = 'PURCHASE_CREDIT_REPAY';
export const PURCHASE_DETAIL_DATA = 'PURCHASE_DETAIL_DATA';
export const PURCHASE_CREDIT_REPAY_CLICK_DATA = 'PURCHASE_CREDIT_REPAY_CLICK_DATA';
export const PURCHASE_REPAY_RECORD_CLEAR = 'PURCHASE_REPAY_RECORD_CLEAR';
export const PURCHASE_CREDIT_REPAY_CLEAR = 'PURCHASE_CREDIT_REPAY_CLEAR';
