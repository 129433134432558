import * as actionTypes from '../constant/supplierDataShare';

//初始状态
const initState = {
    data: {},
    UUID: '',
    reduxRregister: {},
}
//reducer
export function supplierDataShare(state = initState, action) {
    if (action.type === actionTypes.SUPPLIER_DATA_SHARE_DATA) {
        const { code, data } = action.payload;
        if (code === 200) {
            return { ...state, data, }
        }
    } else if (action.type === actionTypes.SUPPLIER_DATA_SHARE_UUID) {
        const { code, data } = action.payload;
        if (code === 200) {
            return { ...state, UUID: data };
        }
    } else if (action.type === actionTypes.SUPPLIER_DATA_SHARE_REGISTER) {
        return { ...state, reduxRregister: action.payload };
    }

    return state;

}