// import { Buffer } from 'buffer';

// export function commEncode(str, source = 'utf8', cry = 'base64') {
//   var encoder = new Buffer(str, source).toString(cry);
//   return encoder;
// }

export function BASE64_encode(str) {
  // return commEncode(str, 'utf8', 'base64');
  return window.btoa(str) // 编码
}
export function BASE64_decode(str) {
  return window.atob(str) // 解码
}

export function fixedZeroTo4Bit(val, len) {
  let length = (val + '').length;
  let format = '';
  for (var i = len; i > length; i--) {
    format += '0';
  }
  return format + val;
}

// 处理时间为2019/03/12格式
export function handleTimeYearMonthDay(time) {
  var datetime = new Date();
  datetime.setTime(time);
  var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
  var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
  // console.log("结果为", year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second)
  return year + "-" + month + "-" + date
}


// 处理时间为月日模式 05/01
export function handleTimeMonthDay(time) {
  var datetime = new Date();
  datetime.setTime(time);
  // var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
  var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
  // console.log("结果为", year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second)
  return month + "/" + date
}

// 将时间格式转化为时间戳
export function handleToTimeStamp(time) {
  let newTime = new Date(time)
  // console.log("时间戳格式为", newTime.getTime())
  return newTime.getTime()
}
// 处理为时间戳 未 2019-10-04 hh:mm:ss的格式
export function handleTime(time) {
  var datetime = new Date();
  datetime.setTime(time);
  var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
  var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
  var hour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
  var minute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
  // var second = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
  // console.log("结果为", year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second)
  return year + "." + month + "." + date + " " + hour + ":" + minute;
}


//字符加省略号
export const strSplit = (str, len) => {
  if (!str) {
    str = ''
  }
  else if (str.length > len) {
    str = str.substring(0, len) + '...';
  }
  return str
}

// 判断是否微信打开
export function isWx() {
  var ua = window.navigator.userAgent.toLowerCase();
  // console.log('ua',ua,ua.match(/MicroMessenger/gi));

  if (/MicroMessenger/i.test(ua)) {
    return true;
  }
  return false;
}
// 判断是否微信打开
export function isAndroid() {
  var u = window.navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
}
// 判断是否微信打开
export function isiOS() {
  var u = window.navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}
// 判断是否QQ打开
export function isQQ() {
  var u = window.navigator.userAgent;
  return u.match(/QQ\/[0-9]/i);//如果返回true 则说明是qq
}
// 判断手机版 钉钉
export function isMobileDing() {
  var u = window.navigator.userAgent;
  return /(DingTalk)/i.test(u);//手机版 钉钉
}
