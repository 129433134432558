import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
// import { message } from 'antd';
import { PullToRefresh, ListView } from 'antd-mobile';

import { getDetailData } from '../../state/action/supplierSalesStatistics';
import { fixedZeroTo4Bit } from '../../utils/math';
// import Back from '../../component/back/index';
import backImgSrc from '../../common/images/icon_left.png';
import './detail.less';

@withRouter
@connect(
    state => state.supplierSalesStatistics,
    { getDetailData }
)
class BathDetail extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            // getRowData: (dataBlob, sectionID, rowID) => dataBlob[rowID],
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        });

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));

        this.state = {
            // data: [],
            pageNo: 1,
            // pageSize: 10,
            // refreshing: false,
            // height: document.documentElement.clientHeight,
            dataSource,
            refreshing: true,
            isLoading: true,
        }
    }

    async componentDidMount() {
        await this.getPageData(1);
        // const hei = this.state.height - ReactDOM.findDOMNode(this.ptr).offsetTop;
        // this.setState({ height: hei, })
        this.setState({
            // height: hei,
            refreshing: false,
            isLoading: false,
        });

        document.title = `批次销售明细`;
        document.getElementById("root").classList.add("bath-detail");
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("bath-detail");
    }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }

    onEndReached = async (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    };

    getPageData = async (pageNo) => {
        // const { totalSize = 0, pageList = [] } = this.props.detailData.data || {};
        // const reqPageIndex = Math.ceil(pageList.length / 10);//请求过的页数
        // if (pageNo <= reqPageIndex) {
        //     this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        // } else {
        //     if (totalSize > pageList.length || pageList.length === 0) {
        this.setState({ refreshing: true });
        const { sn, param } = this.query;
        await this.props.getDetailData({
            sn,
            param,
            pageNo,
            pageSize: 10,
        });

        const { pageList = [], } = this.props.detailData.data || {};
        this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        // if (pageNo * 10 > pageList.length) {
        // message.info('没有更多的数据了！');//ListView插件老是提醒
        //}      
        //     } else {
        //         const { pageList = [], } = this.props.detailData.data || {};
        //         this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
        //         // message.info('没有更多的数据了！');
        //     }
        // }
    }

    // getPageData = async (pageNo) => {
    //     const { sn, param } = this.query;
    //     this.setState({ refreshing: true });
    //     await this.props.getDetailData({
    //         sn,
    //         param,
    //         pageNo,
    //         pageSize: 10,
    //     });
    //     const { data = [] } = this.props.detailData || {};
    //     this.setState({ pageNo, data: [...this.state.data, ...data], refreshing: false });
    //     if (data.length === 0) {
    //         message.info('没有更多的数据了！');
    //     }
    // }
    toBack = () => {
        this.props.history.push({
            pathname: '/supplierSalesStatistics',
            search: qs.stringify({ sn: this.query.sn })
        })
    }

    render() {
        // const { data = [] } = this.props.detailData;
        // const { data, pageNo, refreshing } = this.state;
        // console.log(data)
        const row = (rowData, sectionID, rowID) => {
            // console.log("const row = (rowData, sectionID, rowID) => {", rowData, sectionID, rowID);
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);

            // //包装形式 1定装 3散装 2非定装 7多单位
            // //统计页面及分享页面： 定装显示 数量和数量单位 例如：5件
            // //非定装显示 数量和数量单位（重量和重量单位）例如：1件（20斤）
            // //散装显示 重量和重量单位 例如：20公斤
            // let sale = v.saleNumber;
            // let unit = v.productNumberUnit;
            // if (v.goodsPackId === 1) {
            //     sale = v.saleNumber;
            //     unit = v.productNumberUnit;
            // } else if (v.goodsPackId === 3) {
            //     sale = v.saleWeight;
            //     unit = v.productWeightUnit;
            // } else if (v.goodsPackId === 7) {
            //     sale = v.saleNumber;
            //     unit = v.productNumberUnit;
            // } else {
            //     sale = v.saleWeight;
            //     unit = v.productWeightUnit;
            // }

            //包装形式 1定装 3散装 2非定装 7多单位 5郊菜
            //统计页面及分享页面： 定装显示 数量和数量单位 例如：5件
            //非定装显示 数量和数量单位（重量和重量单位）例如：1件（20斤）
            //散装显示 重量和重量单位 例如：20公斤
            const { saleNumber = 0, productNumberUnit, saleWeight = 0, productWeightUnit } = v;
            let saleHtml = null;
            let unit = v.productNumberUnit;
            if (v.goodsPackId === 1) {
                saleHtml = <span>{saleNumber}{productNumberUnit}</span>;
                unit = v.productNumberUnit;
            } else if (v.goodsPackId === 3|| v.goodsPackId === 5) {
                if (saleNumber > 0) {
                    saleHtml = <span>{saleNumber}{productNumberUnit} <span className='itemNum'>({saleWeight}{productWeightUnit})</span></span>;                  
                } else {
                    saleHtml = <span><span className='itemNum'>{saleWeight}{productWeightUnit}</span> </span>;                   
                }
                unit = v.productWeightUnit;
            } else if (v.goodsPackId === 7) {
                saleHtml = <span>{v.saleNumber}{v.productNumberUnit}</span>
                unit = v.productNumberUnit;
            } else {
                saleHtml = <span>{saleNumber}{productNumberUnit} <span className='itemNum'>({saleWeight}{productWeightUnit})</span></span>;
                unit = v.productWeightUnit;
            }


            return (
                <div key={i} className='coontentItem'>
                    <div className='left'><span>{v.todayNo && fixedZeroTo4Bit(v.todayNo, 4)}</span></div>
                    <div className='right'>
                        <div className='top'>
                            <div className='name'>买家:&nbsp;&nbsp;{v.buyerName}</div>
                            <div className='time'>{v.createTime && moment(v.createTime).format('MM-DD HH:mm:ss')}<span>货款:&nbsp;&nbsp;{v.goodsAmount}元</span></div>
                        </div>
                        <div className='line'></div>
                        <div className='bottom'>
                            <div>销量:&nbsp;&nbsp;{saleHtml}</div>
                            <div className='price'>单价:&nbsp;&nbsp;{v.unitPrice}元/{unit}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return <div className='bath-detail'>
            <div className="content">
                <ListView
                    key={1}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    // renderHeader={() => <span>Pull to refresh</span>}
                    renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中...' : ''}
                        {/* {this.state.isLoading ? '加载中...' : '加载完成'} */}
                    </div>)}
                    renderRow={row}
                    // renderSeparator={separator}
                    useBodyScroll={true}
                    style={{
                        // height: this.state.height,
                        // border: '1px solid #ddd',
                        // margin: '5px 0',
                    }}
                    pullToRefresh={<PullToRefresh
                        refreshing={this.state.refreshing}
                        // onRefresh={this.onRefresh}
                        direction={'up'}
                    />}
                    onEndReached={this.onEndReached}
                    pageSize={10}
                />

                {/* <PullToRefresh
                    damping={100}
                    distanceToRefresh={window.devicePixelRatio ? window.devicePixelRatio * 25 : 25}
                    ref={el => this.ptr = el}
                    style={{
                        height: this.state.height + 500,
                        overflow: 'auto',
                    }}
                    indicator={{ deactivate: '上拉可以刷新' }}
                    direction={'up'}
                    refreshing={refreshing}
                    onRefresh={() => {
                        this.getPageData(pageNo + 1);
                    }}
                >
                    {data.map((v, i) => {
                        //包装形式 1定装 3散装 2非定装 7多单位
                        //统计页面及分享页面： 定装显示 数量和数量单位 例如：5件
                        //非定装显示 数量和数量单位（重量和重量单位）例如：1件（20斤）
                        //散装显示 重量和重量单位 例如：20公斤
                        let sale = v.saleNumber;
                        let unit = v.productNumberUnit;
                        if (v.goodsPackId === 1) {
                            sale = v.saleNumber;
                            unit = v.productNumberUnit;
                        } else if (v.goodsPackId === 3) {
                            sale = v.saleWeight;
                            unit = v.productWeightUnit;
                        } else if (v.goodsPackId === 7) {
                            sale = v.saleNumber;
                            unit = v.productNumberUnit;
                        } else {
                            sale = v.saleWeight;
                            unit = v.productWeightUnit;
                        }

                        return (
                            <div key={i} className='coontentItem'>
                                <div className='left'><span>{v.todayNo && fixedZeroTo4Bit(v.todayNo, 4)}</span></div>
                                <div className='right'>
                                    <div className='top'>
                                        <div className='name'>买家:&nbsp;&nbsp;{v.buyerName}</div>
                                        <div className='time'>{v.createTime && moment(v.createTime).format('MM-DD HH:mm:ss')}<span>{v.saleAmount}元</span></div>
                                    </div>
                                    <div className='line'></div>
                                    <div className='bottom'>
                                        <div>销量:&nbsp;&nbsp;{sale}{unit}</div>
                                        <div className='price'>单价:&nbsp;&nbsp;{v.unitPrice}元/{unit}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </PullToRefresh> */}
            </div>
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
        </div >
    }
}

export default BathDetail;