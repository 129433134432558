import * as actionTypes from '../constant/buyerCredit';

//初始状态
const initState = {
    buyCreditData: {},//赊欠或者对账单数据
    buyCreditRepayData: {},//还款数据
    buyCreditDetailData: {},
}
//reducer
export function buyerCredit(state = initState, action) {
    switch (action.type) {
        case actionTypes.BUYER_CREDIT_DATA:
            // let returnData = {};
            // const { code, data } = action.payload;
            // if (code === 200) {
            //     const d = data.data;
            //     returnData = { ...data};
            // }
            // console.log('actionTypes.ORDER_DATA_REPAY', state, action.payload);
            // return { ...state, repayData: { ...state.repayData, ...action.payload, data: { pageList: [...state.repayData.data.pageList, ...action.payload.data.pageList] } } };
            if (state.buyCreditData.data) {
                return { ...state, buyCreditData: { ...state.buyCreditData, ...action.payload, data: { ...{ ...state.buyCreditData.data, ...action.payload.data }, orderList: [...state.buyCreditData.data.orderList || [], ...action.payload.data.orderList || []] } } };
            } else {//第一次进来
                return { ...state, buyCreditData: { ...state.buyCreditData, ...action.payload } };
            }

        // return { ...state, buyCreditData: action.payload };
        case actionTypes.BUYER_CREDIT_REPAY_DATA:
            if (state.buyCreditRepayData.data) {
                return { ...state, buyCreditRepayData: { ...state.buyCreditRepayData, ...action.payload, data: { ...{ ...state.buyCreditRepayData.data, ...action.payload.data }, pageList: [...state.buyCreditRepayData.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, buyCreditRepayData: { ...state.buyCreditRepayData, ...action.payload } };
            }
        case actionTypes.BUYER_CREDIT_DETAIL_DATA:
            return { ...state, buyCreditDetailData: action.payload };
        default:
            return state;
    }
}