import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs';
import moment from 'moment';

import { message } from 'antd';
import { PullToRefresh } from 'antd-mobile';

import { getData } from '../../state/action/buyerCredit'
// import icon_right from './images/icon_right.png';
// import icon_mark from './images/icon_mark.png';
// import { handleTimeYearMonthDay } from "../../utils/math";
// import List from './index/list';
import { fixedZeroTo4Bit, strSplit } from "../../utils/math";
import './style.less';

@withRouter
@connect(
    state => state.buyerCredit,
    { getData }
)
class Index extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));

        this.sn = decodeURI(this.query.sn);
        console.log('sn', this.sn)

        this.state = {
            data: [],
            pageNo: 1,
            // pageSize: 10,
            refreshing: false,
            height: document.documentElement.clientHeight,
        }
    }

    async componentDidMount() {
        await this.getPageData(1);
        const hei = this.state.height - ReactDOM.findDOMNode(this.ptr).offsetTop;
        this.setState({ height: hei, })

        document.title = `采购对账单`;
        document.getElementById("root").classList.add("cxm-purchase-statement-root");
        document.body.classList.add("cxm-purchase-statement-body");
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("cxm-purchase-statement-root");
        document.body.classList.remove("cxm-purchase-statement-body");
    }

    getPageData = async (pageNo) => {
        const { totalCount = 0, orderList = [] } = this.props.buyCreditData.data || {};
        const reqPageIndex = Math.ceil(orderList.length / 10);//请求过的页数
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, data: orderList, refreshing: false });
        } else {
            if (totalCount > orderList.length || orderList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getData({
                    sn: this.sn,
                    pageNo,
                    pageSize: 10,
                });

                const { orderList = [], } = this.props.buyCreditData.data || {};
                this.setState({ pageNo, data: orderList, refreshing: false });
                // this.setState({ pageNo, data: [...this.state.data, ...orderList], refreshing: false });
                if (orderList.length === 0) {
                    message.info('没有更多的数据了！');
                }
            } else {
                const { orderList = [], } = this.props.buyCreditData.data || {};
                this.setState({ data: orderList });
                message.info('没有更多的数据了！');
            }
        }
    }

    handleCreditDetail(v) {
        this.props.history.push({
            pathname: v.orderType === 5 ? '/buyerCreditDetail5' : '/buyerCreditDetail',
            // pathname: '/buyerCreditDetail5',
            search: qs.stringify({
                sn: this.sn,
                id: v.orderNo,
                token: this.query.token,
                back: '/purchaseStatement',
            }),
            // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
        });
    }

    render() {
        console.log('My render()', this.props);
        const { buyerName, firstTime, lastTime, totalCount, totalAmount } = this.props.buyCreditData.data || {};
        const firstDate = moment(firstTime).format('YYYY-MM-DD');//最早赊欠日
        const lastDate = moment(lastTime).format('YYYY-MM-DD');//最近赊欠日
        const { data, pageNo, refreshing } = this.state;
        return <div className='cxm-purchase-statement-index'>
            <div className="container">
                <div className="header">
                    <div>
                        <div>
                            <div>{buyerName}</div>
                            <div><span>总计：</span><span>{totalAmount}元</span></div>
                        </div>
                        <div>
                            <div>{firstDate} 至 {lastDate}</div>
                            <div><span>单数：</span><span>{totalCount}单</span></div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="title" >订单信息</div>
                    <PullToRefresh
                        damping={100}
                        distanceToRefresh={window.devicePixelRatio ? window.devicePixelRatio * 25 : 25}
                        ref={el => this.ptr = el}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        indicator={{ deactivate: '上拉可以刷新' }}
                        direction={'up'}
                        refreshing={refreshing}
                        onRefresh={() => {
                            // this.getPageData(1);//测试用，第2也没数据，就请求第一页的数据
                            this.getPageData(pageNo + 1);
                        }}
                    >
                        {data.map((v, i) => {
                            //statusWatermark 状态水印 1：已付款，3：赊账
                            //orderType:5-赊欠白条
                            const { todayNo, buyerName, createTime, creditAmount, saleAmount, attrNameList = [], statusWatermark, orderType } = v;
                            return (
                                <div
                                    key={i}
                                    className={`orderinfo ${orderType === 5 ? statusWatermark === 3 ? 'orderType53' : 'orderType5' : ''}`}
                                    onClick={() => this.handleCreditDetail(v)}
                                    ref={el => this['item' + i] = el}
                                    onTouchStart={() => { this['item' + i].classList.add("opacity5") }}
                                    onTouchEnd={() => { this['item' + i].classList.remove("opacity5") }}
                                    onTouchMove={() => { this['item' + i].classList.remove("opacity5") }}
                                    onTouchCancel={() => { this['item' + i].classList.remove("opacity5") }}
                                >
                                    <div>
                                        <div><span>{orderType === 5 ? '历史赊欠' : `票号：${todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}`}</span></div>
                                        <div><span>收银时间：</span><span>{moment(createTime).format(orderType === 5 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')}</span></div>
                                        <div>
                                            {/* {statusWatermark === 1 ? <div className="status1">已结清</div> : <div className="status3">赊欠</div>} */}
                                            <div><img src={require(`../../common/images/${statusWatermark === 1 ? 'jieqing' : 'sheqian'}.png`)} alt='' /></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>{buyerName}</div>
                                        <div>¥ {saleAmount}</div>
                                    </div>
                                    {
                                        orderType === 5 ?
                                            statusWatermark === 3 ? <div>
                                                <div></div>
                                                {statusWatermark === 3 ? <div><span>下欠：</span><span>{creditAmount}元</span></div> : null}
                                            </div>
                                                : null
                                            : <div>
                                                <div>{strSplit(attrNameList.join('、'), 14)}</div>
                                                {statusWatermark === 3 ? <div><span>下欠：</span><span>{creditAmount}元</span></div> : null}
                                            </div>
                                    }


                                </div>
                            )
                        })}
                    </PullToRefresh>
                </div>

            </div>
        </div>
    }
}

export default Index;
