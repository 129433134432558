import * as actionTypes from '../constant/download';

//初始状态
const initState = {
    data: {},
    cxm: {},//菜小秘大前端
}
//reducer
export function download(state = initState, action) {
    switch (action.type) {
        case actionTypes.DOWNLOAD_DATA:
            let returnData = {};
            const { code, data } = action.payload;
            if (code === 200) {
                const d = data.data;
                const url = d && d[0] && d[0].url;
                returnData = { ...data, url };
            }
            return {
                ...state,
                data: { ...returnData },
            }
        case actionTypes.DOWNLOAD_CXM:
            return { ...state, cxm: { ...action.payload }, };
        default:
            return state;
    }
}