import * as actionTypes from '../constant/supplierSalesStatistics';
import * as req from '../request/supplierSalesStatistics';

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData(params) {
    return async dispatch => {
        const res = await req.getData(params);
        if (res) {
            dispatch({ type: actionTypes.SUPPLIER_SALES_STATISTICS_DATA, payload: res })
        }
    }
}
export function getDetailData(params) {
    return async dispatch => {
        const res = await req.getDetailData(params);
        if (res) {
            dispatch({ type: actionTypes.SUPPLIER_SALES_STATISTICS_DETAIL_DATA, payload: res, reqParams: params });
        }
    }
}

