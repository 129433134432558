import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';

import Error from './error';
import './wx';//微信分享

import App from './App';
import reducers from './reducers';
import './ie9classList';//解决ie9不支持classList属性的问题
import './axiosConfig';
import './index.css';
import 'antd-mobile/dist/antd-mobile.min.css';//加载antd-mobile的样式

import * as serviceWorker from './serviceWorker';

// const basename = '';
let basename = '#/cml/h5';
// if (window.location.href.indexOf('from=singlemessage') > 0) {//好友分享
//     basename = '?from=singlemessage#/cml/h5';
// } else if (window.location.href.indexOf('from=groupmessage') > 0) {//微信群
//     basename = '?from=groupmessage#/cml/h5';
// } else if (window.location.href.indexOf('from=timeline') > 0) {//朋友圈
//     basename = '?from=timeline#/cml/h5';
// }

//开发环境
// const store = createStore(reducers, compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f));
const store = createStore(reducers, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
//正式环境，不让浏览器redux插件查看状态
// const store = createStore(reducers, compose(applyMiddleware(thunk), f => f));

ReactDOM.render(<Provider store={store}>
    <Error>
        <ConfigProvider locale={zhCN}>
            <Router basename={basename}>
                <App></App>
            </Router>
        </ConfigProvider>
    </Error>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();