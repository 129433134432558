import React, { Component, } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs';
import DownloadCXMApp from '../../component/download-cxm-app2/index';
import { getData } from '../../state/action/purchase'
import RowData from './purchaseRecord/row';
import { setWXShare, getWXShare } from '../../utils/common';
import './style.less';

@withRouter
@connect(
    state => state.purchase,
    { getData }
)
class Index extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));

        this.state = {

        }
    }

    async componentDidMount() {
        this.props.getData({
            sn: this.query.sn,
        });
        document.title = `采购对账单`;
        document.getElementById("root").classList.add("cxm-bill-root");
        document.body.classList.add("cxm-bill-body");
        setWXShare(this.query);//微信分享用的，主要解决到了子页面再分享，微信公众号得不到值的问题
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("cxm-bill-root");
        document.body.classList.remove("cxm-bill-body");
    }

    render() {
        const objShareData = getWXShare() || {};
        console.log('objShareData' + JSON.stringify(objShareData)  + '111' + JSON.stringify(window.sessionStorage))

        // console.log('My render()', this.props);
        let { buyerName, buyerPhone = '', orderList, stallName, totalCreditAmount, totalCreditBasketNum, totalDiscountAmount, totalSaleAmount, totalOverchargeAmount } = this.props.homeData.data || {};
        // const firstDate = moment(firstTime).format('YYYY-MM-DD');//最早赊欠日
        // const lastDate = moment(lastTime).format('YYYY-MM-DD');//最近赊欠日
        // const { data, pageNo, refreshing } = this.state;       
        // buyerPhone = "15827388969";
        // totalDiscountAmount = 0;
        // totalOverchargeAmount = 0;
        // totalCreditAmount = 10;
        // totalCreditBasketNum = 0;
        let headerHtml = "";
        if (totalDiscountAmount > 0 && totalOverchargeAmount > 0) {
            headerHtml = `（含优惠${totalDiscountAmount}元，多收${totalOverchargeAmount}元）`;
        } else if (totalDiscountAmount > 0) {
            headerHtml = `（含优惠${totalDiscountAmount}元）`;
        } else if (totalOverchargeAmount > 0) {
            headerHtml = `（含多收${totalOverchargeAmount}元）`;
        }
        const rowData = (orderList && orderList.length > 0 && orderList[0]) || {}
        return <div className='cxm-bill-index'>
            <div className='container'>
                <div className="header">
                </div>
                <div className='headerBox'>
                    <div><span>{buyerName}</span><span>{buyerPhone ? `(${buyerPhone.substring(0, 3) + "****" + buyerPhone.substring(7)})` : null}</span></div>
                    <div>您在&nbsp;&nbsp;{stallName}</div>
                    <div>
                        <div>
                            <span className="label">采购：</span>
                            <span>
                                <span>{totalSaleAmount}</span>
                                <span>元</span>
                            </span>
                            <span>{headerHtml}</span>
                        </div>
                        {totalCreditAmount > 0 || totalCreditBasketNum > 0 ? <div>
                            {totalCreditAmount > 0 ? <div><span className="label">下欠：</span><span className='orange'>{totalCreditAmount}<span>元</span></span></div> : null}
                            {totalCreditBasketNum > 0 ? <div><span className="label">欠款：</span><span>{totalCreditBasketNum}<span>个</span></span></div> : null}
                        </div> : null
                        }
                    </div>
                    <div> <Link to={`/cxm-credit-repay?sn=${this.query.sn}`}>查看赊还流水</Link></div>
                </div>
                <div className='detailTop'>
                    <div>
                        <div>最近采购账单</div>
                        <div><Link to={`/cxm-purchase-record?sn=${this.query.sn}`}>全部采购记录</Link></div>
                    </div>
                    <RowData rowData={rowData} rowID={1} back="bill" />
                </div>
            </div>
            <DownloadCXMApp stallName={stallName} />
        </div>
    }
}

export default Index;
