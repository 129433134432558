import React, { Component, Fragment } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// import moment from 'moment';

import { BackTop, Affix } from 'antd';
import { Tabs } from 'antd-mobile';
import Credit from '../index/credit';
import Repay from '../index/repay';

// import DownloadCXMApp from '../../../component/download-cxm-app/index';
import DownloadCXMApp from '../../../component/download-cxm-app2/index';
import RenderTabBar from '../../../component/render-tab-bar/index';

import { shareStatistics } from '../../../state/action/order'
import { setWXShare } from '../../../utils/common';
// import icon_right from './images/icon_right.png';
// import icon_mark from './images/icon_mark.png';
// import { handleTimeYearMonthDay } from "../../utils/math";
// import List from './index/list';

//import close from '../../../common/images/close.png';

import './index.less';

@withRouter
@connect(
    state => state.order,
    { shareStatistics }
)
class Index extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        this.state = {
            visible: true,
            affixed: false,//头部是否固定，true为固定，false为不固定
        }
    }

    async componentDidMount() {
        // console.log('2', this.refHeader, this.refHeader.offsetHeight);
        const { buyerName } = this.props.statistics.data || {};
        if (!buyerName) {
            await this.props.shareStatistics({
                p: this.query.sn
            });
        }
        this.setState({ topContent: this.refHeader.offsetHeight || 80, });//-1是计算是会有小数，造成某些手机滑动时没有遮挡住
        setWXShare(this.query);//微信分享用的，主要解决到了子页面再分享，微信公众号得不到值的问题
    }

    // closeDownload = () => {
    //     this.setState({
    //         visible: false
    //     })
    // }

    // componentDidUpdate(){
    //     console.log('componentDidUpdate(){');

    //     window.scrollTo(this.query.scrollTop,0);
    // }

    // componentWillUnmount() {
    //     document.getElementById("root").classList.remove("order-common-root");
    //     document.body.classList.remove("order-common-body");
    // }

    //tabIndex返回的索引
    toDetail = (v, tabIndex) => {
        if (v.orderType === 5) {
            window._czc.push(['_trackEvent', '赊欠分享 - 白条列表点击数', '点击', '赊欠分享 - 白条列表点击数']);
        } else {
            window._czc.push(['_trackEvent', '赊欠分享 - 货品列表点击数', '点击', '赊欠分享 - 货品列表点击数']);
        }

        this.props.history.push({
            pathname: '/orderDetail',
            search: qs.stringify({
                // stallId: this.props.stallId,
                orderNo: v.orderNo,
                param: this.query.sn,
                back: this.props.back,//'/order',
                tabIndex,
                // miao,
                // scrollTop: document.documentElement.scrollTop,
            }),

            // search: qs.stringify({
            //     sn: this.query.sn,
            //     id: v.orderNo,
            //     // token: this.query.token,
            //     back: this.props.back,//'/order',
            //     tabIndex,
            // }),
            // state: { back: this.props.location.pathname, tabInitialPage: 0 },
        })

    }

    //固定采购联系电话
    handleAffix(affixed) {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        // alert(affixed + ' ' + scrollTop);
        //这个控件有个bug，进页面时不应该执行这个方法的，但是执行了，就导致2个头部出现错乱的的情况
        //加这个滚动条的高度判断出是否滚动过的
        if (scrollTop > 0) {
            this.setState({ affixed, });
        }
    }

    render() {
        // console.log('order-common index.js render()', this);
        const { buyerName, creditAmount,
            // orderCount, 
            stallName, basketNum = 0, basketFee = 0 } = this.props.statistics.data || {};
        // const firstDate = moment(firstTime).format('YYYY-MM-DD');//最早赊欠日
        // const lastDate = moment(lastTime).format('YYYY-MM-DD');//最近赊欠日
        const { topContent = 166, affixed = false } = this.state;

        return <div className='order-common-index'>
            <div className="container" style={this.state.visible ? { paddingBottom: '0.8rem' } : {}}>
                <div className="header" style={{ position: 'relative', zIndex: 2, }}>
                    <div>
                        <div>{buyerName}</div>
                        <div>您在<span>{stallName}</span>{this.props.type === 'index' ? '总' : '部分'}下欠</div>
                        {/* <div><span>{this.props.type === 'index' ? '' : '已选'} 下欠</span><span> {orderCount} 笔</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>合计</span></div> */}
                        <div>
                            <span>{creditAmount ? `¥ ${creditAmount}` : `0元`}</span>
                            {basketNum > 0 ? <Fragment><span>另欠:{basketNum}个筐</span> <span>价值:{basketFee}</span></Fragment> : null}
                        </div>
                    </div>
                </div>
                <Affix
                    offsetTop={this.state.top || 0}
                    onChange={affixed => this.handleAffix(affixed)}
                    style={{ position: 'relative', zIndex: affixed ? 3 : 1, top: -topContent }}
                // style={{ position: 'relative', zIndex: this.state.affixed ? 1 : -1, top: -topContent||-9999 }}
                >
                    <div
                        ref={el => this.refHeader = el}
                        className="header-fixed"
                    // style={{ position: this.state.affixed ? 'static' : 'absolute', top: 0, left: -99999 }}
                    // style={{ position: 'relative', zIndex: this.state.affixed ? 1 : -1 ,top: -166}}
                    >
                        <div>
                            <div>
                                <div>{buyerName}</div>
                                <div>{this.props.type === 'index' ? '总下欠' : '部分下欠'} </div>
                                <div>{creditAmount ? `¥ ${creditAmount}` : `0元`}</div>
                            </div>
                        </div>
                    </div>
                </Affix>
                <div className="content" style={{ marginTop: -topContent }}>
                    <Tabs
                        tabs={[
                            { title: "赊欠详情" },
                            { title: "还款详情" },
                        ]}
                        initialPage={(this.query.tabIndex && parseInt(this.query.tabIndex, 10)) || 0}
                        swipeable={false}
                        animated={false}
                        onChange={(tab, index) => {
                            console.log('onChange', index, tab);
                            if (index === 1) {
                                window._czc.push(['_trackEvent', '赊欠分享 - 还款详情点击数', '点击', '赊欠分享 - 还款详情点击数']);
                            }
                        }}
                        onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                        renderTabBar={(props) =>
                            <Affix
                                offsetTop={topContent || 0 + 80}
                            >
                                <RenderTabBar
                                    goToTab={(index) => true}
                                    {...props}
                                />
                            </Affix>
                        }
                    >
                        <div>
                            <Credit query={this.query} toDetail={this.toDetail} />
                        </div>
                        <div>
                            <Repay query={this.query} toDetail={this.toDetail} />
                        </div>

                    </Tabs>

                </div>

            </div>
            <DownloadCXMApp stallName={stallName} />
            <BackTop />
        </div>
    }
}

export default Index;
