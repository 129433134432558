import * as actionTypes from '../constant/store';

//初始状态
const initState = {
    homeData: {},
    likes: {},//点赞
}
//reducer
export function store(state = initState, action) {
    switch (action.type) {
        case actionTypes.STORE_DATA:
            return { ...state, homeData: action.payload.data, };
        case actionTypes.STORE_UPDATE_LIKES:
            return { ...state, likes: action.payload, };
        default:
            return state;
    }
}