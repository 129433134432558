import moment from 'moment';

//获取数量
export function getNumber(saleNumber, productNumberUnit = '件') {
    if (saleNumber) {
        return saleNumber + productNumberUnit;
    } else {
        return '-'
    }
}
//获取重量
export function getWeight(packKind, saleWeight, extend3) {
    if (packKind === 1) {
        return '-';
    } else {
        if (saleWeight) {
            const findIndex = extend3.indexOf('/');
            return saleWeight + (findIndex === -1 ? extend3 : extend3.substring(findIndex + 1));
        } else {
            return '-'
        }
    }
}

//格式化时间
export function getFormatDateTime(datetime, orderType) {
    const isOrderType5 = orderType === 5;//是否是白条
    if (moment(datetime).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        return '今日 ' + (isOrderType5 ? '' : moment(datetime).format('HH:mm'));//今日 22:00
    } else if (moment(datetime).format('YYYY-MM-DD') === moment(new Date()).add(-1, 'd').format('YYYY-MM-DD')) {
        return '昨日 ' + (isOrderType5 ? '' : moment(datetime).format('HH:mm'));//昨日 22:00
    } else {
        return moment(datetime).format(isOrderType5 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm');//2020-02-22 22:00
    }
}
//微信分享用的，再次分享需要知道title，content，url
export function setWXShare(query) {    
    var storage = window.sessionStorage;
    if (!(storage.getItem("title") && storage.getItem("desc"))) {
        storage.setItem("title", query.title);
        storage.setItem("desc", query.content);
        storage.setItem("link", window.location.href);
    }
}
//微信分享用的，再次分享需要知道title，content，url
export function getWXShare() {
    var storage = window.sessionStorage;
    return {
        title: storage.getItem("title"),
        desc: storage.getItem("desc"),
        link: storage.getItem("link"),
    }
}