// 采购记录
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs';
import { PullToRefresh, ListView } from 'antd-mobile';
import { getPurchaseRecord } from '../../../state/action/purchase';
import RowData from './row';

@withRouter
@connect(
    state => state.purchase,
    { getPurchaseRecord }
)
class PurchaseRecordData extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        })
        this.state = {
            dataSource,
            pageNo: 1,
            refreshing: true,
            isLoading: true,
            // height: document.documentElement.clientHeight,
        }
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
    }

    async componentDidMount() {
        document.title = `全部采购记录`;
        document.getElementById("root").classList.add("buyer-credit-detail-root");
        //静态数据
        // const { pageList = [] } = detailDatas.data || {};
        // this.setState({
        //     dataSource: this.state.dataSource.cloneWithRows(pageList),
        // });

        // const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
        await this.getPageData(1);
        this.setState({
            // height: hei,
            refreshing: false,
            isLoading: false,
        });

    }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }
    getPageData = async (pageNo) => {
        const type = this.props.type;
        const data = this.props[type === 1 ? 'purchaseRecord1' : 'purchaseRecord2'].data || {};
        const { totalSize = 0, pageList = [] } = data;
        const reqPageIndex = Math.ceil(pageList.length / 10);//请求过的页数        
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        } else {
            if (totalSize > pageList.length || pageList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getPurchaseRecord({
                    sn: this.query.sn,
                    type,
                    pageNo,
                    pageSize: 10,
                });

                const { pageList = [], } = this.props[type === 1 ? 'purchaseRecord1' : 'purchaseRecord2'].data || {};
                // this.setState({ pageNo, data: pageList, refreshing: false });
                this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
                // if (pageNo * 10 > pageList.length) {
                // message.info('没有更多的数据了！');//ListView插件老是提醒
                //}      
            } else {
                const { pageList = [], } = data;
                this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
                // message.info('没有更多的数据了！');
            }
        }
    }

    // toOrderDetail = (v = {}) => {
    //     this.props.history.push({
    //         pathname: v.orderType === 5 ? '/cxm-order-detail-5' : '/cxm-order-detail',
    //         search: qs.stringify({
    //             sn: this.query.sn,
    //             id: v.orderNo,
    //             // token: this.query.token,
    //             tabIndex: this.props.type,
    //             back: 'cxm-purchase-record',
    //         }),
    //         // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
    //     });
    // }
    onEndReached = async (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    };

    render() {
        // const { selectedIndex } = this.state;
        // console.log('this.state.dataSource', this.state.dataSource);

        //静态数据
        // const { pageList } = detailDatas.data;
        // const { pageList } = this.props.purchaseRecord.data || {};

        const row = (rowData, sectionID, rowID) => {
            return <RowData rowData={rowData} rowID={rowID} />            
        }
        return <ListView
            key={this.props.type}
            ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                {this.state.isLoading ? '加载中...' : ''}
            </div>)}
            renderRow={row}
            useBodyScroll={true}
            pullToRefresh={<PullToRefresh
                refreshing={this.state.refreshing}
                direction={'up'}
            />}
            onEndReached={this.onEndReached}
            pageSize={10}
            style={{ backgroundColor: '#F3F5F6' }}
        />
    }
}


export default PurchaseRecordData;
