

import request from '../../utils/request';
import { API_STALL } from '../../utils/urls';

export function getData(params) {
    return request(API_STALL, 'custom/getCustomAccountForH5', params);       
}
export function getTotalData(params) {
    return request(API_STALL, 'custom/getConsumeSumForH5', params);       
}
