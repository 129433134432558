import React, { Component } from "react";

import './index.less'
/**
 * 学习统计中的TabBar
 */
export default class RenderTabBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    _renderTabs = (item, index) => {
        const { activeTab, goToTab, } = this.props;

        return (
            <div
                key={index}
                onClick={() => goToTab(index)}
                className={`${activeTab === index ? 'activeTab' : ''}`}
            >
                <div >{item.title}</div>
                <div></div>
            </div>
        );
    }

    render() {
        /**
         * tabs 存放每一项内容的标题的数组
         */
        const { tabs } = this.props;
        return (
            <div className="render-tab-bar-component">
                {tabs.map(this._renderTabs)}
            </div>
        );
    }
}
