import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { message } from 'antd';
// import { PullToRefresh } from 'antd-mobile';

import qs from 'qs';
import { getData } from '../../state/action/supplierSalesStatistics'
import './style.less';
import { fixedZeroTo4Bit } from '../../utils/math';
// import { message } from '../../../node_modules/antd/lib/index';
// import { message } from '../../../node_modules/antd/lib/index';
@withRouter
@connect(
    state => state.supplierSalesStatistics,
    { getData }
)
class Order extends Component {
    constructor(props) {
        super(props);

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        this.sn = decodeURI(this.query.sn);

        this.state = {
            data: [],
            pageNo: 1,
            // pageSize: 10,
            refreshing: false,
            height: document.documentElement.clientHeight,
        }
    }

    async componentDidMount() {
        await this.getPageData(1);
        // const hei = this.state.height - ReactDOM.findDOMNode(this.ptr).offsetTop;
        // this.setState({ height: hei, })

        document.title = `批次销售统计`;
        document.getElementById("root").classList.add("order-root");
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("order-root");
    }

    getPageData = async (pageNo) => {
        this.setState({ refreshing: true });
        await this.props.getData({
            sn: this.sn,
            pageNo,
            pageSize: 10,
        });
        const { batchProductVOList = [] } = this.props.data.data || {};
        this.setState({ pageNo, data: [...this.state.data, ...batchProductVOList], refreshing: false });
        if (batchProductVOList.length === 0) {
            message.info('没有更多的数据了！');
        }
    }

    toDetail(batchItemId) {
        this.props.history.push({
            pathname: '/supplierSalesStatisticsDetail',
            search: qs.stringify({
                sn: this.sn,
                param: batchItemId,
                // pageNo: 1,
                // pageSize: 10,
            }),
            // state: { back: this.props.location.pathname },
            state: { back: { pathname: '/supplierSalesStatistics', search: qs.stringify({ sn: this.sn }) } },
        })
    }

    render() {
        console.log('supplier-sales-statistics index.js render() ', this.props);
        // if (this.props.data.code !== 200) {
        //     return null;
        // }
        // const { titleData, tabIndex } = this.state;
        const { carNo, goodsAmount, supplierName } = this.props.data.data || {};
        const { data } = this.state;
        // const { data, pageNo, refreshing } = this.state;
        console.log('data', data)
        return <div className='bath-index'>
            <div>
                <div className="container">
                    <div className="header">
                        <div className='headerBox'>
                            <span className="header-name">{supplierName} {carNo && fixedZeroTo4Bit(carNo, 4)}</span>
                            <span className="header-price">¥ {goodsAmount || 0}</span>
                        </div>
                    </div>
                    <div className="content">
                        <div className="bathInfo">
                            {/* <PullToRefresh
                                damping={100}
                                distanceToRefresh={window.devicePixelRatio ? window.devicePixelRatio * 25 : 25}
                                ref={el => this.ptr = el}
                                style={{
                                    height: this.state.height,
                                    overflow: 'auto',
                                }}
                                indicator={{ deactivate: '上拉可以刷新' }}
                                direction={'up'}
                                refreshing={refreshing}
                                onRefresh={() => {
                                    this.getPageData(pageNo + 1);
                                }}
                            > */}
                            {data.map((v, i) => {
                                //包装形式 1定装 3散装 2非定装 7多单位  5郊菜
                                //统计页面及分享页面： 定装显示 数量和数量单位 例如：5件
                                //非定装显示 数量和数量单位（重量和重量单位）例如：1件（20斤）
                                //散装显示 重量和重量单位 例如：20公斤
                                const { saleNumber = 0, productNumberUnit, saleWeight = 0, productWeightUnit } = v;
                                let saleHtml = null;
                                if (v.goodsPackId === 1) {
                                    saleHtml = <span>{saleNumber}{productNumberUnit}</span>;
                                } else if (v.goodsPackId === 3 || v.goodsPackId === 5) {
                                    if (saleNumber > 0) {
                                        saleHtml = <span>{saleNumber}{productNumberUnit} <span className='itemNum'>({saleWeight}{productWeightUnit})</span></span>;
                                    } else {
                                        saleHtml = <span><span className='itemNum'>{saleWeight}{productWeightUnit}</span> </span>;
                                    }
                                } else if (v.goodsPackId === 7) {
                                    saleHtml = <span>{v.saleDesc}</span>
                                } else {
                                    saleHtml = <span>{saleNumber}{productNumberUnit} <span className='itemNum'>({saleWeight}{productWeightUnit})</span></span>;
                                }
                                const isClick = (saleNumber && saleNumber > 0) || (saleWeight && saleWeight > 0) ? true : false;
                                return <div key={i} onClick={() => isClick && this.toDetail(v.batchItemId)}>
                                    <div className='bathIndex'>
                                        <div className="bathTitle">{v.attrName}</div>
                                        <div className="bathItem">
                                            <div className="item" style={{ width: '40%' }}>
                                                {saleHtml}
                                                <span className='itemCount'>总销量</span>
                                            </div>
                                            <div className="item">
                                                <span className='itemNum'>{v.goodsAmount || 0}元</span>
                                                <span className='itemCount'>货款</span>
                                            </div>
                                            <div className="item">
                                                <span className='itemNum'>{v.avgUnitPrice || 0}元/{v.goodsPackId === 1 || v.goodsPackId === 7 ? productNumberUnit : productWeightUnit}</span>
                                                <span className='itemCount'>均价</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='line'></div>
                                </div>
                            })}
                            {/* </PullToRefresh> */}
                        </div>
                    </div>
                    {/* <div className="footer">
                        123footer
                    </div> */}

                </div>
            </div>
        </div>
    }
}

export default Order;
