import React from 'react';
import './headerHtml.less';
export default function (props) {
    console.log('props', props);

    let { orderSaleAmount, orderDiscountAmount, orderOverchargeAmount, creditAmount, paymentAmount } = (props.data || {});
    // originOrderSaleAmount = 1;
    // orderDiscountAmount = 2;
    // orderOverchargeAmount = 3;
    // creditAmount = 4;
    // paymentAmount = 5;

    let headerHtml = "";
    if (orderDiscountAmount > 0 && orderOverchargeAmount > 0) {
        headerHtml = `（含优惠${orderDiscountAmount}元，多收${orderOverchargeAmount}元）`;
    } else if (orderDiscountAmount > 0) {
        headerHtml = `（含优惠${orderDiscountAmount}元）`;
    } else if (orderOverchargeAmount > 0) {
        headerHtml = `（含多收${orderOverchargeAmount}元）`;
    }
    return (
        <div className="order-detail-header-html">
            <div>
                <span className="label">订单金额：</span>
                <span>
                    <span>{orderSaleAmount}</span>
                    <span>元</span>
                </span>
                <span>{headerHtml}</span>
            </div>
            {paymentAmount > 0 || creditAmount > 0 ? <div>
                {paymentAmount > 0 ? <div><span className="label">已付：</span><span >{paymentAmount}<span>元</span></span></div> : null}
                {creditAmount > 0 ? <div><span className="label">下欠：</span><span className='orange'>{creditAmount}<span>元</span></span></div> : null}
            </div> : null
            }
        </div>
    )

}