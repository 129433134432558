import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
// import qs from 'qs';
import { connect } from 'react-redux';
// import { Icon } from 'antd';
import { PullToRefresh, ListView } from 'antd-mobile';
import moment from 'moment';

// import icon_right from '../images/icon_right.png';
import { fixedZeroTo4Bit, strSplit } from "../../../utils/math";
import { getRepayData } from '../../../state/action/order'
import './repay.less';

@withRouter
@connect(
    state => state.order,
    { getRepayData }
)
class Repay extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            // getRowData: (dataBlob, sectionID, rowID) => dataBlob[rowID],
            rowHasChanged: (row1, row2) => {
                // return row1 !== row2;
                return true;
            }
        });
        this.state = {
            pageNo: 1,
            dataSource,
            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight,

        }
    }

    async componentDidMount() {
        // console.log('repay.js  async componentDidMount() {');
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).parentNode.offsetTop;
        await this.getPageData(1);
        this.setState({
            height: hei,
            refreshing: false,
            isLoading: false,
        });
    }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataSource !== this.props.dataSource) {
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
            });
        }
    }
    // onRefresh = async () => {
    //     console.log('onRefresh = async () => {');
    //     this.setState({ refreshing: true, isLoading: true });
    //     await this.getPageData(this.state.pageNo + 1);
    //     this.setState({ refreshing: false, isLoading: false, });
    // };

    onEndReached = async (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    };

    getPageData = async (pageNo) => {
        const { totalSize = 0, pageList = [] } = this.props.repayData.data || {};
        const reqPageIndex = Math.ceil(pageList.length / 10);//请求过的页数
        if (pageNo <= reqPageIndex) {
            this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        } else {
            if (totalSize > pageList.length || pageList.length === 0) {
                this.setState({ refreshing: true });
                await this.props.getRepayData({
                    p: this.props.query.sn,
                    pageNo,
                    pageSize: 10,
                });

                const { pageList = [], } = this.props.repayData.data || {};
                this.setState({ pageNo, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
                // if (pageNo * 10 > pageList.length) {
                // message.info('没有更多的数据了！');//ListView插件老是提醒
                //}      
            } else {
                const { pageList = [], } = this.props.repayData.data || {};
                this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
                // message.info('没有更多的数据了！');
            }
        }
    }

    down(e, i) {
        e.stopPropagation();

        // const offsetTop = ReactDOM.findDOMNode(this.lv).scrollTop;
        // console.log('down(e, i) {', this.state, i, offsetTop);
        // this.setState({ ['down' + i]: !this.state['down' + i], refreshing: !this.state.refreshing, ListViewKey: i, offsetTop });
        const { pageList = [], } = this.props.repayData.data || {};
        this.setState({ ['down' + i]: !this.state['down' + i], dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            // console.log("const row = (rowData, sectionID, rowID) => {", rowData, sectionID, rowID);
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);
            const down = this.state['down' + i];
            //statusWatermark 状态水印 1：已付款，3：赊账            
            const { repayTime, repayAmount, orderList = [] } = v;
            //如果下一个信息的还款时间和上一个信息的还款时间是同一天的，那么不需要显示时间和还款金额
            //  let isSameDay = false;
            let isSameYear = true;
            const { pageList = [] } = this.props.repayData.data;
            if (i > 0) {
                const prevRepayTime = pageList[i - 1].repayTime;
                // console.log('票号：' + v.todayNo, moment(repayTime).format('YYYY-MM-DD'), moment(prevRepayTime).format('YYYY-MM-DD'))
                if (moment(repayTime).format('YYYY-MM-DD') === moment(prevRepayTime).format('YYYY-MM-DD')) {
                    //  isSameDay = true;
                } else if (moment(repayTime).format('YYYY') !== moment(prevRepayTime).format('YYYY')) {
                    isSameYear = false;
                }
            }
            return (
                <div key={i} className="repay-item" >

                    <div onClick={(e) => this.down(e, i)}>
                        <div>
                            <div>
                                <span>还款时间：</span>
                                {/* <span>{moment(repayTime).format('MM-DD HH:mm')}</span> */}
                                <span>{moment(repayTime).format(isSameYear ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm')}</span>
                            </div>

                            <div>
                                <span>还款金额：</span>
                                <span>¥ {repayAmount}</span>
                            </div>

                        </div>
                        <div >
                            {/* <Icon type={down ? 'up' : 'down'} /> */}
                            <img src={require(`../../../common/images/icon-${down ? 'up' : 'down'}.png`)} alt='' />
                        </div>
                    </div>

                    <div style={{ display: down ? 'block' : 'none' }}>
                        {orderList.map((y, j) => {
                            //orderType:5-赊欠白条
                            const { repayAmount, orderCreateTime, todayNo, attrNameList = [], discountAmount, overchargeAmount, orderType, creditAmount, remark = '', settled } = y;
                            // console.log('orderList.map((y, j) => {',++j);

                            return <div
                                key={j}
                                className={`orderinfo ${orderType === 5 ? 'orderType5' : ''}`}
                                onClick={() => this.props.toDetail(y, 1)}
                                ref={el => this['item' + i + j] = el}
                                onTouchStart={() => { this['item' + i + j].classList.add("opacity5") }}
                                onTouchEnd={() => { this['item' + i + j].classList.remove("opacity5") }}
                                onTouchMove={() => { this['item' + i + j].classList.remove("opacity5") }}
                                onTouchCancel={() => { this['item' + i + j].classList.remove("opacity5") }}
                            >
                                <div>
                                    <div>
                                        <div><span>{`票号：${todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}`}</span></div>
                                        <div><span>赊欠时间：</span><span>{moment(orderCreateTime).format(orderType === 5 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')}</span></div>
                                        <div>
                                            {/* {status === 1 ? <div className="status1">已结清</div> : <div className="status3">赊欠</div>} */}
                                            <div><img src={require(`../../../common/images/${settled ? 'jieqing' : 'sheqian'}.png`)} alt='' /></div>
                                        </div>
                                    </div>

                                    <div className="attrNameList">
                                        <div>{orderType === 5 ? remark && `备注：${strSplit(remark, 14 - 3)}` : strSplit(attrNameList.join('、'), 14)}</div>
                                        {/* {status === 3 ? <div><span>下欠：</span><span>{creditAmount}元</span></div> : null} */}
                                    </div>

                                    <div className="amount">
                                        {/* <div>{buyerName}</div> */}
                                        <div>
                                            <span>还款 <span>¥ {repayAmount}</span></span>
                                            {discountAmount ? <span> (优惠{discountAmount})</span> : null}
                                            {overchargeAmount ? <span> (多收{overchargeAmount})</span> : null}
                                        </div>
                                        {!settled ? <div><span>下欠</span><span>¥ {creditAmount}元</span></div> : null}
                                    </div>


                                </div>
                            </div>;
                        })}
                    </div>
                    {/* : null} */}

                </div>
            )

        };

        return <div className='order-index-repay'>
            <ListView
                key={1}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                // renderHeader={() => <span>Pull to refresh</span>}
                renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                    {this.state.isLoading ? '加载中...' : ''}
                    {/* {this.state.isLoading ? '加载中...' : '加载完成'} */}
                </div>)}
                renderRow={row}
                // renderSeparator={separator}
                useBodyScroll={true}
                // onScroll={() => { console.log('scroll'); }}
                style={{
                    // height: this.state.height,
                    // border: '1px solid #ddd',
                    // margin: '5px 0',
                }}
                pullToRefresh={<PullToRefresh
                    refreshing={this.state.refreshing}
                    // onRefresh={this.onRefresh}
                    direction={'up'}
                />}
                onEndReached={this.onEndReached}
                pageSize={10}
                className="am-list"
            />
        </div >
    }
}

export default Repay;
