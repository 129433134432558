import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// import { getCreditDetailData } from '../../state/action/buyerCredit';
import { Badge, Table } from 'antd';
import { ORDER_STATUS } from '../../utils/status';
// import { URL_ORDER_TRACK } from '../../utils/urls';

import './orderDetail.less';
import moment from 'moment';
import { fixedZeroTo4Bit, strSplit } from "../../utils/math";
// import Back from '../../component/back/index';
import backImgSrc from '../../common/images/icon_left.png';
//const { Column } = Table;
import { getDetailData, } from '../../state/action/purchase';
import HeaderHtml from './orderDetail/headerHtml';

@withRouter
@connect(
    state => state.purchase,
    { getDetailData }
)
class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        // this.sn = decodeURI(this.query.sn);
    }

    async componentDidMount() {
        // const { pathname } = this.props.location.state.back;
        document.title = "订单详情";//pathname === '/purchaseStatement' ? `采购对账单详情` : `赊还款详情`;
        document.getElementById("root").classList.add("cxm-bill-detail-root");
        // this.orderId = this.props.location.state; //订单号
        const { id } = this.query;
        await this.props.getDetailData({
            sn: id,
        });
        // 监听message事件    
        // window.addEventListener("message", this.receiveMessage, false);
    }

    getNumber(packKind, saleNumber, productNumberUnit = '件') {
        if (saleNumber) {
            return saleNumber + productNumberUnit;
        } else {
            return '-'
        }
    }
    getWeight(packKind, saleWeight, extend3) {
        if (packKind === 1) {
            return '-';
        } else {
            if (saleWeight) {
                const findIndex = extend3.indexOf('/');
                return saleWeight + (findIndex === -1 ? extend3 : extend3.substring(findIndex + 1));
            } else {
                return '-'
            }
        }
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("cxm-bill-detail-root");
        // window.removeEventListener("message", this.receiveMessage);
    }

    // receiveMessage = (event) => {
    //     console.log('receiveMessage', event);
    //     if (event != null && event.data && event.data instanceof Object && event.data.type === "订单轨迹") {
    //         // console.log('我是react,我接受到了来自iframe的数据：', event.data);
    //         document.getElementById('iframeOrderTrack').height = event.data.currDivHeight + 50;
    //     }
    // };
    // imgRotateChange = e => {
    //     if (this.state.imgRotate === 'rotate(90deg)') {
    //         this.setState({ imgRotate: 'rotate(270deg)', freshState: true })
    //     } else {
    //         this.setState({ imgRotate: 'rotate(90deg)', freshState: false })
    //     }
    // }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    toBack = () => {
        this.props.history.push({
            pathname: this.query.back ? this.query.back : 'cxm-credit-repay',//this.query.back有2个值 'purchaseStatement'， '/buyerCredit'
            search: qs.stringify({ sn: this.query.sn, tabIndex: this.query.tabIndex })
        })
    }

    render() {
        //console.log('detail.js Render', this.props.location)
        const { statusWatermark, createTime, todayNo,
            productVOList = [],
            remark = "",//"没有备注就不显示，此为档主端可能有备注的情况，才显示此备注信息。" orderCreateTime
            orderType = 1,
            //,orderSaleAmount
            //, orderTradeFee
            chargeOrderFree = 0, //三轮车费
            orderFeeList = [],//押框明细
            orderDepositAmount = 0,//框子总押金
            // orderStatus,
            // payStatus,
        } = this.props.detail.data || {};
        const columns = [
            {
                title: '货名',
                dataIndex: 'goodsAttrName',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{index + 1}、{text}</span>
                },
            },
            {
                title: '筐子数',
                dataIndex: 'basketCount',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}</span>
                },
            },
            {
                title: '单价',
                dataIndex: 'basketUnitPrice',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}元/个</span>
                },
            },
            {
                title: '押金',
                dataIndex: 'depositAmount',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}元</span>
                },
            }
        ];
        //console.log('orderDiscountAmount', productVOList)
        // let access_token = '', stall_id = '';
        // if (this.query.token) {
        //     const token = BASE64_decode(this.query.token).split('_');
        //     if (token && token.length === 2) {
        //         stall_id = token[0];
        //         access_token = token[1];
        //     }
        // }
        // const orderUrl = URL_ORDER_TRACK.replace('[ORDERNO]', orderNo).replace('[STATUS]', orderStatus).replace('[ACCESSTOKEN]', access_token).replace('[STALLID]', stall_id);
        //const pathname = this.props.location && this.props.location.state && this.props.location.back && this.props.location.state.back.pathname;

        return <div className='cxm-bill-detail'>
            <div className='container'>
                <div className="header">
                </div>
                <div className='headerBox'>
                    <div className="headerNum"><span>票号：{todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}</span></div>
                    {/**<div className="creatTime">开单时间: {moment(orderCreateTime).format('YYYY-MM-DD HH:mm')}</div>*/}
                    {/**<div className="signTime">{pathname === '/purchaseStatement' ? `收银时间` : `赊欠时间`}:  {moment(createTime).format('YYYY-MM-DD HH:mm')}</div> */}
                    <div className="signTime"><span className="label">{statusWatermark === 1 ? `收银时间：` : `赊欠时间：`} {moment(createTime).format('YYYY-MM-DD HH:mm')}</span></div>                    
                    <div className='headerStatu'>
                        <div className={statusWatermark === 1 ? 'status1' : 'status3'}>{ORDER_STATUS.get(statusWatermark)}</div>
                    </div>
                    <HeaderHtml data={this.props.detail.data} />
                </div>

                <div className='detailContent'>
                    <div className='detail-goods-title'>
                        <span>货品明细</span>
                    </div>
                    {
                        productVOList.map((v, i) => {
                            const { attrName, originOrderSaleAmount, packKind, saleNumber, saleWeight, extend3, productNumberUnit, //productWeightUnit,
                                unitPrice, goodsAmount, tradeFee,
                                // tareWeight, grossWeight, iceWeight, billFee, packFee
                            } = v;
                            const goods = [
                                { key: '数量', value: this.getNumber(packKind, saleNumber, productNumberUnit) },
                                { key: '重量', value: this.getWeight(packKind, saleWeight, extend3) },
                                { key: '单价', value: `${unitPrice}${extend3}` },
                                { key: '货款', value: `${goodsAmount}元` },
                                { key: '附加费', value: `${tradeFee}元`, label: tradeFee === 0 ? true : false }
                            ];
                            //tradeFee && goods.push({ key: '附加费', value: `${tradeFee}元` });
                            // tareWeight && goods.push({ key: '皮重', value: `${tareWeight}${productWeightUnit}` });
                            // grossWeight && goods.push({ key: '毛重', value: `${grossWeight}${productWeightUnit}` });
                            // iceWeight && goods.push({ key: '去皮', value: `${iceWeight}${extend3 && extend3.substring(extend3.indexOf('/') + 1)}` });
                            // billFee && goods.push({ key: '开票', value: `${billFee}元` });
                            // packFee && goods.push({ key: '灌包', value: `${packFee}元` });

                            return <div key={i} className={`detail-goods-content ${i === productVOList.length - 1 && 'detail-goods-content-last'}`}>
                                <div>
                                    <div>{i + 1}、{attrName && strSplit(attrName, 14)}
                                        <Badge className={packKind === 1 ? 'packKind1' : (packKind === 2 ? 'packKind2' : 'packKind3')}
                                            count={packKind === 1 ? '定装' : (packKind === 2 ? '非定装' : '散装')} />
                                    </div>
                                    <div>小计：<span>{originOrderSaleAmount}元</span></div>
                                </div>
                                <div>
                                    {goods.map((v, i) => {
                                        //找出最后几个不需要加border-bottom的 4为总个数，1位最后一个去掉border-bottom
                                        //4,1   5,2   6,3   7,1   8,2   9,3  10,1
                                        const removeCount = (goods.length) % 3 === 0 ? 3 : goods.length % 3;
                                        if (v.label) {
                                            return <div key={i} className={`${(i + 1) % 3 === 0 ? '' : 'borderRight'} ${i >= goods.length - removeCount ? '' : 'borderBottom'}`}></div>
                                        } else {
                                            return <div key={i} className={`${(i + 1) % 3 === 0 ? '' : 'borderRight'} ${i >= goods.length - removeCount ? '' : 'borderBottom'}`}>{v.key}：{v.value}</div>
                                        }
                                    })}

                                </div>
                            </div>
                        })
                    }

                    <div className="detail-goods-3">
                        {chargeOrderFree > 0 && <div>三轮车费：{chargeOrderFree}元</div>}
                    </div>

                </div>

                {orderFeeList.length > 0 &&
                    <div className='detail-frame'>
                        <div>
                            <div>押筐明细</div>
                            <div>框押金：<span>{orderDepositAmount}元</span></div>
                        </div>
                        <Table rowKey={(v, i) => i} columns={columns} dataSource={orderFeeList} bordered={true} pagination={false} />
                    </div>
                }
                {
                    remark && orderType === 5 ?
                        <div className='detailRemark'>
                            <div className='detailItem'>
                                <div>备注</div>
                                <div>{remark}</div>
                            </div>
                        </div>
                        : null
                }

                {/* <div className='detailButtom'>
                    <div className='detailButtom-top'>
                        <span>订单的一生</span>
                        <span onClick={this.toggleCollapsed}>
                            <Icon type={this.state.collapsed ? 'up' : 'down'} />
                        </span>
                    </div>
                    {this.state.collapsed ?
                        <div className='detailButtom-bom'>
                            {orderNo &&
                                <iframe
                                    id="iframeOrderTrack"
                                    key={orderNo}
                                    // src='http://192.168.5.111:8001/#/batch/Profile?env=dev&orderNo=379930790487781376&status=10&accessToken=d0c8c7be98a2f57b5ba973f2706dcbd7&appType=web&stallId=10005&code=react'
                                    src={orderUrl}
                                    // height='800'
                                    width='100%'
                                    frameBorder='0'
                                    title='订单轨迹'
                                >订单轨迹</iframe>
                            }
                        </div>
                        : null
                    }

                </div> */}

                {/* <div className="detail-footer"></div>
                <div className="footer">
                    <div className="count">应收：<span>{orderSaleAmount}</span></div>
                    <div className="countPrice">合计：<span>{originOrderSaleAmount}</span></div>
                </div> */}
            </div>
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
            {/* <Back {...this.props.location.state}/> */}
        </div>
    }
}

export default OrderDetail;