

import request from '../../utils/request';
import { API_CXM_DOWNLOAD } from '../../utils/urls';

export function getData() {
    return request("https://stall.mp.caixm.cn/", 'caixmmp-b/app/selectAppversionForList?marketType=1&os=purchase');
}

//菜小秘大前端
export function getCxmData() {
    return request(API_CXM_DOWNLOAD, 'app/selectAppversionForList', { marketType: '1', os: 'INTEGRATIONAPP' });
}

//app下载链接-1:官方 2:android应用宝 3:ios appStore
// export function getDownloadUrl() {
//     return request(API_CXM_IOSDOWNLOAD, 'cxmSystem/download/url');
// }