import * as actionTypes from '../constant/download';
import * as req from '../request/download';

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData() {
    return async dispatch => {
        const res = await req.getData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_DATA, payload: res })
        }
    }
}
/**
 * 获得pos机的数据
 */
export function getCxmData() {
    return async dispatch => {
        const res = await req.getCxmData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_CXM, payload: res })
        }
    }
}

//app下载链接-1:官方 2:android应用宝 3:ios appStore
// export function getDownloadUrl() {
//     return async dispatch => {
//         const res = await req.getDownloadUrl();
//         if (res) {
//             return res
//         }
//     }
// }