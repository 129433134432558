// 采购记录
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs';
// import moment from 'moment';
import { Affix, Radio } from 'antd';

import { getPurchaseRecord } from '../../state/action/purchase';
import backImgSrc from '../../common/images/icon_left.png';
import PurchaseRecordData from './purchaseRecord/data';
import './purchaseRecord.less';

@withRouter
@connect(
    state => state.purchase,
    { getPurchaseRecord }
)
class PurchaseRecord extends Component {
    constructor(props) {
        super(props);
        this.segmentedLabel = ['全部订单', '赊欠订单']
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        // console.log('PurchaseRecord', this.query);

        this.state = {
            selectedIndex: parseInt(this.query.tabIndex, 10) || 1,
        }
    }

    async componentDidMount() {
        document.title = `全部采购记录`;
        document.getElementById("root").classList.add("buyer-credit-detail-root");
    }

    handleModeChange = async (e) => {
        const selectIndex = e.target.value;
        this.setState({ selectedIndex: selectIndex });
    }
    toBack = () => {
        this.props.history.push({
            pathname: 'bill',//this.query.back有2个值 'purchaseStatement'， '/buyerCredit'
            search: qs.stringify({ sn: this.query.sn })
        })
    }

    render() {
        const { selectedIndex } = this.state;
        return <div className='cxm-bill-purchase-record'>
            <Affix offsetTop={0}>
                <div className='header'>
                    <Radio.Group onChange={this.handleModeChange} defaultValue={selectedIndex} value={selectedIndex} buttonStyle="solid">
                        <Radio.Button value={1}>全部订单</Radio.Button>
                        <Radio.Button value={2}>赊欠订单</Radio.Button>
                    </Radio.Group>
                </div>
            </Affix>
            <div style={{ display: selectedIndex === 1 ? 'block' : 'none' }}><PurchaseRecordData type={1} /></div>
            <div style={{ display: selectedIndex === 2 ? 'block' : 'none' }}><PurchaseRecordData type={2} /></div>

            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
        </div>
    }
}


export default PurchaseRecord;
