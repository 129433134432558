// 赊还流水
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs';
import moment from 'moment';

import { Button, Affix } from 'antd';
import { ListView } from 'antd-mobile';//PullToRefresh

// import { getData } from '../../state/action/buyerCredit'
// import icon_right from './images/icon_right.png';
// import icon_mark from './images/icon_mark.png';
// import { handleTimeYearMonthDay } from "../../utils/math";
// import List from './index/list';
// import { fixedZeroTo4Bit, strSplit } from "../../utils/math";
import './creditRepay.less';
import backImgSrc from '../../common/images/icon_left.png';
import loadingSrc from '../../common/images/isLoading.gif';
import { getCreditRepayCount, getCreditRepay, saveClickData, clearCreditRepay } from '../../state/action/purchase';
import { getFormatDateTime } from "../../utils/common";
@withRouter
@connect(
    state => state.purchase,
    { getCreditRepayCount, getCreditRepay, saveClickData, clearCreditRepay }
)
class Index extends Component {
    constructor(props) {
        super(props);       
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => {
                return true;
            }
        });
        this.state = {
            pageNo: 0,
            dataSource,
            refreshing: true,
            isLoading: true,
            height: document.documentElement.clientHeight,
            isMore: false,
        }
        this.props.clearCreditRepay();//清楚赊还流水，不然进入详细页面再返回，会累加所有的数据，造成数据冗余的bug     
    }

    async componentDidMount() {
        const { stallName = '', } = this.props.homeData.data || {};
        document.title = `${stallName}赊还流水`;
        await this.props.getCreditRepayCount({
            sn: this.query.sn,
        })
        // const { pageList = [] } = this.props.creditRepayCount.data || {};
        // if (pageList.length > 0) {
        await this.getPageData(0);
        // }

        // const hei = this.state.height - ReactDOM.findDOMNode(this.lv).parentNode.offsetTop;
        // await this.getPageData(1);
        this.setState({
            // height: hei,
            refreshing: false,
            isLoading: false,
        });

        
    }
    // onEndReached = async (event) => {
    //     debugger
    //     // load new data
    //     // hasMore: from backend data, indicates whether it is the last page, here is false
    //     if (this.state.isLoading && !this.state.hasMore) {
    //         return;
    //     }
    //     // console.log('reach end', event);
    //     this.setState({ isLoading: true });
    //     await this.getPageData(++this.state.pageNo);
    //     this.setState({ isLoading: false, });
    // };
    getPageData = async (pageNo) => {
        // console.log('pageNo', pageNo);

        this.setState({ refreshing: true });
        const pageListCount = (this.props.creditRepayCount.data || {}).pageList;
        if (pageListCount.length > 0 && pageNo < pageListCount.length) {
            //下面的逻辑是一次要读取10条或以上的数据，主要是算开始时间
            let count = 0;
            let i10 = 0;//"2019-04"
            if (pageNo === 0) {
                for (let i = 0; i < pageListCount.length; i++) {
                    const v = pageListCount[i];
                    count += v.count;
                    i10 = i;
                    if (count >= 10) {
                        break;
                    }
                }
            } else {
                i10 = pageNo;
            }
            const startMonth = pageListCount[pageNo === 0 ? i10 : pageNo].month;//"2019-04"
            const endMonth = pageListCount[pageNo].month
            const arrMonth = endMonth.split('-');
            await this.props.getCreditRepay({
                sn: this.query.sn,
                startDate: startMonth + "-01",
                endDate: moment(new Date(arrMonth[0], arrMonth[1], 0)).format('YYYY-MM-DD'),// "2021-04-01"
            });

            const { pageList = [], } = this.props.creditRepay.data || {};
            // this.setState({ pageNo, data: pageList, refreshing: false });
            const isMore = i10 + 1 < pageListCount.length;
            this.setState({ pageNo: i10, isMore, dataSource: this.state.dataSource.cloneWithRows(pageList), refreshing: false });
        } else {
            const { pageList = [], } = this.props.creditRepay.data || {};
            this.setState({ dataSource: this.state.dataSource.cloneWithRows(pageList) });
        }

    }

    // getPageData = async (pageNo) => {
    //     //从后台获取数据
    //     const { pageList = [], totalSize } = dataList.data || {};
    //     const isMore = totalSize - (10 * pageNo) >= 1; //判断是否还有更多数据
    //     //需要把不需要刷新的数据加起来
    //     this.setState({
    //         pageNo,
    //         dataSource: this.state.dataSource.cloneWithRows(pageList),
    //         //isLoading: false,
    //         isMore,
    //         //refreshing: false 
    //     });
    // }

    toDetail = (v) => {
        if (v.type === 1) { //赊欠
            this.toOrderDetail(v);
        } else if (v.type === 2) { //还款
            this.toRepayRecord(v);
        }
    }

    toRepayRecord(v) {
        this.props.saveClickData(v);//保存点击了哪一行的数据，到其他页面去用
        this.props.history.push({
            pathname: 'cxm-repay-record',
            search: qs.stringify({
                sn: this.query.sn,
                id: v.uuid,
                // amount: v.repayAmount || 2500,
                // time: v.createTime || 1573806283953,
                back: '/cxm-credit-repay',
            }),
            // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
        });
    }
    toOrderDetail(v) {
        this.props.history.push({
            pathname: 'cxm-order-detail',
            search: qs.stringify({
                sn: this.query.sn,
                id: v.orderNo,
                // token: this.query.token,
                back: 'cxm-credit-repay',
            }),
            // state: { back: { pathname: '/purchaseStatement', search: qs.stringify({ sn: this.sn }) } },
        });
    }
    toBack = () => {
        this.props.history.push({
            pathname: 'bill',//this.query.back有2个值 'purchaseStatement'， '/buyerCredit'
            search: qs.stringify({ sn: this.query.sn, })
        })
    }

    loadMore = async () => { //点击更多
        this.setState({ isLoading: true });
        await this.getPageData(++this.state.pageNo);
        this.setState({ isLoading: false, });
    }

    render() {
        const { totalCreditAmount = 0, } = this.props.homeData.data || {};
        const row = (rowData, sectionID, rowID) => {
            if (rowData == null) return "null";
            const v = rowData;
            const i = parseInt(rowID, 10);
            //type: 1表示赊欠流水 2表示还款流水
            const {
                type, orderSaleAmount = 0, paymentAmount = 0, creditAmount = 0, repayAmount = 0, createTime, //orderNo, uuid
                orderType,
            } = v;
            // orderSaleAmount=123432.04;
            // paymentAmount=12341100;
            // creditAmount=repayAmount=20023113;
            return <div key={i}
                id={`creditRepay${i}`}
                ref={el => this['item' + i] = el}
                onClick={() => this.toDetail(v)}
            >
                <div>
                    <div className="content">
                        <div className="content-Column1">{type === 1 ? '赊欠' : '还款'}</div>
                        <div className="content-Column2">{type === 1 && `${orderSaleAmount}元`}</div>
                        <div className="content-Column3">{type === 1 && `${paymentAmount}元`}</div>
                        <div className="content-Column4">{type === 1 ? `+${creditAmount}元` : `-${repayAmount}元`}</div>
                    </div>
                    <div className="content1">
                        <div className="content-date">{getFormatDateTime(createTime, orderType)}</div>
                    </div>
                </div>
            </div>

        }
        return <div className='cxm-bill-credit-repay'>
            <div className='container'>
                <Affix offsetTop={0}>
                    <div className="header">
                        <div className='headerBox'>
                            <div className="headerNum">剩余赊欠：<span>{totalCreditAmount}元</span></div>
                        </div>
                    </div>
                    <div className="white-space" />
                    <div className="title">
                        {/* <div className="title-Column1"></div> */}
                        <div className="title-Column1">订单金额</div>
                        <div className="title-Column2">实付</div>
                        <div className="title-Column3">赊欠变动</div>
                    </div>
                </Affix>
                <ListView
                    key={1}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{ paddingBottom: 30, textAlign: 'center' }}>
                        {/* {this.state.isLoading ? '加载中...' : ''} */}
                        {this.state.isLoading ? <div>
                            <div><img src={loadingSrc} alt="" className="img-class" /></div>
                            正在加载...
                        </div> : (this.state.isMore ?
                                <Button onClick={() => this.loadMore()} shape="round" className="btn-round">加载更多</Button> : '')
                        }
                    </div>)}
                    renderRow={row}
                    useBodyScroll={true}
                    // pullToRefresh={<PullToRefresh
                    //     refreshing={this.state.refreshing}
                    //     direction={'up'}
                    // />}
                    onEndReached={this.onEndReached}
                    pageSize={10}
                />
            </div>
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
        </div>
    }
}

export default Index;
