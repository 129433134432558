import React, { Component, Fragment } from 'react';
// import ReactDOM from 'react-dom';
// import { connect } from 'react-redux'
// import { withRouter } from 'react-router-dom'

// import { getData } from '../../state/action/download';

// import { OPENINSTALL_APPKEY } from '../../utils/urls';
import qs from 'qs';

import './style.less';

// import imgHeader from '../../common/images/cxm-share/header.png';
import imgArrow from '../../common/images/cxm-share/arrow.png';
import imgDownload from '../../common/images/cxm-share/download.png';
import imgheadpic from '../../common/images/cxm-share/headpic.png';
// import imgsay from '../../common/images/cxm-share/say.png';

// @withRouter
// @connect(
//     state => state.download,
//     { getData }
// )
class Index extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        this.state = {
            pic: '',
        }
    }

    async componentDidMount() {
        // this.props.getData();        
        const clientHeight = document.documentElement.clientHeight;//网页可见区域的高
        const clientWidth = document.documentElement.clientWidth;//网页可见区域的宽
        const imgHeight = clientWidth / (750 / 1019);//算出等比缩放的高度，750/1019是图片的宽高
        const el = document.getElementById('cxm_share_download_fixed');//底部的元素
        const showPicHeight = clientHeight - el.offsetHeight;//算出实际留给图片显示的高度
        // var oStyle = el.currentStyle ? el.currentStyle : window.getComputedStyle(el, null);
        // var height = parseFloat(oStyle.height);
        // setInterval(() => {
        //     this.setState({
        //         height: el.offsetHeight
        //     })
        // }, 10);
        // console.log(clientHeight, el.offsetHeight);
        // console.log(imgHeight, showPicHeight, imgHeight / showPicHeight, 1019 / 738);
        let pic = 'header';
        if (imgHeight / showPicHeight > 1019 / 738) {//求等比缩放
            pic = 'header-small';
        }
        this.setState({ pic });

        document.title = `分享菜小秘`;
        document.body.classList.add("cxm-share-body");
    }
    componentWillUnmount() {
        document.body.classList.remove("cxm-share-body");
    }

    render() {
        const { stallname = "" } = this.query;
        const { pic } = this.state;
        return <div className='cxm-share' >
            <div className="pic">
                {pic && <img src={require(`../../common/images/cxm-share/${pic}.png`)} alt='' />}
            </div>
            <div className="download-fixed" id="cxm_share_download_fixed">
                <div className="content">
                    <div className="head-pic">
                        <img src={imgheadpic} alt='' />
                    </div>
                    <div className="stall">
                        {/* {stallname ? <div className="stall-name">{stallname}</div> : null} */}
                        <div className="say" >
                            {
                                stallname
                                    ? <Fragment>我是您的好友，"<span className="name">{stallname}</span>"的老板<br />平时这么忙，还不快和我一起<br />用<span className="caixm">菜小秘</span>帮你省心！！！</Fragment>
                                    : <Fragment>平时这么忙，还不快和全国<span className="name">5000名</span>农批老板一起，用<span className="caixm">菜小秘</span>帮你省心！！！</Fragment>
                            }

                        </div>
                        <div className="arrow"><img src={imgArrow} alt='' /></div>
                    </div>
                </div>
                {/* <img src={imgsay} alt='' /> */}
                <div className="download" style={{ cursor: 'pointer' }}><img src={imgDownload} alt='' id="cxmDownloadButton" style={{ cursor: 'pointer' }} /></div>
            </div>
        </div >
    }
}

export default Index;