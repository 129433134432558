// import cookies from 'browser-cookies'
import * as actionTypes from '../constant/order';
import * as req from '../request/order';

//这是老的页面的接口
/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function searchCreditOrdersByCustomerName(params) {
    return async dispatch => {
        const res = await req.searchCreditOrdersByCustomerName(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA, payload: res })
        }
    }
}
//还款分享页面
export function searchRepayOrdersByCustomerName(params) {
    return async dispatch => {
        const res = await req.searchRepayOrdersByCustomerName(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA_REPAY, payload: res })
        }
    }
}
//这是老的页面的接口 end

//下面是新接口
//分享信息统计
export function shareStatistics(params) {
    return async dispatch => {
        const res = await req.shareStatistics(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA_2_STATISTICS, payload: res })
        }
    }
}
//赊欠订单
export function getCreditData(params) {
    return async dispatch => {
        const res = await req.getCreditData(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA_2_CREDIT, payload: res })
        }
    }
}
//获得还款的数据
export function getRepayData(params) {
    return async dispatch => {
        const res = await req.getRepayData(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA_2_REPAY, payload: res })
        }
    }
}
//获得还款的数据
export function getDetail(params) {
    return async dispatch => {
        const res = await req.getDetail(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_DATA_2_DETAIL, payload: res })
        }
    }
}