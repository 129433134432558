

import request from '../../utils/request';
import { API_STORE } from '../../utils/urls';
// 
export function getStoreDetail(params) {
    return request(API_STORE, 'store/getStoreDetail', params, 'json');
}
export function updateLikes(params) {
    return request(API_STORE, 'store/updateLikes', params, 'json');
}