

import request from '../../utils/request';
import { API_CXM } from '../../utils/urls';

export function getData(params) {
    return request(API_CXM, 'cxmUser/custom/buyers/creditOrders', params, 'json');
    // return {
    //     "data": {
    //         "buyerName": "一林",
    //         "buyerPhone": "",
    //         "firstTime": 1575288985202,
    //         "lastTime": 1575288985202,
    //         "totalAmount": 110,
    //         "orderList": [
    //             {
    //                 "todayNo": 23,
    //                 "buyerName": "一林",
    //                 "createTime": 1575288985202,
    //                 "creditAmount": 0,
    //                 "statusWatermark": 1,//状态水印 1：已付款，3：赊账
    //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
    //                 "saleAmount": 5000.00,
    //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
    //             },
    //             {
    //                 "todayNo": 23,
    //                 "buyerName": "一林",
    //                 "createTime": 1575288985202,
    //                 "creditAmount": 4000.00,
    //                 "statusWatermark": 3,//状态水印 1：已付款，3：赊账
    //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
    //                 "saleAmount": 5000.00,
    //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
    //             },
    //             {
    //                 "todayNo": 23,
    //                 "buyerName": "一林",
    //                 "createTime": 1575288985202,
    //                 "creditAmount": 4000.00,
    //                 "statusWatermark": 3,//状态水印 1：已付款，3：赊账
    //                 "orderNo": "WE8wRmVWd3ZnVEVsMmYvc1N4OGFoM1ZQZWJ4U0xrdEt2TW91MDJVNHRMNVFZUDFFMFlkd25STGNsS0QySVVxWQ",
    //                 "saleAmount": 5000.00,
    //                 "attrNameList": ["黄瓜", "白菜", "包菜", "玉米", "大利", "黄瓜"],
    //             }
    //         ],
    //         "totalCount": 1
    //     },
    //     "msg": "操作成功",
    //     "code": 200
    // }
}

export function getRepayData(params) {
    return request(API_CXM, 'cxmUser/custom/share/repayOrders', params, 'json');
    // return {
    //     "msg": "操作成功",
    //     "code": 200,
    //     "data": {
    //         "totalSize": 1,
    //         "totalPage": 1,
    //         "pageNo": 1,
    //         "pageSize": 1,
    //         "pageList": [
    //             {
    //                 "repayTime": 1575705179709,
    //                 "repayAmount": 12,
    //                 "repayList": [
    //                     {
    //                         "buyerName": "买家胡琴颖1",
    //                         "orderNo": "c294ZE9GQ0k4ZVY5VlVBbEwyQWFrTFVRWUdrNC9Vc1Zpb3RGOVRucEgxbWxPS0Z5L2U3TjhmZCtDeVpFcVpUdA==",
    //                         "repayAmount": 1,
    //                         "creditTime": 1571711321389,
    //                         "todayNo": 7,
    //                         "attrNameList": [
    //                             "土豆",
    //                             "白菜"
    //                         ],
    //                         "status": 1,
    //                         "discountAmount": 1,
    //                         "overchargeAmount": 1,
    //                         "buyerUid": 1
    //                     },
    //                     {
    //                         "buyerName": "买家胡琴颖2",
    //                         "orderNo": "c294ZE9GQ0k4ZVY5VlVBbEwyQWFrTFVRWUdrNC9Vc1Zpb3RGOVRucEgxbWxPS0Z5L2U3TjhmZCtDeVpFcVpUdA==",
    //                         "repayAmount": 1,
    //                         "creditTime": 1571711321389,
    //                         "todayNo": 7,
    //                         "attrNameList": [
    //                             "土豆",
    //                             "白菜"
    //                         ],
    //                         "status": 0,
    //                         "discountAmount": 1,
    //                         "overchargeAmount": 0,
    //                         "buyerUid": 1
    //                     }
    //                 ]
    //             },{
    //                 "repayTime": 1575705179709,
    //                 "repayAmount": 12,
    //                 "repayList": [
    //                     {
    //                         "buyerName": "买家胡琴颖",
    //                         "orderNo": "c294ZE9GQ0k4ZVY5VlVBbEwyQWFrTFVRWUdrNC9Vc1Zpb3RGOVRucEgxbWxPS0Z5L2U3TjhmZCtDeVpFcVpUdA==",
    //                         "repayAmount": 1,
    //                         "creditTime": 1571711321389,
    //                         "todayNo": 7,
    //                         "attrNameList": [
    //                             "土豆",
    //                             "白菜"
    //                         ],
    //                         "status": false,
    //                         "discountAmount": 1,
    //                         "overchargeAmount": 1,
    //                         "buyerUid": 1
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // }
}


export function getCreditDetailData(params) {
    return request(API_CXM, 'cxmOrder/sale/share/view', params, 'json');
}
