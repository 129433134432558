import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { getDetail } from '../../state/action/order';
import { Table } from 'antd';
// import { ORDER_STATUS } from '../../utils/status';
// import { URL_ORDER_TRACK } from '../../utils/urls';

import './detail.less';
// import moment from 'moment';
import { fixedZeroTo4Bit, handleTime, strSplit } from "../../utils/math";
// import Back from '../../component/back/index';
import backImgSrc from '../../common/images/icon_left.png';
// const { Column } = Table;

@withRouter
@connect(
    state => state.order,
    { getDetail }
)
class OrderDetail extends Component {
    constructor(props) {
        super(props);

        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        // this.sn = decodeURI(this.query.sn);
    }

    async componentDidMount() {
        // const { pathname } = this.props.location.state.back;
        document.title = "订单详情";//pathname === '/purchaseStatement' ? `采购对账单详情` : `赊还款详情`;
        document.getElementById("root").classList.add("order-detail-root");
        // this.orderId = this.props.location.state; //订单号
        const { param, orderNo } = this.query;
        await this.props.getDetail({
            p: param,
            nonce: orderNo,
        });
    }

    componentWillUnmount() {
        document.getElementById("root").classList.remove("order-detail-root");
    }

    getNumber(packKind, saleNumber, extend3) {
        if (packKind === 7) {
            const findIndex = extend3.indexOf('/');
            return saleNumber + (findIndex === -1 ? extend3 : extend3.substring(findIndex + 1));
        } else {
            return saleNumber + '件'
        }
    }
    getWeight(packKind, saleWeight, extend3, weightUnit) {
        if (packKind === 7) { // packKind === 1
            return '-';
        } else if (packKind === 1) { // 定装有售卖和按斤下单，如果是售卖的话，重量显示'-',如果是按斤卖，重量显示，‘元/斤或元/公斤’
            if (extend3 === '元/件') {
                return '-';
            } else {
                const findIndex = extend3.indexOf('/');
                return saleWeight + (findIndex === -1 ? extend3 : extend3.substring(findIndex + 1));
            }
        }
        else {
            const findIndex = extend3.indexOf('/');
            return saleWeight + (findIndex === -1 ? extend3 : extend3.substring(findIndex + 1));
        }
    }

    toBack = () => {
        this.props.history.push({
            pathname: this.query.back ? this.query.back : '/order',//this.query.back有2个值 'purchaseStatement'， '/buyerCredit'
            search: qs.stringify({
                sn: this.query.param,
                tabIndex: this.query.tabIndex,
                // scrollTop: this.query.scrollTop,
                toback: 1,//给父页面，让父页面不刷新数据
            }) //+ '#' + this.query.miao,
        })
    }

    // 获取押筐列表数据
    parseGoodsInfoToBasketInfo = basketBasketList => {
        let basketInfoArray = [];       
        basketBasketList.forEach((item, index) => {
            if (item.basketCount) {               
                basketInfoArray.push({
                    goodsName:index + 1 + "、" + (item.goodsAttrName || ""),
                    basketNum:item.basketCount,
                    basketUnitPrice:item.basketUnitPrice + "元/个",
                    deposit:item.subTotalDepositAmount + "元",
                });
            }

        });
        // console.log("F-basketInfoArray", basketInfoArray);
        return basketInfoArray;
    };

    render() {
        // console.log('detail.js Render', this.props.location)
        const { todayNo, buyerName, orderType, orderCreateTime, createTime,
            // extend5, 
            orderStatus,
            orderSaleAmount = 0, paymentAmount = 0, creditAmount = 0,
            orderDiscountAmount = 0, originOrderSaleAmount = 0, orderOverchargeAmount = 0,
            orderItemLst = [],
            remark = "",
            chargeOrderFree = 0,//三轮车费        
            // truckWeightUnitJin = '',//皮重，毛重的单位
            // basketResultList = [],//押筐明细
            // totalDeposit = 0,//筐子总押金
            totalDepositAmount = 0,//筐子总押金
        } = this.props.detail.data || {};

        // 押筐数据
        const basketInfoArray = this.parseGoodsInfoToBasketInfo(orderItemLst);
        console.log('押筐数据 basketInfoArray',basketInfoArray);
        
        //status 状态水印 1：已付款，3：赊账
        //orderType:5-赊欠白条

        const columns = [
            {
                title: '货名',
                dataIndex: 'goodsName',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}</span>
                },
            },
            {
                title: '筐子数',
                dataIndex: 'basketNum',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}</span>
                },
            },
            {
                title: '单价',
                dataIndex: 'basketUnitPrice',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}</span>
                },
            },
            {
                title: '押金',
                dataIndex: 'deposit',
                align: 'center',
                render: (text, row, index) => {
                    return <span>{text}</span>
                },
            }
        ];

        return <div className='order-detail-index'>
            <div className='container'>
                <div className="header">
                </div>
                <div className='headerBox'>
                    <div className="headerNum"><span>票号：{todayNo ? fixedZeroTo4Bit(todayNo, 4) : ''}</span>{buyerName}</div>
                    {remark ? <div className="remark"><span>备注：</span><span>{remark}</span></div> : null}
                    {/* {orderType !== 5 && <div className="signTime">码单号：{extend5 ? fixedZeroTo4Bit(extend5, 4) : ''}</div>} */}
                    <div className="creatTime">赊欠时间：
                        {orderType === 5
                            ? orderCreateTime ? handleTime(orderCreateTime).replace(/\./g, '-') : ''
                            : createTime ? handleTime(createTime).replace(/\./g, '-') : ''}
                    </div>
                    <div className='headerStatu'>
                        {/* <div className={status === 1 ? 'status1' : 'status3'}>{ORDER_STATUS.get(statusWatermark)}</div> */}
                        <div><img src={require(`../../common/images/${orderStatus === 10 ? 'jieqing' : orderType === 5 ? 'baitiao' : 'sheqian'}.png`)} alt='' /></div>
                    </div>
                </div>
                <div className='detailTop'>
                    <div className='detailItem'>
                        {/* <div>
                            <span className='title'>{orderSaleAmount}</span><span>应付</span>
                        </div> */}
                        <div>
                            <span className='title'>{paymentAmount}</span><span>已付</span>
                        </div>
                        <div>
                            <span className='title1'>{creditAmount}</span><span>下欠</span>
                        </div>
                    </div>
                </div>

                {orderType !== 5 &&
                    <div className='detailContent'>
                        <div className='detail-goods-title'>
                            <span>货品明细</span>
                        </div>
                        {
                            orderItemLst.map((v, i) => {
                                const { goodsAttrName, originOrderSaleAmount, packKind, saleNumber, saleWeight, extend3, weightUnit,
                                    unitPrice, goodsAmount, tareWeight, grossWeight, iceWeight,
                                    tradeFee, billFee, packFee
                                } = v;
                                const goods = [
                                    { key: '数量', value: this.getNumber(packKind, saleNumber, extend3) },
                                    { key: '重量', value: this.getWeight(packKind, saleWeight, extend3, weightUnit) },
                                    { key: '单价', value: `${unitPrice}${extend3}` },
                                    { key: '货款', value: `${goodsAmount}元` },
                                    // { key: '皮重', value: `${tareWeight}${weightUnit}` },
                                    // { key: '毛重', value: `${grossWeight}${weightUnit}` },
                                    // { key: '去皮', value: `${iceWeight}${weightUnit}` },
                                    // { key: '力资', value: `${tradeFee}元` },
                                    // { key: '开票', value: `${billFee}元` },
                                    // { key: '灌包', value: `${packFee}元` },
                                ];
                                tareWeight && goods.push({ key: '皮重', value: `${tareWeight}${weightUnit}` });
                                grossWeight && goods.push({ key: '毛重', value: `${grossWeight}${weightUnit}` });
                                iceWeight && goods.push({ key: '去皮', value: `${iceWeight}${extend3 && extend3.substring(extend3.indexOf('/') + 1)}` });
                                tradeFee && goods.push({ key: '力资', value: `${tradeFee}元` });
                                billFee && goods.push({ key: '开票', value: `${billFee}元` });
                                packFee && goods.push({ key: '灌包', value: `${packFee}元` });

                                return <div key={i} className={`detail-goods-content ${i === orderItemLst.length - 1 && 'detail-goods-content-last'}`}>
                                    <div>
                                        <div>{i + 1}、{goodsAttrName && strSplit(goodsAttrName, 14)}</div>
                                        <div>小计：<span>{originOrderSaleAmount}</span></div>
                                    </div>
                                    <div>
                                        {goods.map((v, i) => {
                                            //找出最后几个不需要加border-bottom的 4为总个数，1位最后一个去掉border-bottom
                                            //4,1   5,2   6,3   7,1   8,2   9,3  10,1                               
                                            const removeCount = (goods.length) % 3 === 0 ? 3 : goods.length % 3;
                                            return <div key={i} className={`${(i + 1) % 3 === 0 ? '' : 'borderRight'} ${i >= goods.length - removeCount ? '' : 'borderBottom'}`}>{v.key}：{v.value}</div>
                                        })}

                                    </div>
                                </div>
                            })
                        }

                        <div className="detail-goods-3">
                            {chargeOrderFree > 0 && <div>三轮车费：{chargeOrderFree}元</div>}
                        </div>

                    </div>
                }

                {basketInfoArray && basketInfoArray.length > 0  &&
                    <div className='detail-frame'>
                        <div>
                            <div>押筐明细</div>
                            <div>筐押金：<span>{totalDepositAmount}</span></div>
                        </div>
                        <Table columns={columns} dataSource={basketInfoArray} bordered={true} pagination={false} />
                    </div>
                }

                <div className="detail-footer"></div>
                <div className="footer">
                    <div className="count">应付：<span>{orderSaleAmount}元</span></div>
                    <div className="countPrice">
                        <span>合计金额：{originOrderSaleAmount}元</span>
                        {orderDiscountAmount > 0 && <span>优惠：{orderDiscountAmount}元</span>}
                        {orderOverchargeAmount > 0 && <span>多收：{orderOverchargeAmount}元</span>}
                    </div>
                </div>
            </div>
            <div className="backArea" onClick={this.toBack}>
                <img src={backImgSrc} className='imageMark' alt=''></img>
            </div>
            {/* <Back {...this.props.location.state}/> */}
        </div>
    }
}

export default OrderDetail;