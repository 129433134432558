import * as actionTypes from '../constant/purchase';

//初始状态
const initState = {
    homeData: {},//首页的数据
    purchaseRecord1: {},//全部采购记录的数据-全部订单
    purchaseRecord2: {},//全部采购记录的数据-赊欠订单
    repayRecord: {},//还款记录
    creditRepayCount: {},//赊还流水月份的数量
    creditRepay: {},//赊还流水
    detail: {},//详细页面的数据
}
//reducer 采购对账单
export function purchase(state = initState, action) {
    switch (action.type) {
        // case actionTypes.BUYER_CREDIT_DATA:
        //     // let returnData = {};
        //     // const { code, data } = action.payload;
        //     // if (code === 200) {
        //     //     const d = data.data;
        //     //     returnData = { ...data};
        //     // }
        //     // console.log('actionTypes.ORDER_DATA_REPAY', state, action.payload);
        //     // return { ...state, repayData: { ...state.repayData, ...action.payload, data: { pageList: [...state.repayData.data.pageList, ...action.payload.data.pageList] } } };
        //     if (state.buyCreditData.data) {
        //         return { ...state, buyCreditData: { ...state.buyCreditData, ...action.payload, data: { ...{ ...state.buyCreditData.data, ...action.payload.data }, orderList: [...state.buyCreditData.data.orderList || [], ...action.payload.data.orderList || []] } } };
        //     } else {//第一次进来
        //         return { ...state, buyCreditData: { ...state.buyCreditData, ...action.payload } };
        //     }

        // // return { ...state, buyCreditData: action.payload };
        // case actionTypes.BUYER_CREDIT_REPAY_DATA:
        //     if (state.purchaseRecord1.data) {
        //         return { ...state, purchaseRecord1: { ...state.purchaseRecord1, ...action.payload, data: { ...{ ...state.purchaseRecord1.data, ...action.payload.data }, pageList: [...state.purchaseRecord1.data.pageList || [], ...action.payload.data.pageList || []] } } };
        //     } else {//第一次进来
        //         return { ...state, purchaseRecord1: { ...state.purchaseRecord1, ...action.payload } };
        //     }
        // case actionTypes.BUYER_CREDIT_DETAIL_DATA:
        //     return { ...state, buyCreditDetailData: action.payload };
        case actionTypes.PURCHASE_HOME_DATA:
            return { ...state, homeData: action.payload, sn: action.sn };
        case actionTypes.PURCHASE_PRCHASE_RECORD_1:
            // return { ...state, purchaseRecord1: action.payload };
            if (state.purchaseRecord1.data) {
                return { ...state, purchaseRecord1: { ...state.purchaseRecord1, ...action.payload, data: { ...{ ...state.purchaseRecord1.data, ...action.payload.data }, pageList: [...state.purchaseRecord1.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, purchaseRecord1: { ...state.purchaseRecord1, ...action.payload } };
            }
        case actionTypes.PURCHASE_PRCHASE_RECORD_2:
            // return { ...state, purchaseRecord2: action.payload };
            if (state.purchaseRecord2.data) {
                return { ...state, purchaseRecord2: { ...state.purchaseRecord2, ...action.payload, data: { ...{ ...state.purchaseRecord2.data, ...action.payload.data }, pageList: [...state.purchaseRecord2.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, purchaseRecord2: { ...state.purchaseRecord2, ...action.payload } };
            }
        case actionTypes.PURCHASE_REPAY_RECORD:
            if (state.repayRecord.data) {
                return { ...state, repayRecord: { ...state.repayRecord, ...action.payload, data: { ...{ ...state.repayRecord.data, ...action.payload.data }, pageList: [...state.repayRecord.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, repayRecord: { ...state.repayRecord, ...action.payload } };
            }
        case actionTypes.PURCHASE_CREDIT_REPAY_COUNT:
            return { ...state, creditRepayCount: action.payload };
        case actionTypes.PURCHASE_CREDIT_REPAY:
            if (state.creditRepay.data) {
                return { ...state, creditRepay: { ...state.creditRepay, ...action.payload, data: { ...{ ...state.creditRepay.data, ...action.payload.data }, pageList: [...state.creditRepay.data.pageList || [], ...action.payload.data.pageList || []] } } };
            } else {//第一次进来
                return { ...state, creditRepay: { ...state.creditRepay, ...action.payload } };
            }
        case actionTypes.PURCHASE_DETAIL_DATA:
            return { ...state, detail: action.payload };
        case actionTypes.PURCHASE_CREDIT_REPAY_CLICK_DATA:
            return { ...state, creditRepayClickData: action.payload };
        case actionTypes.PURCHASE_REPAY_RECORD_CLEAR:
            return { ...state, repayRecord: {} };
        case actionTypes.PURCHASE_CREDIT_REPAY_CLEAR:
            return { ...state, creditRepay: {} };
        default:
            return state;
    }
}