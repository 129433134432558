import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { Flex } from 'antd-mobile';

import ReactSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { isWx, isiOS, isQQ, isMobileDing } from "../../utils/math";
import { getCxmData } from '../../state/action/download';
import qs from 'qs';
import mask1 from '../../common/images/mask1.png';
import btnPic from '../../common/images/cxm-download/btn.png';

import './index.less';

@withRouter
@connect(
    state => state.download,
    { getCxmData }
)
class CxmDownload extends Component {
    constructor(props) {
        super(props);
        this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
        this.state = {
            //showImg: true,
            show: false,
            data: [0, 1, 2]
        }
    }

    async componentDidMount() {
        document.title = `下载菜小秘`;
        document.body.classList.add("cxm-download-body");
        // this.slideInterval = setInterval(
        //     () => {
        //         this.setState({showImg:!this.state.showImg});
        //     },
        //     4000
        // );
        // if (!isWx() && this.query.download) {
        //     this.download();
        // }

        window._czc.push(['_trackEvent', '菜小秘APP下载页面', '加载', '打开菜小秘APP下载的页面']);
    }

    componentWillUnmount() {
        document.body.classList.remove("cxm-download-body");
    }

    // download = async () => {
    //     window._czc.push(['_trackEvent', '菜小秘APP下载', '点击', '点击了菜小秘APP下载']);

    //     if (isiOS()) {//IOS
    //         // const result = await this.props.getDownloadUrl();
    //         // if(result.code === 200){
    //         //     const data = result.data;
    //         //     let url = '';
    //         //     //1:官方 2:android应用宝 3:ios appStore 
    //         //     data.map((item,index)=>{
    //         //         if(item.channel===3){
    //         //             url = item.url;
    //         //         }
    //         //     })
    //         //     window.location.href = url;
    //         // }else{
    //         //     message.info(result.msg);
    //         // }
    //         window.location.href = 'https://apps.apple.com/cn/app/%E8%8F%9C%E5%B0%8F%E7%A7%98/id1490901038?mt=8';//最新的
    //     } else {
    //         if (isWx() || isQQ() || isMobileDing()) {
    //             this.setState({ show: true });
    //             //网页本身没有任何变化，只是地址栏发生了变化，实现了无刷新改变地址栏，并且在历史记录中添加了一个新的纪录；
    //             window.history.pushState(null, null, window.location.href + '&download=true');
    //         } else {
    //             await this.props.getCxmData();
    //             const { cxm, } = this.props;
    //             if (cxm.code === 200) {
    //                 const cxmLink = cxm.data.data[0].link;
    //                 window.location.href = cxmLink;
    //             } else {
    //                 message.info(cxm.msg)
    //             }
    //         }
    //     }
    // }

    handleCancel = e => {
        //console.log(e);
        this.setState({ show: false });
    };

    render() {
        //const { showImg } = this.state;
        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 10000,
            autoplaySpeed: 1000,
            cssEase: "linear"
        };

        return <Flex className='cxm-download-index'>
            <div className="container">
                <div className="content">
                    <ReactSlick ref={slider => (this.slider = slider)} {...settings}>
                        {this.state.data.map((val, index) => (
                            <div key={index} className="img-div">
                                <div><img src={require(`../../common/images/cxm-download/${1 + (4 * val)}.png`)} alt="" /></div>
                                <div><img src={require(`../../common/images/cxm-download/${1 + (4 * val) + 1}.png`)} alt="" /></div>
                                <div><img src={require(`../../common/images/cxm-download/${1 + (4 * val) + 2}.png`)} alt="" /></div>
                                <div><img src={require(`../../common/images/cxm-download/${1 + (4 * val) + 3}.png`)} alt="" /></div>
                            </div>

                        ))}
                    </ReactSlick>

                    {

                        //  <Carousel className="space-carousel"
                        //   //frameOverflow="visible"
                        //   //cellSpacing={10}
                        //   //slideWidth={0.8}
                        //   //swipeSpeed={10}
                        //   dots={false}
                        //   swiping={false}
                        //   autoplay = {true}
                        //   infinite
                        //   afterChange={index => this.setState({ slideIndex: index })}
                        // >
                        //   {this.state.data.map((val, index) => (
                        //       <div className="img-div">
                        //         <div><img src={img1} alt=""/></div>
                        //         <div><img src={img2} alt=""/></div>
                        //         <div><img src={img3} alt=""/></div>
                        //         <div><img src={img4} alt=""/></div>
                        //     </div>
                        //   ))}
                        // </Carousel>

                        // <NoticeBar icon={false} marqueeProps={{ loop: true, leading: 1000, fps: 20 }} className="notice-bar">
                        //     {noticeData.map((item,index)=>{
                        //         return <div className="img-div">
                        //         <div><img src={img1} /></div>
                        //         <div><img src={img2} /></div>
                        //         <div><img src={img3} /></div>
                        //         <div><img src={img4} /></div>
                        //         </div>
                        //     })}
                        // </NoticeBar>
                    }
                    {
                        // showImg&&
                        // <div>
                        //     <div><img src={img1} className="img1"/></div>
                        //     <div><img src={img2} className="img2"/></div>
                        //     <div><img src={img3} className="img3"/></div>
                        //     <div><img src={img4} className="img4"/></div>
                        // </div>
                        // <div>
                        //     <div><div className="img1"></div></div>
                        //     <div><div className="img2"></div></div>
                        //     <div><div className="img3"></div></div>
                        //     <div><div className="img4"></div></div>
                        // </div>
                    }
                </div>
                <div className="footer" style={{ cursor: 'pointer' }}>
                    <img
                        id="cxmDownloadButton" style={{ cursor: 'pointer' }}
                        src={btnPic}
                        className="btn-class" alt=''
                    // onClick={this.download} 
                    />
                </div>
                <Modal
                    visible={this.state.show}
                    className="popup"
                    footer={null}
                    style={{ top: 0, }}
                    onCancel={this.handleCancel}
                >
                    <img src={mask1} className='mask' alt='' onClick={this.handleCancel}></img>
                </Modal>
            </div>
        </Flex>
    }

}

export default CxmDownload;