import * as actionTypes from '../constant/supplierSalesStatistics';

//初始状态
const initState = {
    data: {},
    detailData: {
        data: {
            pageList: [],
        }
    },
}
//reducer
export function supplierSalesStatistics(state = initState, action) {
    switch (action.type) {
        case actionTypes.SUPPLIER_SALES_STATISTICS_DATA:
            return { ...state, data: action.payload, }
        case actionTypes.SUPPLIER_SALES_STATISTICS_DETAIL_DATA:
            if (action.reqParams.pageNo === 1) {//第一次进来
                return { ...state, detailData: { ...state.detailData, ...action.payload } };
            } else {
                return { ...state, detailData: { ...state.detailData, ...action.payload, data: { ...{ ...state.detailData.data, ...action.payload.data }, pageList: [...state.detailData.data.pageList || [], ...action.payload.data.pageList || []] } } };
            }
        default:
            return state;
    }
}