import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { message, Modal, Form, Button, Row, Col, Input } from 'antd';

import { register, addBathSharingRecord, getCode, getData, getUUID } from '../../../state/action/supplierDataShare';
import { BASE64_encode } from "../../../utils/math";
import supplierLogo from '../images/supplier_logo.png';
import './register.less';

//采购对账表
@Form.create({ name: 'register' })
@withRouter
@connect(
    state => state.supplierDataShare,
    { register, getData, getUUID }
)
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 60,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addBathSharingRecord = this.addBathSharingRecord.bind(this);
        this.getCode = this.getCode.bind(this);
    }

    async componentDidMount() {

    }

    async handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                const parmas = JSON.stringify({
                    phone: values.phoneValue,
                    code: values.codeValue,
                    loginMode: 2,
                    systemType: 4
                });

                await this.props.register({
                    p: BASE64_encode(parmas),
                    appType: 'supplier',
                    method: 'user/login',
                });
                const { code, data } = this.props.reduxRregister;

                if (code === 200) {
                    message.success('操作成功');                    
                    this.props.changeShow();
                    localStorage.setItem('access_token', data.access_token);
                    await this.getData(data.access_token);
                    this.addBathSharingRecord();
                } else {
                    // message.warning(msg);
                }
            }
        });
    }

    async getData(access_token) {
        const { batchId, stallId, startTime, endTime } = this.props.query;
        const parmas = JSON.stringify({ batchId, endTime, stallId, startTime });
        await Promise.all([
            this.props.getData({
                accessToken: access_token,
                appType: 'supplier',
                p: BASE64_encode(parmas)
            }),
            this.props.getUUID({
                accessToken: access_token,
                appType: 'supplier',
            }),
        ]);
        const { data } = this.props;
        document.title = `${data.stallName || ''}数据分享`;
    }

    async addBathSharingRecord() {
        const { batchId, stallId, empId } = this.props.query;
        const { access_token, sysuser: { user_id } } = this.props.reduxRregister.data;
        const { UUID } = this.props;
        let parmas = JSON.stringify({
            batchId,
            stallId,
            beneficiaryUid: user_id,
            shareUuid: UUID,
            createUid: empId
        })
        const res = await addBathSharingRecord({
            accessToken: access_token,
            appType: 'supplier',
            p: BASE64_encode(parmas)
        });
        const { code, data } = res;
        if (code === 200) {
            console.log(res, 'data分享成功', data)
        } else {
            console.log('请登录')
        }
    }

    async getCode() {
        const phoneValue = this.props.form.getFieldValue('phoneValue');

        if (phoneValue) {
            let parmas = JSON.stringify({
                phone: phoneValue,
                smsType: "login",
                systemType: 4
            })
            // console.log(phoneValue, '3432534')
            const res = await getCode({
                p: BASE64_encode(parmas)
            });
            // console.log('data', res, this)
            const { code } = res;
            if (code === 200) {
                message.info("验证码发送成功");

                //验证码倒计时                   
                const time = setInterval(() => {
                    this.setState(state => ({
                        seconds: state.seconds - 1,
                    }));
                    if (this.state.seconds === 0) {
                        this.setState({ seconds: 60, });
                        clearInterval(time);
                    }
                }, 1000)               
            } else if (code === 500) {
                message.warning("服务器连接错误请重试");
            }
        } else {
            message.warning("请输入手机号");
        }
    }

    render() {
        console.log('supplier-data-share render()', this);
        const { seconds } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { show } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 16 },
            },
        };

        return <Modal
            visible={show}
            className="popup"
            footer={null}
            // style={{ top: 0, }}
            closable={false}
        >
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item>
                    <div className="popup-header">
                        <div>
                            <img src={supplierLogo} className='imageLogo' alt=''></img>
                        </div>
                        <div className="popup-content">
                            <span className="popup-text">菜小秘货主端</span>
                            <span className="popup-text popup-interval">验证手机，即刻解锁全部数据</span>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item label="手机号">
                    {getFieldDecorator('phoneValue', {
                        rules: [{ required: true, message: '请输入手机号' }],
                    })(
                        <Input placeholder="请输入手机号" size="large" />
                    )}
                </Form.Item>
                <Form.Item label="验证码">
                    <Row gutter={8}>
                        <Col span={12}>
                            {getFieldDecorator('codeValue', {
                                rules: [{ required: true, message: '请输入验证码' }],
                            })(<Input placeholder="请输入验证码" size="large" />)}
                        </Col>
                        <Col span={12}>
                            {seconds === 60 ?
                                <Button type="link" size="large" style={{ width: '100%' }} onClick={this.getCode}>获取验证码</Button> :
                                <span className="form-code1">{seconds}秒后重新获取</span>
                            }
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
                        确定
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    }
}

export default Register;