import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import qs from 'qs';
// import moment from 'moment';

// import { Tabs } from 'antd-mobile';
// import Credit from './index/credit';
// import Repay from './index/repay';
import CommonIndex from './common/index';


// import { getData } from '../../state/action/buyerCredit'
// import icon_right from './images/icon_right.png';
// import icon_mark from './images/icon_mark.png';
// import { handleTimeYearMonthDay } from "../../utils/math";
// import List from './index/list';
// import { fixedZeroTo4Bit, strSplit } from "../../utils/math";

import './style.less';

// @withRouter
// @connect(
//     state => state.buyerCredit,
//     { getData }
// )
class Index extends Component {

    async componentDidMount() {
        document.title = `赊还款对账单`;
        document.body.classList.add("order-body");
    }

    componentWillUnmount() {
        document.body.classList.remove("order-body");
    }

    render() {

        return <div className='order-index'>
            <CommonIndex type="partial" back="partialOrder" />             
        </div>
    }
}

export default Index;
