import React from 'react';
import { withRouter } from 'react-router-dom';

import Home from './container/home/index';
import Order from './container/order/index';
import PartialOrder from './container/order/partial';
import OrderDetail from './container/order/detail';
import Download from './container/download/index';
import ShareAccount from './container/share-account/index';
import SupplierDataShare from './container/supplier-data-share/index';
import CXMBill from './container/cxm-bill/index';
import CXMCreditRepay from './container/cxm-bill/creditRepay';
import CXMPurchaseRecord from './container/cxm-bill/purchaseRecord';
import CXMRepayRecord from './container/cxm-bill/repayRecord';
import CXMOrderDetail from './container/cxm-bill/orderDetail';
import CXMOrderDetail5 from './container/cxm-bill/orderDetail5';
import XCMPurchaseStatement from './container/cxm-purchase-statement/index';
import BuyerCredit from './container/buyer-credit/index';
import BuyerCreditDetail from './container/buyer-credit/detail';
import BuyerCreditDetail5 from './container/buyer-credit/detailOrderType5';
import SupplierSalesStatistics from './container/supplier-sales-statistics/index';
import SupplierSalesStatisticsDetail from './container/supplier-sales-statistics/detail';
import CxmDownload from './container/cxm-download/index';
import Store from './container/store/index';
import ShareCXM from './container/cxm-share/index';

import './App.less';

@withRouter
class App extends React.Component {
  render() {
    // console.log('App', window.location);//http://localhost:3000/?from=groupmessage&isappinstalled=0#/cml/h5/shareAccount?sn=WnNTcUwxd044ZkFIdzFDL0VYc2p1YVVrcEk4THg1aDlkbzVPN3AxWks0QXdFanhvVGdxM2FQYlFPVGlOcndaQWltVkFYbXpqamhBdm9WemYva09DaHpWTzAvdkRXNkhKZVBURjRTbVZ6Uk0rSEc0MWh0TlpHampUMGJSMmhNMHJaK2FldEMrdGE4Rkp4bUNHVC9LRHRTN2dUdklrL2xyRC85MkI2WkJSb3JFWmZ5R042bWFtb2RXSmwxd2EvSS81MnJ4dVNyRFk1S2pIbTJWallPMFhhNlZld1hnSS9VL1JMdjF0QzJXTys1WT0%3D
    // 获取lastIndexOf('?')和前面的'/'之间的值，这个值就是路由
    const href = window.location.href;
    const lastIndex = href.lastIndexOf('?');
    const temp = lastIndex === -1 ? href : href.substring(0, lastIndex);//得到这个值：?from=singlemessage&isappinstalled=0/order
    const path = temp.substring(temp.lastIndexOf('/') + 1) || '';//得到这个值：order
    //console.log('path', path)
    return <div className='App'>
      {path === "index" || path === "order" ? <Order /> :
        path === "partialOrder" ? <PartialOrder />
          : path === "orderDetail" ? <OrderDetail />
            : path === "shareAccount" ? <ShareAccount />
              : path === "supplierDataShare" ? <SupplierDataShare />
                : path === "download" ? <Download />
                  : path === "bill" ? <CXMBill />
                    : path === "cxm-credit-repay" ? <CXMCreditRepay />
                      : path === "cxm-purchase-record" ? <CXMPurchaseRecord />
                        : path === "cxm-repay-record" ? <CXMRepayRecord />
                          : path === "cxm-order-detail" ? <CXMOrderDetail />
                            : path === "cxm-order-detail-5" ? <CXMOrderDetail5 />
                              : path === "purchaseStatement" ? <XCMPurchaseStatement />
                                : path === "buyerCredit" ? <BuyerCredit />
                                  : path === "buyerCreditDetail" ? <BuyerCreditDetail />
                                    : path === "buyerCreditDetail5" ? <BuyerCreditDetail5 />
                                      : path === "supplierSalesStatistics" ? <SupplierSalesStatistics />
                                        : path === "supplierSalesStatisticsDetail" ? <SupplierSalesStatisticsDetail />
                                          : path === "cxmDownload" ? <ShareCXM />
                                            : path === "store" ? <Store />
                                              : path === "sharecxm" ? <CxmDownload />
                                                : <Home />
      }
    </div>
  }
}

export default App;

// function App() {
//   console.log('App', window.location.search);//"?from=singlemessage&isappinstalled=0/order?sn=RHVBT01GRVAwVEplb0Y4M0h2Wmd3Q0ZRcFRpR3NoQ3
//   // 获取lastIndexOf('?')和前面的'/'之间的值，这个值就是路由
//   const search = window.location.search;
//   const lastIndex = search.lastIndexOf('?');
//   let path = 'home';
//   if (lastIndex === 0) {
//     path = window.location.pathname.substring(1);//得到这个值：order
//   } else {
//     const temp = search.substring(0, lastIndex);//得到这个值：?from=singlemessage&isappinstalled=0/order
//     path = temp.substring(temp.lastIndexOf('/') + 1);//得到这个值：order
//   }

//   return (
//     <div className="App">
//       {path === "index" || path === "order" ? <Order />
//         : path === "orderDetail" ? <OrderDetail />
//           : path === "shareAccount" ? <ShareAccount />
//             : path === "supplierDataShare" ? <SupplierDataShare />
//               : path === "download" ? <Download />
//                 : <Home />
//       }

//       <Switch>
//         <Route exact path='/' component={Home}></Route>
//         <Route path='/index' component={Order}></Route>
//         <Route path='/order' component={Order}></Route>
//         <Route path='/orderDetail' component={OrderDetail}></Route>
//         <Route path='/shareAccount' component={ShareAccount}></Route>
//         <Route path='/supplierDataShare' component={SupplierDataShare}></Route>
//         <Route path='/download' component={Download}></Route> */}
//       </Switch>


//       {/* <Switch> */}
//       {/* <Route exact path='/' component={Home}></Route>
//         <Route path='/index' component={Order}></Route>
//         <Route path='/order' component={Order}></Route>
//         <Route path='/orderDetail' component={OrderDetail}></Route>
//         <Route path='/shareAccount' component={ShareAccount}></Route>
//         <Route path='/supplierDataShare' component={SupplierDataShare}></Route>
//         <Route path='/download' component={Download}></Route> */}
//       {/* </Switch> */}
//     </div>
//   );
// }

// export default App;
