
// // 档主端
// // export const API_STALL = 'http://dev.api.caixm.cn/cxm-stall/';
// export const API_STALL = 'http://test.api2x.caixm.cn/cxm-stall/';
// // export const API_STALL = 'http://pre.api3x.caixm.cn/cxm-stall/';
// // export const API_STALL = 'https://stall.mp.caixm.cn/cxm-stall/';

// //货主端
// // export const API_SUPPLIER = "https://dev.api.caixm.cn/cxm-supplier/";
// export const API_SUPPLIER = "http://test.api2x.caixm.cn/cxm-supplier/";
// // export const API_SUPPLIER= "http://pre.api2x.caixm.cn/cxm-supplier/";
// // export const API_SUPPLIER= "https://api2x.caixm.cn/cxm-supplier/";


// 档主端    
let api_stall = 'http://test.api2x.caixm.cn/cxm-stall/';
//货主端   
let api_supplier = "http://test.api2x.caixm.cn/cxm-supplier/";
//菜小秘APP
let api_cxm = "http://test.api2x.caixm.cn/cxm-integrationApp/";
//订单轨迹
let urlOrderTrack = "http://dev.track.caixm.cn/#/batch/Profile?env=test";
//浏览器的url地址，微信分享用
// let url = "http://test.h5.caixm.cn/";
//订单的一生，微信分享后端api的url
let api_order_track = "http://dev.api.caixm.cn/cxm-cxmComServiceApi/";
//商城的api
let api_store = 'http://dev.api.caixm.cn/cxm-storeApi/';
//分享菜小秘openinstall的appKey
// let appKey = 'lbm47k';

const NODE_ENV = process.env.REACT_APP_API; //process.env.NODE_ENV;
if (NODE_ENV === 'test') {//测试环境
    api_stall = 'http://test.api2x.caixm.cn/cxm-stall/';
    api_supplier = "http://test.api2x.caixm.cn/cxm-supplier/";
    api_cxm = 'http://test.api2x.caixm.cn/cxm-integrationApp/';
    urlOrderTrack = 'http://dev.track.caixm.cn/#/batch/Profile?env=test';
    // url = "http://test.h5.caixm.cn/";
    api_order_track = "http://test.api2x.caixm.cn/cxm-cxmComServiceApi/";
    api_store = 'http://test.api2x.caixm.cn/cxm-storeApi/';
    // appKey = 'lbm47k';
} else if (NODE_ENV === 'preview') {//预发布
    api_stall = 'http://pre.api3x.caixm.cn/cxm-stall/';
    api_supplier = "http://pre.api2x.caixm.cn/cxm-supplier/";
    api_cxm = 'http://pre.api2x.caixm.cn/cxm-integrationApp/';
    urlOrderTrack = 'http://dev.track.caixm.cn/#/batch/Profile?env=prep';
    // url = "http://pre.newh5.caixm.cn/";
    api_order_track = "http://pre.api2x.caixm.cn/cxm-cxmComServiceApi/";
    api_store = 'http://pre.api2x.caixm.cn/cxm-storeApi/';
    // appKey = 'lnrb4e';
} else if (NODE_ENV === 'production') {//正式环境
    api_stall = 'https://stall.mp.caixm.cn/cxm-stall/';
    api_supplier = "https://api2x.caixm.cn/cxm-supplier/";
    api_cxm = 'https://api2x.caixm.cn/cxm-integrationApp/';
    urlOrderTrack = 'https://track.caixm.cn/#/batch/Profile?env=portal';
    // url = "http://newh5.caixm.cn/";
    api_order_track = "https://stall.mp.caixm.cn/cxm-cxmComServiceApi/";
    api_store = 'https://api2x.caixm.cn/cxm-storeApi/';
    // appKey = 'hy5ice';
} else {//本地开发环境
    // 档主端
    // api_stall = 'http://dev.api.caixm.cn/cxm-stall/';
    api_stall = 'http://test.api2x.caixm.cn/cxm-stall/';
    //api_stall = 'http://pre.api3x.caixm.cn/cxm-stall/';
    // api_stall = 'https://stall.mp.caixm.cn/cxm-stall/';
    // api_stall = 'http://192.168.5.115:8281/cxm-stall/';

    //货主端
    // api_supplier = "https://dev.api.caixm.cn/cxm-supplier/";
    api_supplier = "http://test.api2x.caixm.cn/cxm-supplier/";
    // api_supplier= "http://pre.api2x.caixm.cn/cxm-supplier/";
    // api_supplier= "https://api2x.caixm.cn/cxm-supplier/";

    // 菜小秘
    // api_cxm = 'http://192.168.5.224:8088/cxm-integrationApp';
    // api_cxm = 'http://dev.api.caixm.cn/cxm-integrationApp/';
    api_cxm = 'http://test.api2x.caixm.cn/cxm-integrationApp/';
    // api_cxm = 'http://pre.api2x.caixm.cn/cxm-integrationApp/';  
    // api_cxm = 'https://api2x.caixm.cn/cxm-integrationApp/';

    //订单轨迹URL
    // urlOrderTrack = 'http://192.168.5.125:8001/#/batch/Profile?env=prep';
    // urlOrderTrack = 'http://dev.track.caixm.cn/#/batch/Profile?env=dev';
    urlOrderTrack = 'http://dev.track.caixm.cn/#/batch/Profile?env=test';
    // urlOrderTrack = 'http://dev.track.caixm.cn/#/batch/Profile?env=prep';
    // urlOrderTrack= 'https://track.caixm.cn/#/batch/Profile?env=portal';

    //浏览器的url地址
    // url = "http://test.h5.caixm.cn/";
    // url="http://pre.newh5.caixm.cn/";
    // url="http://newh5.caixm.cn/";

    //订单的一生，微信分享后端api的url
    // api_order_track = "http://dev.api.caixm.cn/cxm-cxmComServiceApi/";
    api_order_track = "http://test.api2x.caixm.cn/cxm-cxmComServiceApi/";
    // api_order_track = "http://pre.api2x.caixm.cn/cxm-cxmComServiceApi/";
    // api_order_track = "https://stall.mp.caixm.cn/cxm-cxmComServiceApi/";

    //商城的api
    // api_store = 'http://dev.api.caixm.cn/cxm-storeApi/';
    api_store = 'http://test.api2x.caixm.cn/cxm-storeApi/';

    // appKey = 'lbm47k';//'wg59a7';
}

export const API_STALL = api_stall;// 档主端
export const API_SUPPLIER = api_supplier;//货主端
export const API_CXM = api_cxm;//菜小秘APP
export const URL_ORDER_TRACK = urlOrderTrack + `&orderNo=[ORDERNO]&status=[STATUS]&accessToken=[ACCESSTOKEN]&appType=INTEGRATIONAPP&stallId=[STALLID]&code=react`;//订单轨迹url
export const API_CXM_DOWNLOAD = 'https://stall.mp.caixm.cn/caixmmp-b/';//下载的链接
// export const URL = url;//浏览器的url地址
export const API_ORDER_TRACK = api_order_track;//浏览器的url地址
export const API_STORE = api_store;//浏览器的url地址
// export const OPENINSTALL_APPKEY = appKey;//分享菜小秘openinstall的appKey
//export const API_CXM_IOSDOWNLOAD = 'http://test.api2x.caixm.cn/cxm-integrationApp/';//ios的下载的链接
// export function getStallHost(){
// 	console.log( process.env,'env11111111');

//     let serverUrl = "";

// 	let evnName = "prep"

// 	if ("portal" === evnName) {
// 		serverUrl = "https://stall.mp.caixm.cn/cxm-stall/";
// 	} else if ("prep" === evnName) {
// 		serverUrl = "http://pre.api3x.caixm.cn/cxm-stall/";
// 	}  else if ("test" === evnName) {
// 		serverUrl = "http://test.api2x.caixm.cn/cxm-stall/";
// 	} else if ("local" === evnName) {
// 		serverUrl = "http://10.6.17.2:8281/cxm-stall/";
// 	} else {
// 		serverUrl = "http://dev.api.caixm.cn/cxm-stall/";
// 	}
// 	return serverUrl;
// }
// //货主端
// export const supplierUrl = 'http://pre.api2x.caixm.cn/cxm-supplier/';

// export function getSopplierHost(){
// 	console.log( process.env,'env11111111');
//     let serverUrl = "";

// 	let evnName = "prep"

// 	if ("portal" === evnName) {
// 		serverUrl = "https://api2x.caixm.cn/cxm-supplier/";
// 	} else if ("test" === evnName) {
// 		serverUrl = "http://test.api2x.caixm.cn/cxm-supplier/";
// 	} else if ("local" === evnName) {
// 		serverUrl = "http://192.168.1.132:8081/cxm-supplier/";
// 	} else if ("prep" === evnName) {
// 		serverUrl = "http://pre.api2x.caixm.cn/cxm-supplier/";
// 	} else if ("dev" === evnName) {
// 		serverUrl = "https://dev.api.caixm.cn/cxm-supplier/";
// 	}
// 	return serverUrl;
// }

