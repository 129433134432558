import * as actionTypes from '../constant/orderDetail';

//初始状态
const initState = {
    data: {},
}
//reducer
export function orderDetail(state = initState, action) {
    switch (action.type) {
        case actionTypes.ORDER_DETAIL_DATA:
            let returnData = {};
            const { code, data } = action.payload;
            if (code === 200) {
                const { buyerPhone, orderItemLst = [] } = data
                let phone = orderItemLst.length >= 1 ? orderItemLst[0].buyerPhone : buyerPhone;
                returnData = { ...data, buyerPhone: phone };
            }
            return {
                ...state,
                data: { ...returnData },
            }

        default:
            return state;
    }
}