

import request from '../../utils/request';
import { API_CXM } from '../../utils/urls';

export function getData(params) {
    return request(API_CXM, 'cxmBatch/batch/share', params, 'json');
}
export function getDetailData(params) {
    return request(API_CXM, 'cxmBatch/product/share/sale', params, 'json');
}

