import request from '../../utils/request';
import { API_ORDER_TRACK } from '../../utils/urls';


//获取微信签名
export function getWXSignature(params) {
    return request(API_ORDER_TRACK, 'wechat/getSignature', params, 'json');
    // return {
    //     "code": 200,
    //     "data": {
    //         "signature": "6c81e835ab601897f286a2b7503f3c8b81d47317",
    //         "jsapi_ticket": "HoagFKDcsGMVCIY2vOjf9s4UUvpWu4IHSLVkCo6Oi9yCAwC8uxmanz-1xi6vbY4c9fFSvF0XHcnMKoOOFd7R8Q",
    //         "url": "www.baidu.com",
    //         "nonceStr": "00dee9b8-78e3-48ec-b51b-fdf60dd0b46f",
    //         "timestamp": "1581409364"
    //     },
    //     "msg": "操作成功"
    // }
}
