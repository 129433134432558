import * as actionTypes from '../constant/shareAccount';
import * as req from '../request/shareAccount';

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData(params) {
    return async dispatch => {
        const res= await req.getData(params);
        if (res) {
            dispatch({ type: actionTypes.SHARE_ACCOUNT_DATA, payload: res })
        }
    }
}

export function getTotalData(params) {
    return async dispatch => {
        const res = await req.getTotalData(params);
        if (res) {
            dispatch({ type: actionTypes.SHARE_ACCOUNT_TOTAL_DATA, payload: res })
        }
    }
}