// import cookies from 'browser-cookies'
import * as actionTypes from '../constant/buyerCredit';
import * as req from '../request/buyerCredit';

/**
 * 获得页面的初始值
 * @param {string} user 
 */
export function getData(params) {
    return async dispatch => {
        const res = await req.getData(params);
        if (res) {
            dispatch({ type: actionTypes.BUYER_CREDIT_DATA, payload: res })
        }
    }
}
//获得还款的数据
export function getRepayData(params) {
    return async dispatch => {
        const res = await req.getRepayData(params);
        if (res) {
            dispatch({ type: actionTypes.BUYER_CREDIT_REPAY_DATA, payload: res })
        }
    }
}

export function getCreditDetailData(params) {
    return async dispatch => {
        const res = await req.getCreditDetailData(params);
        if (res) {
            dispatch({ type: actionTypes.BUYER_CREDIT_DETAIL_DATA, payload: res })
        }
    }
}
